import React, { useState } from "react";
import "./Custom.css";
import { FaCalendarAlt } from "react-icons/fa"; // Import calendar icon
import Modal from "react-modal"; // Install using npm install react-modal
import DatePicker from "react-datepicker"; // Install using npm install react-datepicker
import "react-datepicker/dist/react-datepicker.css"; // DatePicker styles


const CustomSelectDateOnly = ({onOptionSelect}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const options = [

    { value: "custom", label: "Custom" },
  ];

  const toggleDropdown = () => setIsOpen(!isOpen);

  const selectOption = (option) => {
    console.log("Selected Option:", option.value);
    if (option.value === "custom") {
      setIsModalOpen(true); // Open modal for custom date selection
    } else {
      setSelectedOption(option); // Set predefined option
      onOptionSelect(option);
    }
    setIsOpen(false); // Close dropdown
  };

  const closeModal = () => setIsModalOpen(false);

  const applyCustomDateRange = () => {
    const customOption = {
      value: `${startDate?.toLocaleDateString()} - ${endDate?.toLocaleDateString()}`,
      label: "Custom",
      startDate,
      endDate,
    };
    //console.log("customOptioncustomOptioncustomOption",customOption);
    setSelectedOption(customOption); // Use the same state for custom option
    onOptionSelect(customOption);
    setIsModalOpen(false); // Close modal
  };



  return (
    <div>
      {/* Custom Dropdown */}
      <div className="custom-selectnew">
        <div
          className={`select-selected ${isOpen ? "select-arrow-active" : ""}`}
          onClick={toggleDropdown}
        >
          <FaCalendarAlt className="calendar-icon" /> {/* Calendar Icon */}
          {"Select Dates"}
        </div>
        {isOpen && (
          <div onClick={selectOption({ value: "custom", label: "Custom" })} className="select-items">
            
            
          </div>
        )}
      </div>

      {/* Modal for Custom Date Range */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
        ariaHideApp={false}
      >
        {/* <h2>Select Date Range</h2> */}
        <div className="date-range-picker">
          <div >
            <label>Start Date:</label>
            <div className="datepicker-container">
              <FaCalendarAlt className="calendar-icon" />
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy-MM-dd"
                placeholderText="YYYY-MM-DD"
                className="datepicker-input"
              />
            </div>
            
          </div>
          <div >
            <label>End Date:</label>
            <div className="datepicker-container">
              <FaCalendarAlt className="calendar-icon" /> 
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="YYYY-MM-DD"
                  className="datepicker-input"
                />
            </div>            
          </div>
        </div>
        <div className="modal-actions">
          <button style={{background:"red", color:"#fff"}} onClick={closeModal}>Cancel</button>
          <button style={{background:"#08be0d9e",color:"#fff"}} onClick={applyCustomDateRange} disabled={!startDate || !endDate}>
            Apply
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CustomSelectDateOnly;
