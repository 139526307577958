import { DO_LOGIN, FETCH_OWNER } from "./actionTypes";
let JWT_TOKEN = localStorage.getItem("jwttoken");
JWT_TOKEN = JWT_TOKEN ? JWT_TOKEN : null;
let initialState = {
  jwttoken: JWT_TOKEN,
  owner: [],
};
const login = (state = initialState, action) => {
  switch (action.type) {
    case DO_LOGIN:
      localStorage.setItem("jwttoken", action.payload);
      return {
        ...state,
        jwttoken: action.payload,
      };
    case FETCH_OWNER:
      return {
        ...state,
        owner: action.payload,
      };
    default:
      return state;
  }
};
export default login;
