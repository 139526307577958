import React, { useState } from "react";
import { Dropdown, Form, Button } from "react-bootstrap";

const Customtradfilter = ({ statusOptions, onSubmit }) => {
  const [filters, setFilters] = useState({
    userName: "",
    script: "",
    exchange: "ALL",
    close_min: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (onSubmit) onSubmit(filters);
  };

  const handleCancel = () => {
    setFilters({
      userName: "",
      script: "all",
      exchange: "ALL",
      close_min: "",
    });
    if (onSubmit) onSubmit({ userName: "", script: "all", exchange: "ALL", close_min: "" });
  };

  return (
    <div className="customfilter">
      <Dropdown>
        <Dropdown.Toggle className="dropdownbtn" id="dropdown-basic">
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#4A4C56">
            <path d="M400-240v-80h160v80H400ZM240-440v-80h480v80H240ZM120-640v-80h720v80H120Z" />
          </svg>{" "}
          Filter
        </Dropdown.Toggle>

        <Dropdown.Menu className="p-3 customfilterselected">
          <Form>
            <Form.Group className="mb-3" controlId="formUserName">
              <Form.Label>User Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter user name"
                name="userName"
                value={filters.userName}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formScript">
              <Form.Label>Script</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter script name"
                name="script"
                value={filters.script}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formExchange">
              <Form.Label>Exchange</Form.Label>
              <Form.Select name="exchange" value={filters.exchange} onChange={handleInputChange}>
                {statusOptions.map((option) => (
                  <option key={option} value={option}>
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formCloseMin">
              <Form.Label>Trade close in min</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter minutes"
                name="close_min"
                value={filters.close_min}
                onChange={handleInputChange}
              />
            </Form.Group>

            {/* Buttons */}
            <div className="d-flex justify-content-between">
              <Button variant="outline-danger" onClick={handleCancel}>
                <i className="bi bi-x-circle"></i> Cancel
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                <i className="bi bi-check-circle"></i> Submit
              </Button>
            </div>
          </Form>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Customtradfilter;
