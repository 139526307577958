import React, { Fragment, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Col, Row, Card, CardHeader, Table } from "reactstrap";
import { toast } from "react-toastify";
import api from "../../../_helper/api_helper";
import { FETCH_MARKET_INSTRUMENTS_URL } from "../../../_helper/url_helper";
import { changeOrderWindow } from "../../../store/Order/action";
import CreateOrderModel from "./CreateOrderModel";
import { useDraggable } from "react-use-draggable-scroll";
import useSocket from "../../../_helper/useSocket";

const priceSocketUrl = process.env.REACT_APP_SOCKET_URL;

const GeneralComponent = () => {
  const priceSocket = useSocket(priceSocketUrl);
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.login);
  const [instruments, setInstruments] = useState({});
  const instrumentsRef = useRef(instruments);
  const [allInstruments, setAllInstruments] = useState({});
  const [query, setQuery] = useState("");
  const [livePrices, setLivePrices] = useState({}); // Live prices state
  const ref = useRef();
  const { events } = useDraggable(ref);

  async function changeOrderWindowHandler(instrument, type) {
    const data = {
      instrument: instrument,
      type: type,
    };
    dispatch(changeOrderWindow(data));
  }

  useEffect(() => {
    instrumentsRef.current = instruments;
  }, [instruments]);

  useEffect(() => {
    fetchMarketInstruments();
  }, []);

  useEffect(() => {
    const temp = {};
    Object.values(allInstruments).forEach((instrument) => {
      if (["FUTSTK", "FUTCOM", "FUTIDX"].includes(instrument.segment)) {
        temp[instrument.token] = instrument;
      }
    });
    setInstruments(temp);
  }, [allInstruments]);

  async function fetchMarketInstruments() {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      const res = await api.get(FETCH_MARKET_INSTRUMENTS_URL);
      if (res.data.status === "SUCCESS") {
        const temp = {};
        res.data.payload.forEach((instrument) => {
          instrument.pre_ltp = instrument.ltp;
          temp[instrument.token] = instrument;
        });
        setAllInstruments(temp);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  // **Filter Instruments Based on Search Query**
  const filteredInstruments = Object.values(instruments).filter((instrument) =>
    instrument.trading_symbol.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <Fragment>
      <CreateOrderModel />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="py-3">
                <h5>MarketWatch</h5>
              </CardHeader>
              <div className="border-top">
                <input
                  type="search"
                  className="form-control search border-0 fw-semibold px-4 py-3"
                  placeholder="Search for instruments..."
                  value={query}
                  onChange={(e) => setQuery(e.target.value.toUpperCase())}
                />
              </div>
              <div className="table-responsive" ref={ref} {...events}>
                <Table className="table-border-horizontal fw-semibold align-middle">
                  <thead>
                    <tr className="bg-secondary text-white">
                      <th>#</th>
                      <th>Script</th>
                      <th>LotSize</th>
                      <th>Bid</th>
                      <th>Ask</th>
                      <th>LTP</th>
                      <th>Change</th>
                      <th>High</th>
                      <th>Low</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <MarketData 
                      priceSocket={priceSocket} 
                      instruments={filteredInstruments} 
                      livePrices={livePrices} 
                      changeOrderWindowHandler={changeOrderWindowHandler} 
                    />
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

const MarketData = ({ priceSocket, instruments, livePrices, changeOrderWindowHandler }) => {
  return instruments.length ? (
    <>
      {instruments.map((instrument, idx) => (
        <MarketDataSingleComp 
          priceSocket={priceSocket} 
          instrument={instrument} 
          key={instrument.token} 
          idx={idx} 
          changeOrderWindowHandler={changeOrderWindowHandler} 
        />
      ))}
    </>
  ) : null;
};

const actionBinder = (changeOrderWindowHandler, instrument) => (
  <ul className="list-inline hstack gap-1 mb-0">
    <li className="list-inline-item">
      <button
        title="Buy"
        className="btn btn-sm btn-primary p-0 d-flex justify-content-center align-items-center fw-semibold"
        style={{ width: "30px", height: "30px" }}
        onClick={() => changeOrderWindowHandler(instrument, "buy")}
      >
        B
      </button>
    </li>
    <li className="list-inline-item">
      <button
        title="Sell"
        className="btn btn-sm btn-danger p-0 d-flex justify-content-center align-items-center fw-semibold"
        style={{ width: "30px", height: "30px" }}
        onClick={() => changeOrderWindowHandler(instrument, "sell")}
      >
        S
      </button>
    </li>
  </ul>
);

const MarketDataSingleComp = ({ priceSocket, instrument, changeOrderWindowHandler, idx }) => {
  const [livePrices, setLivePrices] = useState({});
  
  useEffect(() => {
    if (priceSocket) {
      const event = ["FOREX", "CRYPTO", "US"].includes(instrument?.exchange) 
        ? `forexRoom${instrument?.symbol?.toLowerCase()}` 
        : `room${instrument?.token}`;
      
      const emitData = ["FOREX", "CRYPTO", "US"].includes(instrument?.exchange) 
        ? instrument?.symbol?.toLowerCase() 
        : instrument?.token;
      
      priceSocket.emit("tokenData", emitData);
      
      priceSocket.on(event, (updatedInstrument) => {
        let typeName = updatedInstrument?.exchange === 'FOREX' || updatedInstrument?.exchange === "CRYPTO" || updatedInstrument?.exchange === "US" 
          ? updatedInstrument?.symbol.toLowerCase() 
          : updatedInstrument?.token;
        
        setLivePrices((prevState) => ({
          ...prevState,
          [typeName]: updatedInstrument
        }));
      });
    }
  }, [priceSocket]);

  let typeName = instrument?.exchange === 'FOREX' || instrument?.exchange === "US" || instrument?.exchange === "CRYPTO" 
    ? instrument?.symbol.toLowerCase() 
    : instrument?.token;
  
  let liveData = livePrices[typeName];

  return (
    <tr>
      <td>{idx + 1}</td>
      <td>{instrument.trading_symbol}</td>
      <td>{instrument.lotsize}</td>
      <td className={liveData?.bid_price > instrument?.pre_bid_price ? "bg-success" : "bg-danger"}>
        {liveData?.bid_price ?? instrument?.bid_price}
      </td>
      <td className={liveData?.ask_price > instrument?.pre_ask_price ? "bg-success" : "bg-danger"}>
        {liveData?.ask_price ?? instrument?.ask_price}
      </td>
      <td className={liveData?.ltp > instrument?.pre_ltp ? "bg-success" : "bg-danger"}>
        {liveData?.ltp ?? instrument?.ltp}
      </td>
      <td className={liveData?.percentage_change > 0 ? "text-success" : "text-danger"}>
        {liveData?.percentage_change ?? instrument?.percentage_change}%
      </td>
      <td>{liveData?.high_price ?? instrument?.high_price}</td>
      <td>{liveData?.low_price ?? instrument?.low_price}</td>
      <td>{actionBinder(changeOrderWindowHandler, instrument)}</td>
    </tr>
  );
};

export default GeneralComponent;
