import React, { Fragment, useState, useEffect } from "react";
import {
  CardBody,
  Card,
  CardHeader,
  Container,
  Collapse,
  Col,
  Row,
  Label,
} from "reactstrap";
import { useSelector } from "react-redux";
import api from "../../../../_helper/api_helper";
import { toast } from "react-toastify";
import { SEARCH_USER_BY_USERNAME_URL, SEARCH_CLIENT_BY_USERNAME_URL, CREATE_USER_FUND_URL, CREATE_CLIENT_FUND_URL } from "../../../../_helper/url_helper";
import { useNavigate } from "react-router-dom";
// import Select from "react-select";
import { AsyncPaginate } from 'react-select-async-paginate';

function GeneralComponent() {
  const navigate = useNavigate();
  const loginState = useSelector((state) => state.login);
  const [options, setOptions] = useState([]);
  const [recieverType, setRecieverType] = useState("client");
  const [recieverId, setRecieverId] = useState(null);
  const [type, setType] = useState("deposit");
  const [walletType, setWalletType] = useState("primary");
  const [amount, setAmount] = useState(0);
  const [note, setNote] = useState("");
  const [underUserOptions, setUnderUserOptions] = useState([]);
  const [underCientOptions, setUnderClientOptions] = useState([]);
  const [currency, setCurrency] = useState("inr");

  // useEffect(() => {
  //   if (loginState.owner.type !== "broker") {
  //     fetchUnderUsersHandler();
  //   }
  //   fetchUnderClientsHandler();
  // }, []);

  useEffect(() => {
    if (recieverType === "user") {
      setOptions(underUserOptions);
    } else {
      setOptions(underCientOptions);
    }
    setRecieverId(null);
  }, [recieverType, underUserOptions, underCientOptions]);

  const loadOptionsClient = async (inputValue) => {
    let data = [];
    data = await fetchUnderClientsHandler(inputValue);
    return { options: data };
  }

  const loadOptionsUser = async (inputValue) => {
    let data = [];
    data = await fetchUnderUsersHandler(inputValue);
    return { options: data };
  }

  async function fetchUnderUsersHandler(username) {
    try {
      let data = {
        username: username
      }
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(SEARCH_USER_BY_USERNAME_URL, { params: data });
      if (res.data.status === "SUCCESS") {
        let temp = res.data.payload.map((user) => {
          return { value: user.id, label: user.username };
        });
        setUnderUserOptions(temp);
        return temp;
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function fetchUnderClientsHandler(username) {
    try {
      // let data = {
      //   jwttoken: loginState.jwttoken,
      // };
      let data = {
        username: username
      }
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(SEARCH_CLIENT_BY_USERNAME_URL, { params: data });
      if (res.data.status === "SUCCESS") {
        let temp = [];
        temp = res.data.payload.map((user) => {
          return { value: user.id, label: user.username };
        });
        setUnderClientOptions(temp);
        return temp;
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function createFundHandler() {
    try {
      if (recieverId === null) {
        toast.error("Please select a user");
        return;
      }
      if (amount === "" || amount === 0) {
        toast.error("amount required");
        return;
      }
      let data = {
        reciever_id: recieverId.value,
        reciever_type: recieverType,
        amount: amount,
        type: type,
        currency: currency
      };
      if (note !== "") {
        data.note = note;
      }
      // if (recieverType === "user") {
      //   data.wallet_type = walletType;
      // }
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = "";
      if (recieverType === "client") {
        res = await api.post(CREATE_CLIENT_FUND_URL, data);
      } else {
        res = await api.post(CREATE_USER_FUND_URL, data);
      }
      if (res.data.status === "SUCCESS") {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <>
      <Container fluid={true} className="datatables">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="p-b-0 b-b-dark border-1">
                <div className="justify-content-between align-items-center mb-3 row">
                  <div className="col-4 col">
                    <h5 className="m-0 p-0">
                      <i className="fa fa-money"></i> Create Fund
                    </h5>
                  </div>
                  {/* <div className="col-4 col  text-end">
                    <button
                      className="btn btn-primary mx-2"
                      onClick={createFundHandler}
                    >
                      Create
                    </button>
                    <button
                      className="btn btn-outline-secondary"
                      onClick={() => navigate("/fund")}
                    >
                      Cancel
                    </button>
                  </div> */}
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm={12} className="mb-4">
                    <button
                      className={`btn ${recieverType === "user"
                        ? "btn-primary"
                        : "btn-outline-primary"
                        }`}
                      onClick={() => setRecieverType("user")}
                    >
                      User
                    </button>
                    <button
                      className={`btn mx-3 ${recieverType === "client"
                        ? "btn-primary"
                        : "btn-outline-primary"
                        }`}
                      onClick={() => setRecieverType("client")}
                    >
                      Client
                    </button>
                  </Col>

                  <Col sm={12} className="mb-4">
                    <label className="form-label">
                      User{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    {/* <Select
                      // options={options}
                      className="js-example-basic-single col-sm-12"
                      // value={recieverId}
                      isClearable={true}
                      isSearchable={true}
                      // loadOptions={loadOptions}
                      onChange={(e) => { console.log(e) }}
                    // onChange={(e) => { setRecieverId(e) }}
                      />*/}
                    {recieverType === "client" && <AsyncPaginate
                      className="js-example-basic-single col-sm-12"
                      value={recieverId}
                      isClearable={true}
                      loadOptions={loadOptionsClient}
                      // onChange={setValue}
                      onChange={(e) => { setRecieverId(e) }}
                    />}

                    {recieverType === "user" && <AsyncPaginate
                      className="js-example-basic-single col-sm-12"
                      value={recieverId}
                      isClearable={true}
                      loadOptions={loadOptionsUser}
                      // onChange={setValue}
                      onChange={(e) => { setRecieverId(e) }}
                    />}
                  </Col>



                  <Col sm={12} className="mb-3">
                    <div className="radio radio-primary d-inline-block me-4 ps-2">
                      <input type="radio" checked={type === "deposit"} />
                      <Label onClick={() => setType("deposit")}>Deposit</Label>
                    </div>

                    <div className="radio radio-danger d-inline-block">
                      <input type="radio" checked={type === "withdraw"} />
                      <Label onClick={() => setType("withdraw")}>
                        Withdraw
                      </Label>
                    </div>
                  </Col>

                  {/* <Collapse isOpen={recieverType === "user"}>
                    {" "}
                    <Col sm={12} className="mb-4">
                      <button
                        className={`btn ${walletType === "primary"
                          ? "btn-primary"
                          : "btn-outline-primary"
                          }`}
                        onClick={() => setWalletType("primary")}
                      >
                        <i className="icon-wallet"></i>&nbsp; Primary
                      </button>
                      <button
                        className={`btn mx-3 ${walletType === "pl"
                          ? "btn-secondary"
                          : "btn-outline-secondary"
                          }`}
                        onClick={() => setWalletType("pl")}
                      >
                        <i className="icon-wallet"></i>&nbsp; Profit Loss
                      </button>
                      <button
                        className={`btn ${walletType === "rented"
                          ? "btn-danger"
                          : "btn-outline-danger"
                          }`}
                        onClick={() => setWalletType("rented")}
                      >
                        <i className="icon-wallet"></i>&nbsp; Rented
                      </button>
                    </Col>
                  </Collapse> */}



                  {" "}
                  <Col sm={12} className="mb-4">
                    <button
                      className={`btn ${currency === "inr"
                        ? "btn-primary"
                        : "btn-outline-primary"
                        }`}
                      onClick={() => setCurrency("inr")}
                    >
                      <i className="icon-wallet"></i>&nbsp; INR
                    </button>
                    <button
                      className={`btn mx-3 ${currency === "usd"
                        ? "btn-secondary"
                        : "btn-outline-secondary"
                        }`}
                      onClick={() => setCurrency("usd")}
                    >
                      <i className="icon-wallet"></i>&nbsp; USD
                    </button>
                  </Col>

                  <Col sm={12} className="mb-4">
                    <label className="form-label">
                      Amount{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Amount"
                      value={amount}
                      onChange={(e) => {
                        if (!(e.target.value < 0)) {
                          setAmount(e.target.value);
                        }
                      }}
                    />
                  </Col>

                  <Col sm={12}>
                    <label className="form-label">Note</label>
                    <textarea
                      className="form-control"
                      value={note}
                      onChange={(e) => {
                        setNote(e.target.value);
                      }}
                    ></textarea>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm={12} className="text-end">
                      <button className="btn btn-primary mx-2" onClick={createFundHandler} > Create </button>
                      <button className="btn btn-outline-secondary" onClick={() => navigate("/fund")} > Cancel </button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default GeneralComponent;
