import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import ProfitLossCards from "./ProfitLossCards";
import DashboardDetail from "./DashboardDetail";
import { Breadcrumbs } from "../../../AbstractElements";
import { H2, H4, H5 } from "../../../AbstractElements";
import { useSelector, useDispatch } from "react-redux";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import { FETCH_DASHBOARD_DATA_URL } from "../../../_helper/url_helper";
import { fetchOwner } from "../../../store/login/action";
import Customselect from "./Customselect";
import './Custom.css'


import Uppercardcomp from "./Uppercardcomp";
import Profitlosscomp from "./Profitlosscomp";
import Brokeragecomp from "./Brokeragecomp";
import Balancecomp from "./Balancecomp";
import Totalturnovercomp from "./Totalturnovercomp";

const GeneralComponent = () => { 

  return (
    <Fragment>
      <Container fluid={true} className="general-widget">
        <Row>
          <Col>            
            <Uppercardcomp/>
            <Profitlosscomp/>
            <Brokeragecomp/>
            <Balancecomp/>
            <Totalturnovercomp/>          
          </Col>
        </Row>       
      </Container>
    </Fragment>
  );
};

export default GeneralComponent;
