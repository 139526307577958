import React, { useEffect, useRef } from "react";
import "./Modal.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { DOMAIN_SETTINGS_UPDATE } from "../../../_helper/url_helper";
import { useNavigate } from "react-router-dom";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const Settingmodal = ({ show, onClose, title, setmarketid }) => {
  const isMounted = useRef(true);
  const loginState = useSelector((state) => state.login);

  useEffect(() => {
    isMounted.current = true; // Mark component as mounted
    return () => {
      isMounted.current = false; // Cleanup on unmount
    };
  }, []);

 

  if (!show) return null;

  const initialValues = {
    // broker_id: setmarketid?.id || "",
    broker_name: setmarketid?.firstname || "",
    // theme_id: setmarketid?.theme_id || "",
    domain_name: "",
    status: setmarketid?.status === "active" ? "Active" : "Inactive",
  };

  const validationSchema = Yup.object({
    broker_name: Yup.string().required("User Name is required"),
    // theme_id: Yup.string().required("Theme ID is required"),
    domain_name: Yup.string()
      .matches(
        /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/,
        "Enter a valid domain name"
      )
      .required("Domain name is required"),
    status: Yup.string().oneOf(["Active", "Inactive"], "Invalid status"),
  });

 

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    const formData = {
      broker_id: values.broker_id,
      // theme_id: values.theme_id,
      domain_name: values.domain_name,
      status: values.status,
    };
  
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      const res = await api.post(DOMAIN_SETTINGS_UPDATE, formData);
      if (isMounted.current) {
        if (res.data.status === "SUCCESS") {
          toast.success(res.data.message);
          resetForm();
          onClose();
        } else {
          toast.error(res.data.message || "An error occurred");
        }
      }
    } catch (error) {
      if (isMounted.current) {
        console.error("Error during form submission:", error); // Debug error
        toast.error(error.response?.data?.message || "An error occurred");
      }
    } finally {
      if (isMounted.current) setSubmitting(false); // Stop submitting state
    }
  };
  

  return (
    <div className="modal" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()} style={{ textAlign: "left" }}>
        <span className="close-btn" onClick={onClose}>
          &times;
        </span>
        <h4>{title || "Domain Settings"}</h4>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="mb-3">
                <label htmlFor="broker_name">User Name</label>
                <Field name="broker_name" type="text" className="form-control" readOnly />
                <ErrorMessage name="broker_name" component="div" className="text-danger" />
              </div>

              <div className="mb-3">
                <label htmlFor="domain_name">Domain</label>
                <Field name="domain_name" type="text" className="form-control" placeholder="Enter domain name" />
                <ErrorMessage name="domain_name" component="div" className="text-danger" />
              </div>

              <div className="mb-3">
                <label>Status</label>
                <div className="d-flex align-items-center">
                  <Field type="radio" name="status" value="Active" id="active" className="me-2" />
                  <label htmlFor="active" className="me-4">Active</label>
                  <Field type="radio" name="status" value="Inactive" id="inactive" className="me-2" />
                  <label htmlFor="inactive">Inactive</label>
                </div>
                <ErrorMessage name="status" component="div" className="text-danger" />
              </div>

              <div className="d-flex justify-content-end">
                <button type="button" className="btn btn-outline-danger me-3" onClick={onClose}>
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Settingmodal;
