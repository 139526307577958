import React, { Fragment, useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import {
  CardBody,
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Collapse,
  Table,
} from "reactstrap";
import { H2, H4, H5 } from "../../../AbstractElements";
import { Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import {
  FETCH_ORDERS_URL,
  CANCEL_ORDER_URL,
} from "../../../_helper/url_helper";
import { useNavigate } from "react-router-dom";
import { useDraggable } from "react-use-draggable-scroll";
import ModifyOrderModal from "./ModifyOrderModal";
import { convertToIndianTime, parseDateRange } from "../../../_helper/dateFormate";
import CustomSelect from "../Dashboard/Customselect";

const GeneralComponent = () => {
  const navigate = useNavigate();
  const loginState = useSelector((state) => state.login);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  // const [startDate, setstartDate] = useState(new Date());
  const [orders, setOrders] = useState([]);
  const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
  const { events } = useDraggable(ref);
  const [modifyOrderModalStatus, setModifyOrderModalStatus] = useState(false);
  const [order, setOrder] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [status, setStatus] = useState("all");
  // const [username, setUsername] = useState("");
  // const [script, setScript] = useState("");
  const [filters, setFilters] = useState({ username: "", script: "", status: "all" });
  const [pagination, setPagination] = useState({ current: 1, total: 1, totalRows: 0 });

  useEffect(() => {
    fetchOrdersHandler();
  }, []);


  useEffect(() => {
    fetchOrdersHandler();
  }, [filters]);

  function setPage(page) {
    if (page < 1 || page > pagination.total) {
      return;
    }
    // setPagination({ ...pagination, current: page });
    fetchOrdersHandler(page);
  }

  // useEffect(() => {
  //   let temp = [];
  //   allOrders.forEach((order) => {
  //     if (status === "all") {
  //       temp.push(order);
  //     } else if (status === "pending" && order.status === "pending") {
  //       temp.push(order);
  //     } else if (status === "close" && order.status !== "pending") {
  //       temp.push(order);
  //     }
  //   });
  //   setOrders(temp);
  // }, [allOrders, status]);

  useEffect(() => {
    if (modifyOrderModalStatus === false) {
      fetchOrdersHandler();
    }
  }, [modifyOrderModalStatus]);

  // useEffect(() => {
  //   let interval = setInterval(fetchOrdersHandler, 20000);
  //   return () => clearInterval(interval);
  // }, []);

  async function fetchOrdersHandler(page = 1,option) {
    try {
      let data = {};
      const formattedDates=parseDateRange(option?.value)
      const startDate=formattedDates?.startDate
      const endDate=formattedDates?.endDate

      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      // let query = "";
      if (filters.status !== 'all') {
        data.status = filters.status;
      }
      if (filters.username !== '') {
        data.username = filters.username;
      }
      if (filters.script !== '') {
        data.script = filters.script;
      }
      data.page = page;
      data.filter=option?.value;
      // data.date_filter = option?.value;
      if(option?.label=="custom"||option?.label=="Custom"){
        data.filter='custom'
        data.start_date=startDate
        data.end_date=endDate
      }
      let res = await api.get(`${FETCH_ORDERS_URL}`, { params: data });
      if (res.data.status === "SUCCESS") {
        if (res.data.payload.data !== undefined) {
          setOrders(res.data.payload.data);
          setPagination({ current: res.data.payload.current_page, total: res.data.payload.last_page, totalRows: res.data.payload.total });
        } else {
          setOrders([]);
        }

      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
  const handleDateFilterSubmit=(option)=>{
    fetchOrdersHandler(1,option)
  }
  async function cancelOrderHandler(orderId) {
    try {
      if (!window.confirm("Are you sure?")) {
        return;
      }
      let data = {
        jwttoken: loginState.jwttoken,
        order_id: orderId,
      };
      let res = await api.post(CANCEL_ORDER_URL, data);
      if (res.data.status === 1) {
        fetchOrdersHandler();
        toast.success("Order canceled");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <Fragment>
      <ModifyOrderModal
        order={order}
        modifyOrderModalStatus={modifyOrderModalStatus}
        setModifyOrderModalStatus={setModifyOrderModalStatus}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Accordion defaultActiveKey="0">
              <div className="default-according" id="accordion1">
                <Card className="mb-4">
                  <CardHeader
                    className="bg-secondary"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <h5 className="d-flex justify-content-between">
                      <span>Filters</span>
                      {isOpen ? (
                        <i className="icofont icofont-rounded-up"></i>
                      ) : (
                        <i className="icofont icofont-rounded-down"></i>
                      )}
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={isOpen}>
                    <CardBody>
                      <div className="row">
                        {/* <div className="col">
                          <label className="col-form-label form-label">
                            Search Script
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Script"
                          />
                        </div> */}

                        <div className="col">
                          <label className="col-form-label form-label">
                            Status
                          </label>
                          <select value={filters.status} className="form-control" onChange={(e) => { setFilters({ ...filters, status: e.target.value }) }}>
                            <option value="all">All</option>
                            <option value="pending">Pending</option>
                            <option value="complete">Complete</option>
                            <option value="cancel">Canceled</option>
                          </select>
                        </div>

                        <div className="col">
                          <label className="col-form-label form-label">
                            Username
                          </label>
                          <input type="text" placeholder="Enter username" value={filters.username} className="form-control" onChange={(e) => { setFilters({ ...filters, username: e.target.value }) }} />
                        </div>

                        <div className="col">
                          <label className="col-form-label form-label">
                            Script
                          </label>
                          <input type="text" placeholder="Enter script" value={filters.script} className="form-control" onChange={(e) => { setFilters({ ...filters, script: e.target.value }) }} />
                        </div>

                        {/* <div className="col row align-items-end">
                          <div>
                            <button className="btn btn-danger m-t-5">
                              Reset
                            </button>
                          </div>
                        </div> */}
                        <div className="col">
                          <label className="col-form-label form-label"> Filter by Date </label>
                          <CustomSelect onOptionSelect={handleDateFilterSubmit}/>
                        </div>
                      </div>
                    </CardBody>
                  </Collapse>
                </Card>
              </div>
            </Accordion>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="py-3">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Orders</h5>
                  {/* <button className="btn btn-primary">Create</button> */}
                </div>
              </CardHeader>
              <div className="table-responsive" ref={ref} {...events}>
                <Table className="table-border-horizontal fw-semibold" striped>
                  <thead>
                    <tr className="bg-primary">
                      <th style={{ color: "#ffffff" }}>ID</th>
                      <th style={{ color: "#ffffff" }}>Script</th>
                      <th style={{ color: "#ffffff" }}>User</th>
                      <th style={{ color: "#ffffff" }}>Type</th>
                      <th style={{ color: "#ffffff" }}>OrderType</th>
                      <th style={{ color: "#ffffff" }}>Qty</th>
                      <th style={{ color: "#ffffff" }}>Price</th>
                      <th style={{ color: "#ffffff" }}>Currency</th>
                      {/* <th style={{ color: "#ffffff" }}>Lots</th> */}
                      <th style={{ color: "#ffffff" }}>IPAddress</th>
                      <th style={{ color: "#ffffff" }}>CDI</th>
                      <th style={{ color: "#ffffff" }}>Status</th>
                      <th style={{ color: "#ffffff" }}>OrderAt</th>
                      <th style={{ color: "#ffffff" }}>Action</th>
                      {/* <th style={{color:"#ffffff"}}>Net</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {orders.length !== undefined ? (
                      orders.map((order) => {
                        return (
                          <tr key={order.id}>
                            <td>{order.id}</td>
                            <td style={{textTransform:"uppercase"}}>{order.trading_symbol}</td>
                            <td style={{textTransform:"uppercase"}}>{order.client_name}</td>
                            <td style={{fontSize:"14px"}}>
                              {order.type === "buy" ? (
                                <span style={{fontSize:"14px"}} className="text-success fw-semibold">
                                  BUY
                                </span>
                              ) : (
                                <span style={{fontSize:"14px"}} className="text-danger fw-semibold">
                                  SELL
                                </span>
                              )}
                            </td>
                            <td style={{textTransform:"uppercase"}}>{order.order_type}</td>
                            <td style={{textTransform:"uppercase"}}>{order.qty}</td>
                            <td style={{textTransform:"uppercase"}}>{order.price}</td>
                            <td style={{textTransform:"uppercase"}}>{order.currency}</td>
                            {/* <td>
                              {order.type === "buy"
                                ? parseFloat(order.lot).toFixed(2)
                                : "-" + parseFloat(order.lot).toFixed(2)}
                            </td> */}
                            <td style={{textTransform:"uppercase"}}>{order.from_ip_address}</td>
                            <td style={{textTransform:"uppercase"}}>{order.from_custom_device_id}</td>
                            <th style={{textTransform:"uppercase"}}>
                              {order.status === "complete" ? (
                                <span className="badge bg-success">
                                  Complete
                                </span>
                              ) : order.status === "pending" ? (
                                <span className="badge bg-secondary">
                                  Pending
                                </span>
                              ) : (
                                <span className="badge bg-danger">Cancel</span>
                              )}
                            </th>{" "}
                            <td className="text-nowrap">{convertToIndianTime(order.added_on)}</td>
                            <td
                              className="text-nowrap"
                              style={{ width: "200px" }}
                            >
                              {order.status === "pending" && (
                                <>
                                  <button
                                    className="btn btn-primary btn-xs mx-2"
                                    onClick={() => {
                                      setOrder(order);
                                      setModifyOrderModalStatus(true);
                                    }}
                                  >
                                    Modify
                                  </button>
                                  <button
                                    className="btn btn-outline-danger btn-xs"
                                    onClick={() => {
                                      cancelOrderHandler(order.id);
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={12} className="text-center">
                          Orders Not found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <div className="pagination mt-3 d-flex justify-content-between align-items-center mb-3 px-3" >
                <div>
                  <span className="text-semibold">Page {pagination.current} out of {pagination.total}</span>
                </div>
                <div>
                  <button className="btn btn-outline-primary" onClick={() => { setPage(pagination.current - 1) }}>Prev</button>
                  <input type="number" className="form-control d-inline mx-3 text-center fw-semibold" style={{ maxWidth: '100px' }} min="1" value={pagination.current} onChange={(e) => { setPage(e.target.value) }} />
                  {/* <span>of</span> */}
                  {/* <span id="totalPages">10</span> */}
                  <button className="btn btn-outline-primary" onClick={() => { setPage(pagination.current + 1) }}>Next</button>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GeneralComponent;
