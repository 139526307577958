import React, { Fragment, useContext } from 'react';
import { LI } from '../../../AbstractElements';
import ConfigDB from '../../../Config/ThemeConfig';
import CustomizerContext from '../../../_helper/Customizer';

const SidebarToHeader = () => {
    const { addSidebarTypes } = useContext(CustomizerContext);
    const sidebarType = localStorage.getItem('sidebar_types') || ConfigDB.data.settings.sidebar.type

    return (
        <Fragment>
            <LI>
                <div className="mode" onClick={() => addSidebarTypes((sidebarType === "compact-wrapper")?"horizontal-wrapper":"compact-wrapper")} >
                    {(sidebarType === "compact-wrapper") ? <i className="fa fa-stop"></i> : <i className="fa fa-align-left"></i>}
                </div>
            </LI>
        </Fragment>
    );
};

export default SidebarToHeader;