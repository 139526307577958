import React, { useEffect, useState } from "react";
import profitlossicon from "../../../assets/images/activeprofitloss.svg";
import activetradesicon from "../../../assets/images/activetrades.svg";
import activetradersicon from "../../../assets/images/activetraders.svg";
import brokerageicon from "../../../assets/images/brokerage.svg";
import swapicon from "../../../assets/images/swap.svg";
import dollerswapicon from "../../../assets/images/dollerswap.svg";
import inrswapicon from "../../../assets/images/inrswap.svg";
import { Col, Row } from "reactstrap";
import CustomSelect from "./Customselect";
import { fetchOwner } from "../../../store/login/action";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../_helper/api_helper";
import { FETCH_DASHBOARD_DATA_URL_TOTAL_VIEW } from "../../../_helper/url_helper";
import { toast } from "react-toastify";
import { parseDateRange } from "../../../_helper/dateFormate";
import BeatLoader from "react-spinners/BeatLoader";

const Uppercardcomp = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.login);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState("inr");

  useEffect(() => {
    fetchDataHandler();
    dispatch(fetchOwner({ jwttoken: loginState.jwttoken }));
  }, []);

  useEffect(()=>{
    let timeout;
    const fetchDataWithTimeout=()=>{
      fetchDataHandler(selectedOption);
      timeout=setTimeout(fetchDataWithTimeout,5000);
    }
    fetchDataWithTimeout();
    return ()=>clearTimeout(timeout)
  },[selectedOption])

  useEffect(() => {
    if (loginState.owner.status === "inactive") {
      localStorage.removeItem("jwttoken");
      window.location.href = "/";
    }
  }, [loginState]);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    fetchDataHandler(option);
  };

  const fetchDataHandler = async (option) => {
    setLoading(true);
    const jwttoken = loginState.jwttoken;
    const formattedDates = parseDateRange(option?.value);
    const startDate = formattedDates?.startDate;
    const endDate = formattedDates?.endDate;

    try {
      let dataObj = {
        jwttoken,
        filter: option?.value,
      };

      if (option?.label?.toLowerCase() === "custom") {
        dataObj.filter = "custom";
        dataObj.start_date = startDate;
        dataObj.end_date = endDate;
      }

      api.defaults.headers.common["Authorization"] = `Bearer ${jwttoken}`;
      const res = await api.post(FETCH_DASHBOARD_DATA_URL_TOTAL_VIEW, dataObj);

      if (res.data.status === "SUCCESS") {
        setData(res.data.payload);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const toggleCurrency = (newCurrency) => {
    setCurrency(newCurrency);
  };

  return (
    <>
      <Row className="d-flex px-3 flex-column flex-sm-row align-items-start align-items-sm-center">
        <Col className="px-0">
          <h2>Welcome to the Trade Learning</h2>
        </Col>
        <Col style={{ display: "contents" }}>
          <CustomSelect onOptionSelect={handleOptionSelect} />
        </Col>
      </Row>
      <div className="newtradecard">
        {[
          {
            label: "Active Profit/Loss",
            value: currency === "inr" ? data?.active_profit_loss_inr : data?.active_profit_loss_usd,
            icon: profitlossicon,
          },
          {
            label: "Active Trades",
            value: currency === "inr" ? data?.active_trades_inr : data?.active_trades_usd,
            icon: activetradesicon,
          },
          {
            label: "Active Traders",
            value: data?.active_traders,
            icon: activetradersicon,
          },
          {
            label: "Brokerage",
            value: currency === "inr" ? data?.brokerage_inr : data?.brokerage_usd,
            icon: brokerageicon,
          },
          {
            label: "Margin Used",
            value: currency === "inr" ? data?.used_margin_inr : data?.used_margin_usd,
            icon: profitlossicon,
          },
        ].map((card, index) => (
          <div className="newtradecardinner" key={index}>
            <div className="d-flex justify-content-between align-items-center">
              <p>{card.label}</p>
              <div className="icondiv" style={{ background: "#883dcf2e" }}>
                <img src={card.icon} alt="" />
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-end lower">
              <div>
                <h2>
                  {loading ? <BeatLoader color="rgba(37, 146, 213, 1)" /> : Number(card.value || 0).toFixed(2)}
                </h2>
                <span>
                  <p>
                    <b style={{ color: card.value > 0 ? "green" : "red" }}>
                      10% <i className={`fa ${card.value > 0 ? "fa-caret-up" : "fa-caret-down"}`} />
                    </b>{" "}
                    + $120
                  </p>
                </span>
              </div>
              {index !== 2 && ( // Exclude "Active Traders" because it doesn't have INR/USD toggle
                <div>
                  <img
                    onClick={() => toggleCurrency("inr")}
                    src={inrswapicon}
                    alt=""
                    style={{
                      cursor: "pointer",
                      backgroundColor: currency === "inr" ? "red" : "transparent",
                      padding: "1px",
                      borderRadius: "5px",
                    }}
                  />
                  <img src={swapicon} alt="" />
                  <img
                    onClick={() => toggleCurrency("us")}
                    src={dollerswapicon}
                    alt=""
                    style={{
                      cursor: "pointer",
                      backgroundColor: currency === "us" ? "red" : "transparent",
                      padding: "1px",
                      borderRadius: "5px",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Uppercardcomp;
