import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOwner } from "../../../store/login/action";
import { FETCH_CLIENT_TRANSACTIONS_ANALYSIS } from "../../../_helper/url_helper";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import Paginationcomp from "./Paginationcomp";
import visibilityeye from "../../../assets/images/visibility_eye.svg";
import './Transaction.css';
import CustomSelectDateOnly from "../Dashboard/CustomSelectDateOnly";
import { parseDateRange } from "../../../_helper/dateFormate";

const FundAnalysis = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [activeTab, setActiveTab] = useState("INR");
    const dispatch = useDispatch();
    const loginState = useSelector((state) => state.login);
    const [data, setData] = useState({ USD: {}, INR: {} });
    const [resultsPerPage, setResultsPerPage] = useState(10);
    const [pagination, setPagination] = useState({ current: 1, total: 1, totalRows: 0 });

    useEffect(() => {
        fetchDataHandler();
        dispatch(fetchOwner({ jwttoken: loginState.jwttoken }));
    }, [dispatch, loginState.jwttoken, pagination.current, activeTab, resultsPerPage]);

    useEffect(() => {
        if (loginState.owner.status === "inactive") {
            localStorage.removeItem("jwttoken");
            window.location.href = "/";
        }
    }, [loginState]);

    const fetchDataHandler = async (option) => {
        try {
            const formattedDates=parseDateRange(option?.value);
            const startDate=formattedDates?.startDate;
            const endDate=formattedDates?.endDate;
            let params = { page: pagination.current, per_page: resultsPerPage };
            if(option?.label==="custom"||option?.label==="Custom"){
                params.start_date=startDate;
                params.end_date=endDate;
            }else if(option?.value){
                params.filter=option?.value
            }
            api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
            let res = await api.get(FETCH_CLIENT_TRANSACTIONS_ANALYSIS, { params });

            // console.log(`Full API Response for ${activeTab}, Page ${pagination.current}:`, JSON.stringify(res.data, null, 2));

            if (res.data.status === "SUCCESS") {
                const newData = res.data.payload;
                const tabData = newData[activeTab] || {};

                if (!tabData.transaction || !Array.isArray(tabData.transaction.data)) {
                    console.warn(`No valid transaction data for ${activeTab} on page ${pagination.current}`);
                    setData(prevData => ({
                        ...prevData,
                        [activeTab]: {
                            ...tabData,
                            transaction: { 
                                data: [], 
                                current_page: pagination.current, 
                                last_page: tabData.transaction?.last_page || 1, 
                                total: tabData.transaction?.total || 0 
                            }
                        }
                    }));
                    setPagination(prev => ({
                        ...prev,
                        total: tabData.transaction?.last_page || 1,
                        totalRows: tabData.transaction?.total || 0
                    }));
                    return;
                }

                // Explicitly replace the transaction data for the active tab
                setData(prevData => {
                    const updatedData = {
                        ...prevData,
                        [activeTab]: {
                            ...tabData,
                            transaction: {
                                ...tabData.transaction,
                                data: [...tabData.transaction.data] // Ensure a new array reference
                            }
                        }
                    };
                    console.log(`Updated data state for ${activeTab} after page ${pagination.current}:`, JSON.stringify(updatedData[activeTab], null, 2));
                    return updatedData;
                });

                setPagination({
                    current: tabData.transaction.current_page,
                    total: tabData.transaction.last_page,
                    totalRows: tabData.transaction.total,
                });
            } else {
                toast.error(res.data.message);
            }
        } catch (error) {
            toast.error("Error fetching data: " + error.message);
        }
    };

    const setPage = (page) => {
        if (page >= 1 && page <= pagination.total) {
            setPagination(prev => ({ ...prev, current: page }));
        }
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setPagination(prev => ({ ...prev, current: 1 }));
    };

    const handleResultsPerPageChange = (newResultsPerPage) => {
        setResultsPerPage(newResultsPerPage);
        setPagination(prev => ({ ...prev, current: 1 }));
    };

    const handleOptionSelect = (option) => {
        setSelectedOption(option)
        fetchDataHandler(option)
    }

    const convertToIST = (timestamp) => {
        if (!timestamp) return "Invalid Date";
      
        let date = new Date(timestamp);
        if (isNaN(date.getTime())) {
            console.error("Invalid date format:", timestamp);
            return "Invalid Date";
        }
      
        // Convert to IST (UTC + 5:30)
        const istOffset = 5.5 * 60 * 60 * 1000;
        date = new Date(date.getTime() + istOffset);
      
        // Format the date (e.g., "26 Mar 2025, 01:50 AM")
        return date.toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata",
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true
        });
      };

    return (
        <div className="containerdiv">
            <div className="filterdiv mb-4">
                <h2>Fund Analysis</h2>
                <CustomSelectDateOnly onOptionSelect={handleOptionSelect}/>
            </div>

            <div className="tabdiv">
                <ul className="nav nav-tabs">
                    {["INR", "USD"].map((currency) => (
                        <li className="nav-item" key={currency}>
                            <a
                                className={`nav-link ${activeTab === currency ? "active" : ""}`}
                                href={`#${currency}`}
                                onClick={(e) => { e.preventDefault(); handleTabChange(currency); }}
                            >
                                {currency}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>

            <div className="tab-content mt-3">
                <div className="fundheader">
                    {["total_deposit", "total_withdraw", "total_bonus", "highest_deposit"].map((key, index) => (
                        <div className="fundcard" key={index}>
                            <div className="card">
                                <div className="card-body">
                                    <p>{key.replace(/_/g, " ").toUpperCase()}</p>
                                    <h4>
                                        {activeTab === "INR"
                                            ? `₹ ${data?.[activeTab]?.[key] || "0"}`
                                            : `$ ${Number(data?.[activeTab]?.[key] || 0).toFixed(2)}`}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="containerdiv p-0" style={{ background: "transparent" }}>
                <div className="table-responsive mt-4">
                    <table className="table table-bordered">
                        <thead className="table-secondary">
                            <tr>
                                <th>ID</th>
                                <th>Amount</th>
                                <th>Type</th>
                                <th>Status</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.[activeTab]?.transaction?.data?.length > 0 ? (
                                data[activeTab].transaction.data.map((txn, index) => (
                                    <tr key={index}>
                                        <td style={{textTransform:"uppercase"}}>{(pagination.current - 1) * resultsPerPage + index + 1}</td>
                                        <td style={{textTransform:"uppercase"}}>{activeTab === "INR" ? `₹ ${txn.amount}` : `$ ${Number(txn.amount).toFixed(2)}`}</td>
                                        <td style={{ color: txn.type === "deposit" ? "green" : "red",textTransform:"uppercase" }}>{txn.type}</td>
                                        <td style={{textTransform:"uppercase"}}>
                                            <span className={`badge bg-${txn.status === "complete" ? "success" : txn.status === "pending" ? "warning" : "danger"}`}>
                                                {txn.status}
                                            </span>
                                        </td>
                                        <td style={{textTransform:"uppercase"}}>{convertToIST(txn.date)}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr><td colSpan="5" className="text-center">No Transactions Found</td></tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <Paginationcomp
                    currentPage={pagination.current}
                    totalPages={pagination.total}
                    onPageChange={setPage}
                    totalResults={pagination.totalRows}
                    resultsPerPage={resultsPerPage}
                    onResultsPerPageChange={handleResultsPerPageChange}
                />
            </div>
        </div>
    );
};



export default FundAnalysis;