import React, { Fragment, useState } from "react";
import { Card, Col, Row, Collapse, Table } from "reactstrap";
import { Accordion } from "react-bootstrap";

const ScriptTable = (props) => {
  function changeMcxMarginFixHandler(value, symbol) {
    if (value < 0) {
      return;
    }
    const temp = JSON.parse(JSON.stringify(props.mcxMarginFix));
    temp[symbol] = value;
    props.setMcxMarginFix(temp);
  }
  return (
    <Fragment>
      <Accordion defaultActiveKey="0">
        <div className="default-according" id="accordion1">
          <Collapse
            isOpen={
              props.exchange === "mcx" && props.mcxMarginType === "fix"
            }
          >
            <Row>
              <Col sm="12">
                <Card>
                  <Table
                    className="table-border-horizontal fw-semibold align-middle"
                    striped
                  >
                    <thead>
                      <tr className="bg-primary">
                        <th style={{ color: "#ffffff" }}>Script</th>
                        {/* <th style={{ color: "#ffffff" }}>LotSize</th> */}
                        {/* <th style={{ color: "#ffffff" }}>LTP</th> */}
                        <th style={{ color: "#ffffff" }}>Intraday(MIS)</th>
                        <th style={{ color: "#ffffff" }}>Longterm(CNC)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.mcxSymbols.length
                        ? props.mcxSymbols.map((symbol, idx) => {
                            return (
                              <tr key={idx}>
                                <td>{symbol["symbol"]}</td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter margin"
                                    value={
                                      props.mcxMarginFix[symbol["symbol"]+"_margin_mis"]
                                    }
                                   readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter margin"
                                    value={
                                      props.mcxMarginFix[symbol["symbol"]+"_margin_cnc"]
                                    }
                                   readOnly
                                  />
                                </td>
                              </tr>
                            );
                          })
                        : ""}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
          </Collapse>
        </div>
      </Accordion>
    </Fragment>
  );
};

export default ScriptTable;

