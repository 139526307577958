import axios from "axios";
import { useSelector } from "react-redux"; // Import the useSelector hook

// console.log("Admin API URL:", process.env.REACT_APP_ADMIN_URL);

const API = axios.create({    
    baseURL: process.env.REACT_APP_ADMIN_URL,
});

export default API;
