import React, { useState } from "react";
import { Dropdown, Form, Button } from "react-bootstrap";
import { FaCalendarAlt } from "react-icons/fa";
import './Transaction.css'

const CustomFilter = ({ statusOptions, onSubmit }) => {
  const [filters, setFilters] = useState({
    userName: "",
    usernameType:"",
    status: "all",
    contactNo: "",
  });

  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (onSubmit) onSubmit(filters);
  };

  return (
    <div className="customfilter">
      <Dropdown>
        <Dropdown.Toggle  className="dropdownbtn" id="dropdown-basic">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#4A4C56"><path d="M400-240v-80h160v80H400ZM240-440v-80h480v80H240ZM120-640v-80h720v80H120Z"/></svg> Filter
        </Dropdown.Toggle>

        <Dropdown.Menu className="p-3 customfilterselected">
          <Form>
            <Form.Group className="mb-3" controlId="formUserName">
              <Form.Label> User Name </Form.Label>
              <div className="d-flex checkboxnew">
                <Form.Check type="radio" label="User" name="usernameType" value="user" checked={filters.usernameType === "user"} onChange={handleInputChange} className="me-3 moonlightmode" />
                <Form.Check className="moonlightmode" type="radio" label="Broker" name="usernameType" value="broker" checked={filters.usernameType === "broker"} onChange={handleInputChange} />
              </div>
              <Form.Control
                type="text"
                placeholder="Enter user name"
                name="userName"
                value={filters.userName}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formStatus">
              <Form.Label>Status</Form.Label>             
              <Form.Select
                name="status"
                value={filters.status}
                onChange={handleInputChange}
              >
                {statusOptions.map((option) => (
                  <option key={option} value={option}>
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formContactNo">
              <Form.Label>Contact No</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter contact number"
                name="contactNo"
                value={filters.contactNo}
                onChange={handleInputChange}
              />
            </Form.Group>

            {/* Buttons */}
            <div className="d-flex justify-space-evenly">
              <Button variant="outline-danger" onClick={() => setFilters({ userName: "", status: "all", contactNo: "" }) } > <i className="bi bi-x-circle"></i> Cancel </Button>
              <Button variant="primary" onClick={handleSubmit}>
                <i className="bi bi-check-circle"></i> Submit
              </Button>
            </div>
          </Form>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default CustomFilter;
