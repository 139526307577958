import React, { useRef, useState } from 'react'
import Paginationcomp from '../TransactionAnalysis/Paginationcomp'
import actioncopy from '../../../assets/images/action-copy.svg'
import actionedit from '../../../assets/images/action-edit.svg'
import actiondelete from '../../../assets/images/action-delete.svg'
import CustomSelect from '../Dashboard/Customselect'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import arrowupward from "../../../assets/images/arrow_upward.svg";
import cancelred from "../../../assets/images/cancel_red.svg";
import { toast } from 'react-toastify'
import { CREATE_MARKET_LIST, LOT_SCRIPT_POST_URL } from "../../../_helper/url_helper";
import api from "../../../_helper/api_helper";
import { useSelector } from 'react-redux'
import CustomFilter from '../TransactionAnalysis/CustomFilter'
import { useNavigate } from 'react-router-dom'

const GeneralComponent = () => {
    const [username, setUsername] = useState("");
    const [status, setStatus] = useState("all");
    const [allData, setAllData] = useState([]); // Store all data initially
    const [filteredData, setFilteredData] = useState([]); // Data after filtering     
    const [resultsPerPage, setResultsPerPage] = useState(10);
    const [pagination, setPagination] = useState({ current: 1, total: 1, totalRows: 0 });
    const [filterOptions, setFilterOptions] = useState({
        statusOptions: ["active","inactive"],
    });
    const [filters, setFilters] = useState({
    userName: "",
    status: "all",
    contactNo: "",
    });

    const navigate = useNavigate();
    const handleFilterSubmit = (newFilters) => {
        setFilters(newFilters);
    };
  
  return (
    <div>        
        <div className="container containerdiv ">
                <div className="filterdiv" style={{padding:"5px"}} >
                    <div className="filtertitle">
                        <h2 className='mb-0'>Market</h2>
                    </div>
                    <div className="filteranddate">
                        <CustomSelect/>
                        <CustomFilter  statusOptions={filterOptions.statusOptions} onSubmit={handleFilterSubmit}/>
                        <button className='btn btn-success' onClick={()=>navigate("/themes/create")}> <i className="fa fa-plus"></i> Create theme</button>                     
                    </div>
                </div>           
        </div>
        <div className="container containerdiv p-0"  style={{background:"transparent"}}>
            <div className="table-responsive mt-4">
                <table className="table table-bordered">
                <thead className="table-secondary">
                    <tr>
                        <th>#</th>
                        <th>NAME</th>
                        <th>Currency</th>
                        <th>Remarks</th>                            
                        <th>STATUS</th>
                        <th>Action</th>
                    </tr>
                </thead>
                
                <tbody>
                    <tr>
                        <td>id</td>
                        <td>Name</td>
                        <td>Currency</td>
                        <td>Remarks</td>
                        <td>
                            {/* <span className={`badge ${ txn.status === "active" ? "bg-succesnew" : txn.status === "Pending" ? "bg-pendingnew " : "bg-failednew" }`} > {txn.status} </span> */}
                            <span className="badge bg-succesnew" > Active </span>                        
                        </td>
                        <td className="actiondiv">                                
                            <button className="btn btn-sm" style={{padding:"0px",border:"0px",marginRight:'5px'}}><img src={actioncopy} width="25px"/></button>
                            <button className="btn btn-sm" style={{padding:"0px",border:"0px",marginRight:'5px'}} ><img src={actionedit} width="25px"/></button>
                            <button className="btn btn-sm" style={{padding:"0px",border:"0px",marginRight:'5px'}}><img src={actiondelete} width="25px"/></button>
                        </td>
                    </tr>
                    <tr>
                        <td>id-2</td>
                        <td>Name-2</td>
                        <td>Currency-2</td>
                        <td>Remarks-2</td>
                        <td>
                            {/* <span className={`badge ${ txn.status === "active" ? "bg-succesnew" : txn.status === "Pending" ? "bg-pendingnew " : "bg-failednew" }`} > {txn.status} </span> */}
                            <span className="badge bg-pendingnew" > Inactive </span>                        
                        </td>
                        <td className="actiondiv">                                
                            <button className="btn btn-sm" style={{padding:"0px",border:"0px",marginRight:'5px'}}><img src={actioncopy} width="25px"/></button>
                            <button className="btn btn-sm" style={{padding:"0px",border:"0px",marginRight:'5px'}} ><img src={actionedit} width="25px"/></button>
                            <button className="btn btn-sm" style={{padding:"0px",border:"0px",marginRight:'5px'}}><img src={actiondelete} width="25px"/></button>
                        </td>
                    </tr>
                </tbody>
              </table>
            </div>
            {/* Pagination */}
            <Paginationcomp 
              // currentPage={pagination.current}
              // totalPages={pagination.total}
              // onPageChange={setPage}
              // resultsPerPage={resultsPerPage}
              // onResultsPerPageChange={handleResultsPerPageChange}
            />
        </div>
    </div>
  )
}

export default GeneralComponent