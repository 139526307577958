import React from "react";
import { Suspense, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../Layout/Loader";
import LayoutRoutes from "../Route/LayoutRoutes";
import Login from "../Components/public/Login";

const Routers = () => {
  const loginState = useSelector((state) => state.login);
  useEffect(() => {
    let abortController = new AbortController();
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <BrowserRouter basename={"/"}>
    <>
        <Suspense fallback={<Loader />}>
          <Routes>
            {/* <Route exact path={"/"} element={<Navigate to={`/home`} />} /> */}
            <Route path={`/login`} element={(loginState.jwttoken)?<Navigate to="/"/>:<Login />} />
            <Route path={`/*`} element={(loginState.jwttoken)?<LayoutRoutes />:<Navigate to="/login"/>} />
          </Routes>
        </Suspense>
      </>
    </BrowserRouter>
  );
};

export default Routers;
