import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useSelector } from "react-redux";
import { MODIFY_ORDER_URL } from "../../../_helper/url_helper";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";

export default function ModifyOrderModal(props) {
  const loginState = useSelector((state) => state.login);
  const [qty, setQty] = useState("");
  const [price, setPrice] = useState("");

  useEffect(() => {
    if (props.order.id !== undefined) {
      setPrice(props.order.price);
      setQty(props.order.qty);
    }
  }, [props]);

  async function modifyOrderHandler() {
    try {
      if (qty === "") {
        toast.error("Qty required");
        return;
      }
      if (price === "") {
        toast.error("price required");
        return;
      }

      let data = {
        jwttoken: loginState.jwttoken,
        order_id: props.order.id,
        qty: Number.parseInt(qty),
        price: price,
      };
      let res = await api.post(MODIFY_ORDER_URL, data);
      if (res.data.status == 1) {
        toast.success("Order Modified");
        props.setModifyOrderModalStatus(false);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <>
      <Modal
        isOpen={props.modifyOrderModalStatus}
        toggle={() => {
          props.setModifyOrderModalStatus(false);
        }}
      >
        <ModalHeader>Modify Order</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12 mb-4">
              <label>Qty</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter qty"
                value={qty}
                onChange={(e) => {
                  if (
                    (Number.isInteger(Number(e.target.value)) &&
                      Number(e.target.value) > 0) ||
                    e.target.value === ""
                  )
                    setQty(e.target.value);
                }}
              />
            </div>
            <div className="col-12 mb-4">
              <label>Price</label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter price"
                value={price}
                onChange={(e) => {
                  if (Number(e.target.value) > 0 || e.target.value === "")
                    setPrice(e.target.value);
                }}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary mx-3" onClick={modifyOrderHandler}>
            Submit
          </button>
          <button
            className="btn btn-outline-secondary"
            onClick={() => {
              props.setModifyOrderModalStatus(false);
            }}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}
