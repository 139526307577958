import React, { Fragment, useRef } from "react";
import {
  Table,
} from "reactstrap";
import { useDraggable } from "react-use-draggable-scroll";

const ClientBill = (props) => {
  const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
  const { events } = useDraggable(ref);
  return (
    <Fragment>
        <div className="table-responsive" ref={ref} {...events}>
        {props.client.bill.map((data,idx) => {
        return (
          <>
            <div className="pt-4" key={idx}>
              <h4 className="bg-secondary text-center p-2 fw-semibold">
                {data.trading_symbol}
              </h4>
              <Table striped className="fw-semibold">
                <thead>
                  <tr className="bg-success">
                    <th style={{ color: "#ffffff" }}>ExitAt</th>
                    <th style={{ color: "#ffffff" }}>Type</th>
                    <th style={{ color: "#ffffff" }}>Qty</th>
                    <th style={{ color: "#ffffff" }}>BuyRate</th>
                    <th style={{ color: "#ffffff" }}>SellRate</th>
                    <th style={{ color: "#ffffff" }}>Profit/Loss</th>
                    <th style={{ color: "#ffffff" }}>Brokerage</th>
                    <th style={{ color: "#ffffff" }}>UsedMargin</th>
                  </tr>
                </thead>
                <tbody>
                    {data.trades.map((trade)=>{
                          return (<>
                            <tr key={trade.id}>
                                <td>{timestampFormatter((new Date(trade.added_on)).getTime())}</td>
                                <td className={`${(trade.type === "buy")?"text-success":"text-danger"}`}>{trade.type}</td>
                                <td>{trade.qty}</td>
                                <td>{trade.buy_price}</td>
                                <td>{trade.sell_price}</td>
                                <td className={`${(trade.profit_and_loss > 0)?"text-success":"text-danger"}`}>{trade.profit_and_loss}</td>
                                <td>{trade.brokerage}</td>
                                <td>{trade.used_margin}</td>
                            </tr>
                          </>);
                    })}
                </tbody>
              </Table>
            </div>
          </>
        );
      })}
              </div>
    </Fragment>
  );
};

export default ClientBill;

function timestampFormatter(timestamp) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    
    const d = new Date();
    d.setTime(Number(timestamp) + ((new Date()).getTimezoneOffset() * 60 * 1000));
    // console.log(timestamp);
    return (
      <>
        {d.getDate()} {months[d.getMonth()]} {d.getFullYear()}{" "}
        <small className="text-muted">{formatAMPM(d)}</small>
      </>
    );
  }
  
  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }