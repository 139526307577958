import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { fetchOwner } from '../../../store/login/action'
import { toast } from 'react-toastify'
import api from "../../../_helper/api_helper";
import { FETCH_DASHBOARD_DATA_URL_TOTAL_TURNOVER } from '../../../_helper/url_helper';

const Totalturnovercomp = () => {
    const dispatch        = useDispatch();
      const loginState      = useSelector((state) => state.login);
      const [data, setData] = useState([]);
      useEffect(() => {
        fetchDataHandler();
        dispatch(fetchOwner({ jwttoken: loginState.jwttoken }));
      }, []);
    
      useEffect(() => {
        if (loginState.owner.status === "inactive") {
          localStorage.removeItem("jwttoken");
          window.location.href = "/";
        }
      }, [loginState]);
    //   useEffect(() => {
    //     let interval = setInterval(fetchDataHandler, 5000);
    //     return () => { clearInterval(interval) }
    //   }, []);
    
      async function fetchDataHandler() {
        try {
          let data = {
            jwttoken: loginState.jwttoken,
          };
          api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
          let res = await api.post(FETCH_DASHBOARD_DATA_URL_TOTAL_TURNOVER, data);
          if (res.data.status === "SUCCESS") {
            setData(res.data.payload);
          } else {
            toast.error(res.data.message);
          }
        } catch (error) {
          toast.error(error.message);
        }
      }
  return (
    
    <>
        <div className="profitlosbottomdiv">
            <div className="profitlosbottomdivinner">
                <h4>Buy Turnover</h4>
                <hr />
                <div>
                    <p>US</p>
                    <h5>&#x24; {data?.buy_turnover_us?data?.buy_turnover_us:0.00}</h5>
                </div>
                <div>
                    <p>Indian</p>
                    <h5>&#x20B9; {data?.buy_turnover_indian?data?.buy_turnover_indian:0.00}</h5>
                </div>
                <div>
                    <p>Forex</p>
                    <h5>&#x24; {data?.buy_turnover_forex?data?.buy_turnover_forex:0.00}</h5>
                </div>
                <div>
                    <p>Crypto</p>
                    <h5>&#x24; {data?.buy_turnover_us?data?.buy_turnover_crypto:0.00}</h5>
                </div>
            </div>
            <div className="profitlosbottomdivinner">
                <h4>Sell Turnover</h4>
                <hr />
                <div>
                    <p>US</p>
                    <h5>&#x24; {data?.sell_turnover_us?data?.sell_turnover_us:0.00}</h5>
                </div>
                <div>
                    <p>Indian</p>
                    <h5>&#x20B9; {data?.sell_turnover_indian?data?.sell_turnover_indian:0.00}</h5>
                </div>
                <div>
                    <p>Forex</p>
                    <h5>&#x24; {data?.sell_turnover_forex?data?.sell_turnover_forex:0.00}</h5>
                </div>
                <div>
                    <p>Crypto</p>
                    <h5>&#x24; {data?.sell_turnover_crypto?data?.sell_turnover_crypto:0.00}</h5>
                </div>
            </div>
            <div className="profitlosbottomdivinner">
                <h4>Total Turnover</h4>
                <hr />
                <div>
                <p>US</p>
                <h5>&#x24; {data?.total_turnover_us?data?.total_turnover_us:0.00}</h5>
                </div>
                <div>
                <p>Indian</p>
                <h5>&#x20B9; {data?.total_turnover_indian?data?.total_turnover_indian:0.00}</h5>
                </div>
                <div>
                <p>Forex</p>
                <h5>&#x24; {data?.total_turnover_forex?data?.total_turnover_forex:0.00}</h5>
                </div>
                <div>
                <p>Crypto</p>
                <h5>&#x24; {data?.total_turnover_crypto?data?.total_turnover_crypto:0.00}</h5>
                </div>
            </div>
            <div className="profitlosbottomdivinner">
                <h4>Active Users</h4>
                <hr />
                <div>
                <p>US</p>
                <h5>&#x24; {data?.us_users?data?.us_users:0.00}</h5>
                </div>
                <div>
                <p>Indian</p>
                <h5>&#x20B9; {data?.indian_users?data?.indian_users:0.00}</h5>
                </div>
                <div>
                <p>Forex</p>
                <h5>&#x24; {data?.fx_users?data?.fx_users:0.00}</h5>
                </div>
                <div>
                <p>Crypto</p>
                <h5>&#x24; {data?.crypto_users?data?.crypto_users:0.00}</h5>
                </div>
            </div>
            <div className="profitlosbottomdivinner">
                <h4>Profit / Loss</h4>
                <hr />
                <div>
                <p>US</p>
                <h5>&#x24; {data?.profit_and_loss_us?data?.profit_and_loss_us:0.00}</h5>
                </div>
                <div>
                <p>Indian</p>
                <h5>&#x20B9; {data?.profit_and_loss_indian?data?.profit_and_loss_indian:0.00}</h5>
                </div>
                <div>
                <p>Forex</p>
                <h5>&#x24; {data?.profit_and_loss_forex?data?.profit_and_loss_forex:0.00}</h5>
                </div>
                <div>
                <p>Crypto</p>
                <h5>&#x24; {data?.profit_and_loss_crypto?data?.profit_and_loss_crypto:0.00}</h5>
                </div>
            </div>
            <div className="profitlosbottomdivinner">
                <h4>Brokerage</h4>
                <hr />
                <div>
                <p>US</p>
                <h5>&#x24; {data?.brokerage_us?data?.brokerage_us:0.00}</h5>
                </div>
                <div>
                <p>Indian</p>
                <h5>&#x20B9; {data?.brokerage_indian?data?.brokerage_indian:0.00}</h5>
                </div>
                <div>
                <p>Forex</p>
                <h5>&#x24; {data?.brokerage_forex?data?.brokerage_forex:0.00}</h5>
                </div>
                <div>
                <p>Crypto</p>
                <h5>&#x24; {data?.brokerage_crypto?data?.brokerage_crypto:0.00}</h5>
                </div>
            </div>
            <div className="profitlosbottomdivinner">
                <h4>Active Buy</h4>
                <hr />
                <div>
                <p>US</p>
                <h5>&#x24; {data?.active_buy_us?data?.active_buy_us:0.00}</h5>
                </div>
                <div>
                <p>Indian</p>
                <h5>&#x20B9; {data?.active_buy_indian?data?.active_buy_indian:0.00}</h5>
                </div>
                <div>
                <p>Forex</p>
                <h5>&#x24; {data?.active_buy_forex?data?.active_buy_forex:0.00}</h5>
                </div>
                <div>
                <p>Crypto</p>
                <h5>&#x24; {data?.active_buy_crypto?data?.active_buy_crypto:0.00}</h5>
                </div>
            </div>
            <div className="profitlosbottomdivinner">
                <h4>Active Sell</h4>
                <hr />
                <div>
                <p>US</p>
                <h5>&#x24; {data?.active_sell_us?data?.active_sell_us:0.00}</h5>
                </div>
                <div>
                <p>Indian</p>
                <h5>&#x20B9; {data?.active_sell_indian?data?.active_sell_indian:0.00}</h5>
                </div>
                <div>
                <p>Forex</p>
                <h5>&#x24; 12{data?.active_sell_fx?data?.active_sell_fx:0.00}00</h5>
                </div>
                <div>
                <p>Crypto</p>
                <h5>&#x24; {data?.active_sell_crypto?data?.active_sell_crypto:0.00}</h5>
                </div>
            </div>
        </div> 
    </>
  )
}

export default Totalturnovercomp