import React, { Fragment, useState, useEffect } from "react";
import {
  CardBody,
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  Collapse,
} from "reactstrap";

import { H2, H4, H5 } from "../../../AbstractElements";
import { useSelector } from "react-redux";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import {
  CHANGE_SCRIPT_STATUS_URL,
  FETCH_SCRIPTS_URL,
} from "../../../_helper/url_helper";

const GeneralComponent = () => {
  const loginState = useSelector((state) => state.login);
  const [scripts, setScripts] = useState({ MCX: [], NFO: [], CDS: [] });
  const [activeExchange, setActiveExchange] = useState("MCX");
  const [isOpen, setIsOpen] = useState(false);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  const [symbol, setSymbol] = useState("");
  const [segment, setSegment] = useState(0);
  const [expiry, setExpiry] = useState("");
  const [status, setStatus] = useState("both");

  useEffect(() => {
    fetchScriptHandler();
  }, []);

  useEffect(() => {
    reset();
  }, [activeExchange]);

  useEffect(() => {
    fetchScriptHandler();
  }, [symbol, segment, expiry, status]);

  async function fetchScriptHandler() {
    try {
      let data = {
        // jwttoken: loginState.jwttoken,
      };
      if (symbol !== "") {
        data.symbol = symbol;
      }
      if (Number(segment) !== 0) {
        data.segment = segment;
      }
      if (expiry !== "") {
        data.expiry = expiry;
      }
      if (status !== "both") {
        data.status = status;
      }
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.post(FETCH_SCRIPTS_URL, data);
      if (res.data.status === "SUCCESS") {
        setScripts(res.data.payload);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function activateScriptStatusHandler(scriptID, status) {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let data = {
        // jwttoken: loginState.jwttoken,
        script_id: scriptID,
        status: status
      };
      let res = await api.post(CHANGE_SCRIPT_STATUS_URL, data);
      if (res.data.status === "SUCCESS") {
        fetchScriptHandler();
        toast.success("Script Activated", { autoClose: 2000 });
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  // async function deActivateScriptHandler(scriptID) {
  //   try {
  //     let data = {
  //       jwttoken: loginState.jwttoken,
  //       id: scriptID
  //     };
  //     let res = await api.post(CHANGE_SCRIPT_STATUS_URL, data);
  //     if (res.data.status == 1) {
  //       fetchScriptHandler();
  //       toast.success("Script DeActivated", { autoClose: 2000 });
  //     } else {
  //       toast.error(res.data.message);
  //     }
  //   } catch (error) {
  //     toast.error(error.message);
  //   }
  // }

  function reset() {
    setSymbol("");
    setSegment(0);
    setExpiry("");
    setStatus("both");
  }

  return (
    <>
      <Card className="mb-4">
        <CardHeader className="bg-secondary py-3" onClick={() => toggle(1)}>
          <h5 className="d-flex justify-content-between align-items-center">
            <span>Filters</span>
            {isOpen === 1 ? (
              <i className="icofont icofont-rounded-up"></i>
            ) : (
              <i className="icofont icofont-rounded-down"></i>
            )}
          </h5>
        </CardHeader>
        <Collapse isOpen={isOpen === 1}>
          <CardBody>
            <Row className="row">
              <Col className="mb-3" md={4}>
                <label>Symbol</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Symbol"
                  value={symbol}
                  onChange={(e) => {
                    if (
                      /^[a-zA-Z]*$/g.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      setSymbol(e.target.value);
                    }
                  }}
                />
              </Col>
              <Col className="mb-3" md={4}>
                <label>Segment</label>
                <select
                  className="form-control"
                  value={segment}
                  onChange={(e) => {
                    setSegment(e.target.value);
                  }}
                >
                  <option value={0}>Select Segment</option>
                  {activeExchange === "MCX" ? (
                    <>
                      <option value="FUTCOM">FUTCOM</option>
                      <option value="OPTFUT">OPTFUT</option>
                    </>
                  ) : (
                    ""
                  )}
                  {activeExchange === "NFO" ? (
                    <>
                      <option value="FUTSTK">FUTSTK</option>
                      <option value="FUTIDX">FUTIDX</option>
                      <option value="OPTSTK">OPTSTK</option>
                      <option value="OPTIDX">OPTIDX</option>
                    </>
                  ) : (
                    ""
                  )}
                  {activeExchange === "CDS" ? (
                    <>
                      <option value="FUTCUR">FUTCUR</option>
                    </>
                  ) : (
                    ""
                  )}
                </select>
              </Col>
              <Col className="mb-3" md={4}>
                <label>Expiry</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Symbol"
                  value={expiry}
                  onChange={(e) => {
                    setExpiry(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <label>Status</label>
                <select
                  className="form-control"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <option value="both">Both</option>
                  <option value="active">Active</option>
                  <option value="inactive">InActive</option>
                </select>
              </Col>
              <Col className="align-self-end" md={4}>
                {symbol !== "" ||
                  Number(segment) !== 0 ||
                  expiry !== "" ||
                  status !== "both" ? (
                  <button className="btn btn-danger mt-" onClick={reset}>
                    Reset
                  </button>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </CardBody>
        </Collapse>
      </Card>

      <Card>
        <CardBody>
          <Nav tabs className="row justify-content-between mb-3">
            <NavItem className="col px-0">
              <NavLink
                className={`text-center ${activeExchange === "MCX" ? "active" : ""
                  }`}
                onClick={() => {
                  setActiveExchange("MCX");
                }}
              >
                MCX
              </NavLink>
            </NavItem>
            <NavItem className="col px-0">
              <NavLink
                className={`text-center ${activeExchange === "NFO" ? "active" : ""
                  }`}
                onClick={() => {
                  setActiveExchange("NFO");
                }}
              >
                NFO
              </NavLink>
            </NavItem>
            <NavItem className="col px-0">
              <NavLink
                className={`text-center ${activeExchange === "CDS" ? "active" : ""
                  }`}
                onClick={() => {
                  setActiveExchange("CDS");
                }}
              >
                CDS
              </NavLink>
            </NavItem>

            <NavItem className="col px-0">
              <NavLink
                className={`text-center ${activeExchange === "FX" ? "active" : ""
                  }`}
                onClick={() => {
                  setActiveExchange("FX");
                }}
              >
                FX
              </NavLink>
            </NavItem>

            <NavItem className="col px-0">
              <NavLink
                className={`text-center ${activeExchange === "CRYPTO" ? "active" : ""
                  }`}
                onClick={() => {
                  setActiveExchange("CRYPTO");
                }}
              >
                CRYPTO
              </NavLink>
            </NavItem>

            <NavItem className="col px-0">
              <NavLink
                className={`text-center ${activeExchange === "US" ? "active" : ""
                  }`}
                onClick={() => {
                  setActiveExchange("US");
                }}
              >
                US
              </NavLink>
            </NavItem>
          </Nav>
          <Row>
            <Table className="table-border-horizontal align-middle" striped>
              <thead>
                <tr className="bg-primary">
                  <th style={{ color: "#ffffff" }}>#</th>
                  <th style={{ color: "#ffffff" }}>Symbol</th>
                  <th style={{ color: "#ffffff" }}>Segment</th>
                  <th style={{ color: "#ffffff" }}>Expiry</th>
                  <th style={{ color: "#ffffff" }}>AddedOn</th>
                  <th style={{ color: "#ffffff" }}>Status</th>
                </tr>
              </thead>
              <tbody className="fw-semibold">
                {scripts[activeExchange].length ? (
                  scripts[activeExchange].map((script, idx) => {
                    return (
                      <tr key={script.id}>
                        <td>{idx + 1}</td>
                        <td>{script.symbol}</td>
                        <td>{script.segment}</td>
                        <td>{script.expiry}</td>
                        <td>{script.added_on}</td>
                        <td>
                          {script.status === "active" ? (
                            <button className="btn btn-primary btn-sm" onClick={() => { activateScriptStatusHandler(script.id, "inactive") }}>
                              Active
                            </button>
                          ) : (
                            <button className="btn btn-secondary btn-sm" onClick={() => { activateScriptStatusHandler(script.id, "active") }}>
                              DeActive
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No Scripts found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default GeneralComponent;
