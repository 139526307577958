import React, { useEffect, useState } from 'react'
import dollericon from '../../../assets/images/dollericon.svg'
import inricon from '../../../assets/images/inricon.svg'
import cryptoforexicon from '../../../assets/images/crypto.svg'
import forexicon from '../../../assets/images/forex.svg'
import { Col, Row } from 'reactstrap'
import Customselect from './Customselect'
import { parseDateRange } from '../../../_helper/dateFormate'
import { useDispatch, useSelector } from 'react-redux'
import { fetchOwner } from '../../../store/login/action'
import { toast } from 'react-toastify'
import { FETCH_DASHBOARD_DATA_URL_TOTAL_PROFIT, FETCH_DASHBOARD_DATA_URL_TOTAL_TRADE, FETCH_DASHBOARD_DATA_URL_TOTAL_VIEW } from '../../../_helper/url_helper'
import api from "../../../_helper/api_helper";
import BeatLoader from 'react-spinners/BeatLoader'

const Profitlosscomp = () => {

    const [selectedOption, setSelectedOption] = useState(null);
    const dispatch        = useDispatch();
    const loginState      = useSelector((state) => state.login);
    const [data, setData] = useState([]);
    const [seconddata, setSecondData] = useState([]);
    const [loading,setLoading] = useState(false);
    
    useEffect(() => {
        // fetchDataHandler();
        fetchDataHandlerprofitloss(); // Call the fetch function
        fetchDataHandlertrad()
        dispatch(fetchOwner({ jwttoken: loginState.jwttoken }));
    }, []);

    

    useEffect(() => {
        if (loginState.owner.status === "inactive") {
        localStorage.removeItem("jwttoken");
        window.location.href = "/";
        }
    }, [loginState]);  
   
    const handleOptionSelect = (option) => {
        setSelectedOption(option); // Update the state
        fetchDataHandlerprofitloss(option); // Call the fetch function
        // fetchDataHandlertrad(option)
    };

    const handleOptionSelecttrade = (option) => {
        setSelectedOption(option); // Update the state       
        fetchDataHandlertrad(option)
    }; 
    
    async function fetchDataHandlerprofitloss(option) {
        let jwttoken = loginState.jwttoken;
        const formattedDates = parseDateRange(option?.value);
        const startDate = formattedDates?.startDate;
        const endDate = formattedDates?.endDate;
        setLoading(true)
    
        try {
            api.defaults.headers.common["Authorization"] = `Bearer ${jwttoken}`;
            // let data = { jwttoken: jwttoken };
            let dataObj = {
                jwttoken: jwttoken,
                filter: option?.value, // Use the passed option directly
            };
    
            if (option?.label === "custom" || option?.label === "Custom") {
                dataObj.filter = 'custom'; // For custom, include dates
                dataObj.start_date = startDate;
                dataObj.end_date = endDate;
            }
            api.defaults.headers.common["Authorization"] = `Bearer ${jwttoken}`;
            const res = await api.post(FETCH_DASHBOARD_DATA_URL_TOTAL_PROFIT, dataObj);    
            if (res.data.status === "SUCCESS") {
                setData(res.data.payload);
            } else {
                toast.error(res.data.message);
            }
            //let data2 = { additionalParam: "value" };
            // const [res1, res2] = await Promise.all([
            //     api.post(FETCH_DASHBOARD_DATA_URL_TOTAL_PROFIT, dataObj),
            //     api.post(FETCH_DASHBOARD_DATA_URL_TOTAL_TRADE, dataObj),
            // ]);
            // if (res1.data.status === "SUCCESS") {
            //     setData(res1.data.payload);
            // } else {
            //     toast.error(`API 1 Error: ${res1.data.message}`);
            // }
            // if (res2.data.status === "SUCCESS") {
            //     setSecondData(res2.data.payload);
            // } else {
            //     toast.error(`API 2 Error: ${res2.data.message}`);
            // }
            setLoading(false)
        } catch (error) {
            toast.error(`Error: ${error.message}`);
            setLoading(false)
        }
    }

    async function fetchDataHandlertrad(option) {
        const jwttoken = loginState.jwttoken;
    
        // Compute the startDate and endDate based on the selected option
        const formattedDates = parseDateRange(option?.value);
        const startDate = formattedDates?.startDate;
        const endDate = formattedDates?.endDate;
        setLoading(true)
    
        try {
            let dataObj = {
                jwttoken: jwttoken,
                filter: option?.value, // Use the passed option directly
            };
    
            if (option?.label === "custom" || option?.label === "Custom") {
                dataObj.filter = 'custom'; // For custom, include dates
                dataObj.start_date = startDate;
                dataObj.end_date = endDate;
            }
    
              
            api.defaults.headers.common["Authorization"] = `Bearer ${jwttoken}`;
            const res = await api.post(FETCH_DASHBOARD_DATA_URL_TOTAL_TRADE, dataObj);    
            if (res.data.status === "SUCCESS") {
                setSecondData(res.data.payload);
            } else {
                toast.error(res.data.message);
            }
            setLoading(false)
        } catch (error) {
            toast.error(error.message);
            setLoading(false)
        }
    }

    // const handleOptionSelect = (option) => {
    //     setSelectedOption(option);
    // };    
    
    // const formattedDates = parseDateRange(selectedOption?.value);
    // const startDate = formattedDates?.startDate;
    // const endDate = formattedDates?.endDate;

  return (
    <>
        <div className="rowhalfcoldiv">
            <div className="halfcoldiv">
                <div className='p-2'>
                <div className="d-flex justify-content-between align-items-center" >
                    <div>
                        <h4>Total Profit</h4>
                        <p>Your Profit</p>
                    </div>
                    <div>
                        <Customselect onOptionSelect={handleOptionSelect}/>
                    </div>
                </div>                   
                <Row className="mt-3">
                    <Col md="3">
                        <div className="iconaligndivmain">
                            <span className="iconaligndiv">
                                <div className="icondiv" style={{background:"#883dcf2e"}}>
                                    <img src={dollericon} alt=""/>
                                </div>                                  
                                <p>US <small className={data?.profit_and_loss_us >=0 ?"profitcolor" : "losscolor"}> 30%<i className={`fa ${ data?.profit_and_loss_us >= 0 ? "fa-caret-up" : "fa-caret-down" }`}></i></small></p>
                            </span>
                            {loading? <><BeatLoader color="rgba(37, 146, 213, 1)" /></>:<h4>{Number(data?.profit_and_loss_us).toFixed(2)}</h4>}
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="iconaligndivmain">
                            <span className="iconaligndiv">
                                <div className="icondiv" style={{background:"#f867262e"}}>
                                    <img src={inricon} alt=""/>
                                </div>                                  
                                <p>Indian <small className={data?.profit_and_loss_indian >=0 ?"profitcolor" : "losscolor"}> 10% <i className={`fa ${ data?.profit_and_loss_indian >= 0 ? "fa-caret-up" : "fa-caret-down" }`}></i></small></p>
                            </span>
                            {/* <h4>&#8377;{Number(data?.profit_and_loss_indian).toFixed(2)}</h4> */}
                            {loading? <><BeatLoader color="rgba(37, 146, 213, 1)" /></>:<h4>{Number(data?.profit_and_loss_indian).toFixed(2)}</h4>}
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="iconaligndivmain">
                            <span className="iconaligndiv">
                                <div className="icondiv" style={{background:"#48bcfe2e"}}>
                                    <img src={forexicon} alt="" />
                                </div>
                                <p>Forex  <small className={data?.profit_and_loss_forex >=0 ?"profitcolor" : "losscolor"}> 14 %<i className={`fa ${ data?.profit_and_loss_forex >= 0 ? "fa-caret-up" : "fa-caret-down" }`}></i></small></p>
                            </span>
                            {/* <h4>${Number(data?.profit_and_loss_fx?data?.profit_and_loss_fx :0).toFixed(2)} </h4> */}
                            {loading? <><BeatLoader color="rgba(37, 146, 213, 1)" /></>:<h4>{Number(data?.profit_and_loss_forex?data?.profit_and_loss_forex :0).toFixed(2)} </h4>}
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="iconaligndivmain">
                            <span className="iconaligndiv">
                                <div className="icondiv" style={{background:"#32cdb42e"}}>
                                    <img src={cryptoforexicon} alt="" />
                                </div>
                                <p>Crypto <small className={data?.profit_and_loss_crypto >=0 ?"profitcolor" : "losscolor"}> 14 %<i className={`fa ${ data?.profit_and_loss_crypto >= 0 ? "fa-caret-up" : "fa-caret-down" }`}></i></small></p>
                            </span>
                            {/* <h4>${Number(data?.profit_and_loss_crypto).toFixed(2)}</h4> */}
                            {loading? <><BeatLoader color="rgba(37, 146, 213, 1)" /></>:<h4>{Number(data?.profit_and_loss_crypto).toFixed(2)}</h4>}
                        </div>
                    </Col>
                </Row>    
                
                </div>                      
            </div>
            <div className="halfcoldiv">
                <div className='p-2'>
                <div div className="d-flex justify-content-between align-items-center" >
                    <div>
                        <h4>Total Trade</h4>
                        <p>Your Trade</p>  
                    </div>
                    <div>
                        <Customselect onOptionSelect={handleOptionSelecttrade}/> 
                    </div>
                </div>
                
                <Row className="mt-3">
                    <Col md="3">
                        <div className="iconaligndivmain">
                            <span className="iconaligndiv">
                                <div className="icondiv" style={{background:"#883dcf2e"}}>
                                    <img src={dollericon} alt=""/>
                                </div>                                  
                                <p>US <small className={seconddata?.trades_us >=0 ?"profitcolor" : "losscolor"}> 10% <i className={`fa ${ seconddata?.trades_us >= 0 ? "fa-caret-up" : "fa-caret-down" }`}></i></small></p>
                            </span>
                            {/* <h4 className="ml-2">{seconddata?.trades_us} </h4> */}
                            {loading? <><BeatLoader color="rgba(37, 146, 213, 1)" /></>:<h4 className="ml-2">{seconddata?.trades_us} </h4>}
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="iconaligndivmain">
                            <span className="iconaligndiv">
                                <div className="icondiv" style={{background:"#f867262e"}}>
                                    <img src={inricon} alt=""/>
                                </div>                                  
                                <p>Indian <small className={seconddata?.trades_indian >=0 ?"profitcolor" : "losscolor"}>20%<i className={`fa ${ seconddata?.trades_indian >= 0 ? "fa-caret-up" : "fa-caret-down" }`}></i></small></p>
                            </span>
                            {/* <h4>{seconddata?.trades_indian} </h4> */}
                            {loading? <><BeatLoader color="rgba(37, 146, 213, 1)" /></>:<h4>{seconddata?.trades_indian} </h4>}
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="iconaligndivmain">
                            <span className="iconaligndiv">
                                <div className="icondiv" style={{background:"#48bcfe2e"}}>
                                    <img src={forexicon} alt="" />
                                </div>
                                <p>Forex  <small className={seconddata?.trades_forex >=0 ?"profitcolor" : "losscolor"}> 14 %<i className={`fa ${ seconddata?.trades_forex >= 0 ? "fa-caret-up" : "fa-caret-down" }`}></i></small></p>
                            </span>
                            {/* <h4>{seconddata?.trades_forex}</h4> */}
                            {loading? <><BeatLoader color="rgba(37, 146, 213, 1)" /></>:<h4>{seconddata?.trades_forex}</h4>}
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="iconaligndivmain">
                            <span className="iconaligndiv">
                                <div className="icondiv" style={{background:"#32cdb42e"}}>
                                    <img src={cryptoforexicon} alt="" />
                                </div>
                                <p>Crypto <small className={seconddata?.trades_crypto >=0 ?"profitcolor" : "losscolor"}>10% <i className={`fa ${ seconddata?.trades_crypto >= 0 ? "fa-caret-up" : "fa-caret-down" }`}></i></small></p>
                            </span>
                            {/* <h4>{seconddata?.trades_crypto}</h4> */}
                            {loading? <><BeatLoader color="rgba(37, 146, 213, 1)" /></>:<h4>{seconddata?.trades_crypto}</h4>}
                        </div>
                    </Col>
                </Row>
                </div>
            </div>
        </div> 
    </>
  )
}

export default Profitlosscomp