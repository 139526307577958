import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";

const Detail = ({ client }) => {
  return (
    <div className="table-responsive">
      <Table striped bordered>
        <tbody>
          <tr>
            <th>ID</th>
            <td>{client["account"]["client_id"]}</td>
          </tr>

          <tr>
            <th>First Name</th>
            <td>{client["personal"]["firstname"]}</td>
          </tr>

          <tr>
            <th>Last Name</th>
            <td>{client["personal"]["lastname"]}</td>
          </tr>

          <tr>
            <th>User Name</th>
            <td>{client["personal"]["username"]}</td>
          </tr>

          <tr>
            <th>Phone</th>
            <td>{client["personal"]["contact"]}</td>
          </tr>

          <tr>
            <th>Email</th>
            <td>{client["personal"]["email"]}</td>
          </tr>

          <tr>
            <th>Status</th>
            <td>{client["personal"]["status"]}</td>
          </tr>

          <tr>
            <th>Balance</th>
            <td>{client["account"]["balance"]}</td>
          </tr>

          <tr>
            <th>Used Balance</th>
            <td>{client["account"]["used_balance"]}</td>
          </tr>

          <tr>
            <th>Is Demo</th>
            <td>{client["account"]["is_demo"]}</td>
          </tr>

          <tr>
            <th>Brokerage template Id</th>
            <td>{client["account"]["brokerage_template_id"]}</td>
          </tr>

          <tr>
            <th>Margin template id</th>
            <td>{client["account"]["margin_template_id"]}</td>
          </tr>

          <tr>
            <th>Limit above high low</th>
            <td>{client["account"]["limit_above_high_low"]}</td>
          </tr>

          <tr>
            <th>Limit between high loq</th>
            <td>{client["account"]["limit_between_high_low"]}</td>
          </tr>

          <tr>
            <th>fresh Sl Entry above high low</th>
            <td>{client["account"]["sl_above_high_low"]}</td>
          </tr>

          <tr>
            <th>fresh Sl Entry above high low</th>
            <td>{client["account"]["sl_between_high_low"]}</td>
          </tr>

          <tr>
            <th>Only Intraday</th>
            <td>{client["account"]["only_intraday"]}</td>
          </tr>

          <tr>
            <th>Intraday Brokerage One Side</th>
            <td>{client["account"]["intraday_brokerage_oneside"]}</td>
          </tr>

          
          <tr>
            <th>Trade Equity in Unit</th>
            <td>{client["account"]["trade_equity_in_unit"]}</td>
          </tr>

          <tr>
            <th>Trade Mcx in unit</th>
            <td>{client["account"]["trade_mcx_in_unit"]}</td>
          </tr>

          <tr>
            <th>Minimum Lot Mcx single trade</th>
            <td>{client["account"]["min_lot_mcx_single_trade"]}</td>
          </tr>
          
          <tr>
            <th>Minimum Lot Cds single trade</th>
            <td>{client["account"]["min_lot_cds_single_trade"]}</td>
          </tr>

          <tr>
            <th>Minimum Lot Equity single trade</th>
            <td>{client["account"]["min_lot_equity_single_trade"]}</td>
          </tr>

          <tr>
            <th>Maximum Lot Mcx single trade</th>
            <td>{client["account"]["max_lot_mcx_single_trade"]}</td>
          </tr>
          
          <tr>
            <th>Maximum Lot Cds single trade</th>
            <td>{client["account"]["max_lot_cds_single_trade"]}</td>
          </tr>

          <tr>
            <th>Maximum Lot Equity single trade</th>
            <td>{client["account"]["max_lot_equity_single_trade"]}</td>
          </tr>

          <tr>
            <th>Maximum Lot Mcx Per Script</th>
            <td>{client["account"]["max_lot_mcx_per_script"]}</td>
          </tr>
          
          <tr>
            <th>Maximum Lot Cds Per Script</th>
            <td>{client["account"]["max_lot_cds_per_script"]}</td>
          </tr>

          <tr>
            <th>Maximum Lot Equity Per Script</th>
            <td>{client["account"]["max_lot_equity_per_script"]}</td>
          </tr>

          <tr>
            <th>Maximum Lot Can Stand</th>
            <td>{client["account"]["max_lot_can_stand"]}</td>
          </tr>

          <tr>
            <th>Mcx trading active</th>
            <td>{client["account"]["is_mcx_trading_active"]}</td>
          </tr>

          <tr>
            <th>Equity trading active</th>
            <td>{client["account"]["is_equity_trading_active"]}</td>
          </tr>

          <tr>
            <th>Cds trading active</th>
            <td>{client["account"]["is_cds_trading_active"]}</td>
          </tr>

          <tr>
            <th>Equity Sl Percentage</th>
            <td>{client["account"]["equity_sl_percentage"]}</td>
          </tr>

          <tr>
            <th>Cds Sl Percentage</th>
            <td>{client["account"]["cds_sl_percentage"]}</td>
          </tr>

          <tr>
            <th>mcx Sl Type</th>
            <td>{client["account"]["mcx_sl_type"]}</td>
          </tr>

          <tr>
            <th>Mcx Sl Percentage</th>
            <td>{client["account"]["mcx_sl_percentage"]}</td>
          </tr>

          <tr>
            <th>Trade Gap Minutes</th>
            <td>{client["account"]["trade_gap_minutes"]}</td>
          </tr>

          <tr>
            <th>Trade Gap Minutes Apply On Market Order</th>
            <td>{client["account"]["trade_gap_minutes_apply_on_market_order"]}</td>
          </tr>

          <tr>
            <th>Is Option Buy Active</th>
            <td>{client["account"]["is_option_buy_active"]}</td>
          </tr>

          <tr>
            <th>Is Option Sell Active</th>
            <td>{client["account"]["is_option_sell_active"]}</td>
          </tr>

          <tr>
            <th>Options Only On Market Order</th>
            <td>{client["account"]["options_only_on_market_order"]}</td>
          </tr>

          <tr>
            <th>Bill Effect On Ledger</th>
            <td>{client["account"]["bill_effect_on_ledger"]}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default Detail;
