import { Home, Airplay, Box, FolderPlus, Command, Cloud, BarChart, Users, ShoppingBag, List, Mail, MessageCircle, GitPullRequest, Monitor, Heart, Clock, Zap, CheckSquare, Calendar, Image, HelpCircle, Radio, Map, Edit, Sunrise, Package, Sliders, Layout, Server, Database, Search, File, Layers, UserCheck, Activity, Columns, BookOpen, CreditCard } from 'react-feather';

export let MENUITEMS = [
    {
        menutitle: 'Main',
        Items: [,
            { path: `/dashboard`, icon: Home, title: 'Dashboard', type: 'link' },
            { path: `/transactionAnalysis`, icon: Activity, title: 'Fund Analysis', type: 'link' },            
            { path: `/users`, icon: Users, title: 'Brokers', type: 'link' },
            { path: `/clients`, icon: Users, title: 'Clients', type: 'link' },
            { path: `/clientsWithM2M`, icon: Users, title: 'Clients M2M', type: 'link' },

            // { path: `/actionLedger`, icon: GitPullRequest, title: 'Action Ledger', type: 'link' },
            // {
            //     title: 'Project', icon: Box, type: 'sub', active: false, children: [
            //         { path: `${process.env.PUBLIC_URL}/app/project/project-list`, type: 'link', title: 'Project List' },
            //         { path: `${process.env.PUBLIC_URL}/app/project/new-project`, type: 'link', title: 'Create New' }
            //     ]
            // },
        ],
    },

    // {
    //     menutitle:"Super Admin",
    //     Items:[
    //         { path: `/market`, icon: GitPullRequest, title: 'Market', type: 'link' },
    //         { path: `/createtheme`, icon: GitPullRequest, title: 'Create Theme', type: 'link' },
    //     ]
    // },

    {
        menutitle: 'Overview',
        Items: [
            // { path: `/client/M2M`, icon: GitPullRequest, title: 'Client M2M', type: 'link' },
            { path: `/activePositions`, icon: Monitor, title: 'Active Positions', type: 'link' },
            { path: `/closedPositions`, icon: Columns, title: 'Closed Positions', type: 'link' },
            { path: `/trades`, icon: GitPullRequest, title: 'Trades', type: 'link' },
            { path: `/positions`, icon: GitPullRequest, title: 'Positions', type: 'link' },
            { path: `/Orders`, icon: GitPullRequest, title: 'Orders', type: 'link' },
            { path: `/liveAccounts`, icon: GitPullRequest, title: 'Live Accounts', type: 'link' },
            { path: `/accounts`, icon: GitPullRequest, title: 'Accounts', type: 'link' },            
            { path: `/marketstocks`, icon: GitPullRequest, title: 'Market Stocks', type: 'link' },
            { path: `/SingleClientM2M`, icon: GitPullRequest, title: 'Single Client M2M', type: 'link' },
            { path: `/BillMaster`, icon: GitPullRequest, title: 'Bill Master', type: 'link' },
            { path: `/LedgerMaster`, icon: GitPullRequest, title: 'Ledger Master', type: 'link' },
            { path: `/RejectionLogs`, icon: GitPullRequest, title: 'Rejection Logs', type: 'link' }
        ]
    },  

    {
        menutitle: 'Payments',
        Items: [
            { path: `/brokerageTemplate`, icon: GitPullRequest, title: 'Brokerage Templates', type: 'link' },
            { path: `/marginTemplates`, icon: GitPullRequest, title: 'Margin Templates', type: 'link' },
            { path: `/PayoutRequests`, icon: CreditCard, title: 'Payout Requests', type: 'link' },
            { path: `/fund`, icon: GitPullRequest, title: 'Funds', type: 'link' },
            { path: `/currencyRateManager`, icon: GitPullRequest, title: 'Currency Manager', type: 'link' }
        ]
    },

    {
        menutitle: 'Setting',
        Items: [
            { path: `/lotscriptmanagement`, icon: GitPullRequest, title: 'Script Lot Setting', type: 'link' },
            { path: `/script`, icon: GitPullRequest, title: 'Script Manager', type: 'link' },
            { path: `/BlockScriptManager`, icon: GitPullRequest, title: 'Block Script Manager', type: 'link' },
            { path: `/dividend`, icon: GitPullRequest, title: 'Dividend', type: 'link' },
            { path: `/timings`, icon: GitPullRequest, title: 'Timings', type: 'link' },
            // { path: `/globalMargin`, icon: GitPullRequest, title: 'Global Margin', type: 'link' },
            { path: `/ReferralSetting`, icon: GitPullRequest, title: 'Referral Setting', type: 'link' },
            { path: `/settings`, icon: GitPullRequest, title: 'Settings', type: 'link' }
        ]
    }
];