import React, { useEffect, useState, useCallback } from 'react';
import Customtradfilter from './Customtradfilter';
import Paginationcomp from '../TransactionAnalysis/Paginationcomp';
import { useSelector } from 'react-redux';
import api from '../../../_helper/api_helper';
import { toast } from 'react-toastify';
import BeatLoader from 'react-spinners/BeatLoader';
import { DOWNLOAD_TRADES_URL, FETCH_TRADES_URL } from '../../../_helper/url_helper';
import { convertToIndianTime, parseDateRange } from '../../../_helper/dateFormate';
import CustomSelect from '../Dashboard/Customselect';

const GeneralComponent = () => {
    const loginState = useSelector((state) => state.login);
    const [allData, setAllData] = useState([]);
    const [selectedOption,setSelectedOption]=useState(null)
    const [loading, setLoading] = useState(false);
    const [resultsPerPage, setResultsPerPage] = useState(10);
    const [pagination, setPagination] = useState({
        current: 1,
        total: 1,
        totalRows: 0
    });

    const [filters, setFilters] = useState({
        userName: "",
        script: "",
        exchange: "ALL",
        close_min: "",
    });

    const filterOptions = {
        statusOptions: ["ALL", "MCX", "NFO", "CDS", "FOREX", "CRYPTO", "US"],
    };

    useEffect(() => {
        fetchTrades(pagination.current, filters);
    }, [pagination.current, resultsPerPage, filters]);

    const fetchTrades = async (page = 1, appliedFilters = filters,option) => {
        try {
            setLoading(true);
            api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
            const formattedDates=parseDateRange(option?.value)
            const startDate=formattedDates?.startDate;
            const endDate=formattedDates?.endDate;
            const params = {
                page,
                per_page: resultsPerPage,
                username: appliedFilters.userName || undefined,
                script: appliedFilters.script || undefined,
                exchange: appliedFilters.exchange !== "ALL" ? appliedFilters.exchange : undefined,
                close_min: appliedFilters.close_min || undefined
            };
            params.filter=option?.value;
            if(option?.label==="custom"||option?.label==="Custom"){
                params.filter='custom'
                params.start_date=startDate
                params.end_date=endDate
            }
            console.log("Fetching with params:", params);
            const res = await api.get(FETCH_TRADES_URL, { params });
            console.log("API Response:", res.data);
            if (res.data.status === "SUCCESS") {
                setAllData(res.data.payload?.data || []);
                setPagination({
                    current: res.data.payload.current_page,
                    total: res.data.payload.last_page,
                    totalRows: res.data.payload.total
                });
            } else {
                toast.error(res.data.message);
            }
        } catch (error) {
            console.error("Error fetching trades:", error);
            toast.error("Error fetching trades");
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
        fetchTrades(1, newFilters);
    };

    const handleDownloadCSV = async (appliedFilters = filters) => {
        try {
            api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
            let allFilteredData = [];
            let currentPage = 1;
            let hasMoreData = true;
            const perPage = 100; // Higher per_page to reduce requests

            while (hasMoreData) {
                const params = {
                    page: currentPage,
                    per_page: perPage,
                    username: appliedFilters.userName || undefined,
                    script: appliedFilters.script || undefined,
                    exchange: appliedFilters.exchange !== "ALL" ? appliedFilters.exchange : undefined,
                    close_min: appliedFilters.close_min || undefined
                };

                console.log("Fetching CSV data for page:", params);
                const res = await api.get(FETCH_TRADES_URL, { params });
                if (res.data.status === "SUCCESS") {
                    const pageData = res.data.payload?.data || [];
                    allFilteredData = [...allFilteredData, ...pageData];
                    const totalPages = res.data.payload.last_page;
                    hasMoreData = currentPage < totalPages;
                    currentPage++;
                } else {
                    throw new Error("Failed to fetch data");
                }
            }

            // Convert to CSV
            const csvContent = convertToCSV(allFilteredData);
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "trades_filtered.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.success("CSV downloaded successfully");
        } catch (error) {
            console.error("Error downloading CSV:", error);
            toast.error("Error downloading CSV: " + error.message);
        }
    };

    const handleDateFilterSubmit=(option)=>{
        setSelectedOption(option)
        fetchTrades(1,filters,option)
    }

    const convertToCSV = (data) => {
        if (!data.length) return 'No data available';
        const headers = [
            "ID", "Script", "Exchange", "Currency", "User", "Qty", "Type",
            "Buy Rate", "Sell Rate", "Profit/Loss", "Brokerage", "Net",
            "Used Margin", "Bought At", "Sold At", "Settled"
        ].join(',');
        const rows = data.map(txn => [
            txn.id, txn.trading_symbol, txn.exchange, txn.currency, txn.client_name,
            txn.qty, txn.type, txn.buy_price, Number(txn.sell_price).toFixed(2),
            txn.profit_and_loss, txn.brokerage, (txn.profit_and_loss - txn.brokerage).toFixed(2),
            txn.used_margin, convertToIndianTime(txn.bought_at), convertToIndianTime(txn.added_on), txn.is_settled.toUpperCase()
        ].map(value => `"${value}"`).join(',')).join('\n');
        return `${headers}\n${rows}`;
    };

    return (
        <>
            <div className="containerdiv">
                <div className="filterdiv" style={{ padding: "5px" }}>
                    <div className="filtertitle">
                        <h2 className='mb-0'>All Trades</h2>
                    </div>
                    <div className="filteranddate">
                        <button className="btn btn-secondary me-2" onClick={() => handleDownloadCSV(filters)}>
                            Download CSV
                        </button>
                        <CustomSelect onOptionSelect={handleDateFilterSubmit}/>
                        <Customtradfilter statusOptions={filterOptions.statusOptions} onSubmit={handleFilterChange} />
                    </div>
                </div>
            </div>

            <div className="containerdiv p-0" style={{ background: "transparent" }}>
                <div className="table-responsive mt-4">
                    <table className="table table-bordered">
                        <thead className="table-secondary">
                            <tr>
                                <th style={{whiteSpace:"nowrap"}}>ID</th>
                                <th style={{whiteSpace:"nowrap"}}>Script</th>
                                <th style={{whiteSpace:"nowrap"}}>Exchange</th>
                                <th style={{whiteSpace:"nowrap"}}>Currency</th>
                                <th style={{whiteSpace:"nowrap"}}>User</th>
                                <th style={{whiteSpace:"nowrap"}}>Qty</th>
                                <th style={{whiteSpace:"nowrap"}}>Type</th>
                                <th style={{whiteSpace:"nowrap"}}>Buy Rate</th>
                                <th style={{whiteSpace:"nowrap"}}>Sell Rate</th>
                                <th style={{whiteSpace:"nowrap"}}>Profit/Loss</th>
                                <th style={{whiteSpace:"nowrap"}}>Brokerage</th>
                                <th style={{whiteSpace:"nowrap"}}>Net</th>
                                <th style={{whiteSpace:"nowrap"}}>Used Margin</th>
                                <th style={{whiteSpace:"nowrap"}}>Bought At</th>
                                <th style={{whiteSpace:"nowrap"}}>Sold At</th>
                                <th style={{whiteSpace:"nowrap"}}>Settled</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="16" className="text-center">
                                        <BeatLoader color="rgba(37, 146, 213, 1)" />
                                    </td>
                                </tr>
                            ) : allData.length === 0 ? (
                                <tr>
                                    <td colSpan="16" className="text-center">
                                        No data available.
                                    </td>
                                </tr>
                            ) : (
                                allData.map((txn) => (
                                    <tr key={txn.id}>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{txn.id}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{txn.trading_symbol}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{txn.exchange}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{txn.currency}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{txn.client_name}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{txn.qty}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase",fontSize:"14px"}} className={txn.type === "buy" ? "text-success" : "text-danger"}>
                                            {txn.type}
                                        </td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{txn.buy_price}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{Number(txn.sell_price).toFixed(2)}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase",fontSize:"14px"}} className={txn.profit_and_loss > 0 ? "text-success" : "text-danger"}>
                                            {txn.profit_and_loss}
                                        </td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{txn.brokerage}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase",fontSize:"14px"}} className={txn.profit_and_loss > 0 ? "text-success" : "text-danger"}>{(txn.profit_and_loss - txn.brokerage).toFixed(2)}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{txn.used_margin}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{(txn.bought_at)}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{convertToIndianTime(txn.added_on)}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{txn.is_settled.toUpperCase()}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>

                <Paginationcomp
                    currentPage={pagination.current}
                    totalPages={pagination.total}
                    onPageChange={(page) => setPagination(prev => ({ ...prev, current: page }))}
                    totalResults={pagination.totalRows}
                    resultsPerPage={resultsPerPage}
                    onResultsPerPageChange={(value) => {
                        setResultsPerPage(value);
                        setPagination(prev => ({ ...prev, current: 1 }));
                    }}
                />
            </div>
        </>
    );
};

export default GeneralComponent;