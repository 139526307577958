import React, { Fragment, useState, useEffect } from "react";
import {
  CardBody,
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Nav,
  Collapse,
  NavItem,
  NavLink,
  Label,
} from "reactstrap";
import { useSelector } from "react-redux";
import api from "../../../../_helper/api_helper";
import { toast } from "react-toastify";
import {
  FETCH_MCX_SYMBOLS_URL,
  UPDATE_MARGIN_TEMPLATE,
  FETCH_SINGLE_MARGIN_TEMPLATE_URL,
} from "../../../../_helper/url_helper";
import { useNavigate, useParams } from "react-router-dom";
import ScriptTable from "./ScriptTable";

const GeneralComponent = () => {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const loginState = useSelector((state) => state.login);
  const [name, setName] = useState("");
  const [exchange, setExchange] = useState("equity");
  const [mcxMarginType, setMcxMarginType] = useState("multiply");
  const [equityMarginMis, setEquityMarginMis] = useState("");
  const [equityMarginCnc, setEquityMarginCnc] = useState("");
  const [equityOptBuyingMarginMis, setEquityOptBuyingMarginMis] = useState("");
  const [equityOptBuyingMarginCnc, setEquityOptBuyingMarginCnc] = useState("");
  const [equityOptSellingMarginMis, setEquityOptSellingMarginMis] =
    useState("");
  const [equityOptSellingMarginCnc, setEquityOptSellingMarginCnc] =
    useState("");
  const [cdsMarginMis, setCdsMarginMis] = useState("");
  const [cdsMarginCnc, setCdsMarginCnc] = useState("");
  const [mcxMarginMis, setMcxMarginMis] = useState("");
  const [mcxMarginCnc, setMcxMarginCnc] = useState("");
  const [mcxOptBuyingMarginMis, setMcxOptBuyingMarginMis] = useState("");
  const [mcxOptBuyingMarginCnc, setMcxOptBuyingMarginCnc] = useState("");
  const [mcxOptSellingMarginMis, setMcxOptSellingMarginMis] = useState("");
  const [mcxOptSellingMarginCnc, setMcxOptSellingMarginCnc] = useState("");
  const [mcxSymbols, setMcxSymbols] = useState("");
  const [mcxMarginFix, setMcxMarginFix] = useState("");

  //fetch first time users
  useEffect(() => {
    fetchMcxSymbols();
  }, []);

  async function fetchMcxSymbols() {
    try {
      let data = {
        jwttoken: loginState.jwttoken,
      };
      let res = await api.post(FETCH_MCX_SYMBOLS_URL, data);
      if (res.data.status === 1) {
        const temp = {};
        res.data.data.map((symbol) => {
          let obj = {};
          temp[symbol["symbol"] + "_margin_mis"] = "";
          temp[symbol["symbol"] + "_margin_cnc"] = "";
          return obj;
        });
        setMcxMarginFix(temp);
        setMcxSymbols(res.data.data);
        fetchTemplate();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function fetchTemplate() {
    try {
      let data = {
        jwttoken: loginState.jwttoken,
        template_id: templateId,
      };
      let res = await api.post(FETCH_SINGLE_MARGIN_TEMPLATE_URL, data);
      if (res.data.status === 1) {
        setName(res.data.data.template.name);
        setEquityMarginMis(res.data.data.template.equity_margin_mis);
        setEquityMarginCnc(res.data.data.template.equity_margin_cnc);
        setEquityOptBuyingMarginMis(res.data.data.template.equity_opt_buying_margin_mis);
        setEquityOptBuyingMarginCnc(res.data.data.template.equity_opt_buying_margin_cnc);
        setEquityOptSellingMarginMis(res.data.data.template.equity_opt_selling_margin_mis);
        setEquityOptSellingMarginCnc(res.data.data.template.equity_opt_selling_margin_cnc);
        setCdsMarginMis(res.data.data.template.cds_margin_mis);
        setCdsMarginCnc(res.data.data.template.cds_margin_cnc);
        setMcxMarginType(res.data.data.template.mcx_margin_type);

        if (res.data.data.template.mcx_margin_type === "multiply") {
          setMcxMarginMis(res.data.data.template.mcx_margin_mis);
          setMcxMarginCnc(res.data.data.template.mcx_margin_cnc);
          setMcxOptBuyingMarginMis(res.data.data.template.mcx_opt_buying_margin_mis);
          setMcxOptBuyingMarginCnc(res.data.data.template.mcx_opt_buying_margin_cnc);
          setMcxOptSellingMarginMis(res.data.data.template.mcx_opt_selling_margin_mis);
          setMcxOptSellingMarginCnc(res.data.data.template.mcx_opt_selling_margin_cnc);
        } else {
          const temp = {};
          res.data.data.mcx_fix_margin.map((margin) => {
            let obj = {};
            temp[margin["instrument_symbol"] + "_margin_mis"] =
              margin["margin_mis"];
            temp[margin["instrument_symbol"] + "_margin_cnc"] =
              margin["margin_cnc"];
            return obj;
          });
          setMcxMarginFix(temp);
        }
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function updateTemplateHandler() {
    try {
      let data = {};
      if (name === "") {
        toast.error("Template name required");
        return;
      }
      if (equityMarginMis === "" || equityMarginCnc === "" || equityOptBuyingMarginMis === "" || equityOptBuyingMarginCnc === "" || equityOptSellingMarginMis === "" || equityOptSellingMarginCnc === "") {
        toast.error("EQUITY margin required");
        setExchange("equity");
        return;
      }
      if (cdsMarginMis === "" || cdsMarginCnc === "") {
        toast.error("CDS margin required");
        setExchange("cds");
        return;
      }
      if (mcxMarginType === "multiply") {
        if (mcxMarginMis === "" || mcxMarginCnc === "" || mcxOptBuyingMarginMis === "" || mcxOptBuyingMarginCnc === "" || mcxOptSellingMarginMis === "" || mcxOptSellingMarginCnc === "") {
          toast.error("MCX margin required");
          setExchange("mcx");
          return;
        } else {
          data["mcx_margin_mis"] = mcxMarginMis;
          data["mcx_margin_cnc"] = mcxMarginCnc;
          data["mcx_opt_buying_margin_mis"] = mcxOptBuyingMarginMis;
          data["mcx_opt_buying_margin_cnc"] = mcxOptBuyingMarginCnc;
          data["mcx_opt_selling_margin_mis"] = mcxOptSellingMarginMis;
          data["mcx_opt_selling_margin_cnc"] = mcxOptSellingMarginCnc;
        }
      } else {
        for (let i = 0; i < mcxSymbols.length; i++) {
          if (
            mcxMarginFix[mcxSymbols[i]["symbol"] + "_margin_mis"] === "" ||
            mcxMarginFix[mcxSymbols[i]["symbol"] + "_margin_cnc"] === ""
          ) {
            toast.error(`${mcxSymbols[i]["symbol"]} margin required`);
            setExchange("mcx");
            return;
          } else {
            data[mcxSymbols[i]["symbol"] + "_margin_mis"] =
              mcxMarginFix[mcxSymbols[i]["symbol"] + "_margin_mis"];
            data[mcxSymbols[i]["symbol"] + "_margin_cnc"] =
              mcxMarginFix[mcxSymbols[i]["symbol"] + "_margin_cnc"];
          }
        }
      }
      data = {
        ...data,
        jwttoken: loginState.jwttoken,
        template_id: templateId,
        name: name,
        equity_margin_mis: equityMarginMis,
        equity_margin_cnc: equityMarginCnc,
        equity_opt_buying_margin_mis: equityOptBuyingMarginMis,
        equity_opt_buying_margin_cnc: equityOptBuyingMarginCnc,
        equity_opt_selling_margin_mis: equityOptSellingMarginMis,
        equity_opt_selling_margin_cnc: equityOptSellingMarginCnc,
        cds_margin_mis: cdsMarginMis,
        cds_margin_cnc: cdsMarginCnc,
        mcx_margin_type: mcxMarginType,
      };
      let res = await api.post(UPDATE_MARGIN_TEMPLATE, data);
      if (res.data.status === 1) {
        toast.success("Template created sucessfully");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <Fragment>
      <Container fluid={true} className="datatables">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="p-b-0 mb-3 pt-4">
                <div className="row g-4 align-items-center">
                  <div className="col-sm">
                    <div>
                      <h5 className="card-title mb-0">
                       {name}
                      </h5>
                    </div>
                  </div>
                  <div className="col-sm-auto">
                    {/* <div className="row">
                      <div className="col-8">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter template name"
                          value={name}
                          readOnly
                        />
                      </div>
                      <div className="col-4">
                        <button
                          type="button"
                          className="btn btn-success add-btn"
                          onClick={updateTemplateHandler}
                        >
                          Update
                        </button>
                      </div>
                    </div> */}
                  </div>
                </div>
              </CardHeader>

              <CardBody className="border-top">
                <Nav tabs className="row justify-content-between fw-semibold mb-4">
                  <NavItem className="col px-0">
                    <NavLink
                      className={`text-center ${
                        exchange === "equity" ? "active" : ""
                      }`}
                      onClick={() => setExchange("equity")}
                    >
                      EQUITY
                    </NavLink>
                  </NavItem>
                  <NavItem className="col px-0">
                    <NavLink
                      className={`text-center ${
                        exchange === "cds" ? "active" : ""
                      }`}
                      onClick={() => setExchange("cds")}
                    >
                      CDS
                    </NavLink>
                  </NavItem>

                  <NavItem className="col px-0">
                    <NavLink
                      className={`text-center ${
                        exchange === "mcx" ? "active" : ""
                      }`}
                      onClick={() => setExchange("mcx")}
                    >
                      MCX
                    </NavLink>
                  </NavItem>
                </Nav>
                <Row>
                  {exchange === "equity" ? (
                    <>
                      <Col className="ps-0 mb-4" md={6}>
                        <Label>Equity Margin Intraday(MIS)</Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={equityMarginMis}
                          readOnly
                        />
                      </Col>
                      <Col className="ps-0 mb-4" md={6}>
                        <Label>Equity Margin Longterm(CNC)</Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={equityMarginCnc}
                          readOnly
                        />
                      </Col>
                      <Col className="ps-0 mb-4" md={6}>
                        <Label>Equity Option Buying Margin Intraday(MIS)</Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={equityOptBuyingMarginMis}
                         readOnly
                        />
                      </Col>
                      <Col className="ps-0 mb-4" md={6}>
                        <Label>Equity Option Buying Margin Longterm(CNC)</Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={equityOptBuyingMarginCnc}
                          readOnly
                        />
                      </Col>
                      <Col className="ps-0" md={6}>
                        <Label>
                          Equity Option Selling Margin Intraday(MIS)
                        </Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={equityOptSellingMarginMis}
                          readOnly
                        />
                      </Col>
                      <Col className="ps-0" md={6}>
                        <Label>
                          Equity Option Selling Margin Longterm(CNC)
                        </Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={equityOptSellingMarginCnc}
                          readOnly
                        />
                      </Col>
                    </>
                  ) : (
                    ""
                  )}

                  {exchange === "cds" ? (
                    <>
                      <Col className="ps-0" md={6}>
                        <Label>CDS Margin Intraday(MIS)</Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={cdsMarginMis}
                          readOnly
                        />
                      </Col>
                      <Col className="ps-0" md={6}>
                        <Label>CDS Margin Longterm(CNC)</Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={cdsMarginCnc}
                          readOnly
                        />
                      </Col>
                    </>
                  ) : (
                    ""
                  )}

                  {exchange === "mcx" ? (
                    <>
                      <Col className="mb-4 px-0" sm={12}>
                        <button
                          className={`btn ${
                            mcxMarginType === "multiply"
                              ? "btn-primary"
                              : "btn-outline-primary"
                          }`}
                         
                        >
                          Multiply
                        </button>
                        <button
                          className={`btn ${
                            mcxMarginType === "fix"
                              ? "btn-primary"
                              : "btn-outline-primary"
                          } mx-4`}
                          
                        >
                          Fix
                        </button>
                      </Col>
                      <Collapse isOpen={mcxMarginType === "multiply"}>
                        <>
                          <Row>
                            <Col className="ps-0 mb-4" md={6}>
                              <Label>MCX Margin Intraday(MIS)</Label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter margin"
                                value={mcxMarginMis}
                                readOnly
                              />
                            </Col>
                            <Col className="ps-0 mb-4" md={6}>
                              <Label>MCX Margin Longterm(CNC)</Label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter margin"
                                value={mcxMarginCnc}
                                readOnly
                              />
                            </Col>
                            <Col className="ps-0 mb-4" md={6}>
                        <Label>MCX Option Buying Margin Intraday(MIS)</Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={mcxOptBuyingMarginMis}
                          readOnly
                        />
                      </Col>
                      <Col className="ps-0 mb-4" md={6}>
                        <Label>MCX Option Buying Margin Longterm(CNC)</Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={mcxOptBuyingMarginCnc}
                          readOnly
                        />
                      </Col>
                      <Col className="ps-0" md={6}>
                        <Label>
                          MCX Option Selling Margin Intraday(MIS)
                        </Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={mcxOptSellingMarginMis}
                          readOnly
                        />
                      </Col>
                      <Col className="ps-0" md={6}>
                        <Label>
                          MCX Option Selling Margin Longterm(CNC)
                        </Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={mcxOptSellingMarginCnc}
                          readOnly
                        />
                      </Col>
                          </Row>
                        </>
                      </Collapse>
                    </>
                  ) : (
                    ""
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ScriptTable
          exchange={exchange}
          mcxMarginType={mcxMarginType}
          mcxSymbols={mcxSymbols}
          mcxMarginFix={mcxMarginFix}
          setMcxMarginFix={setMcxMarginFix}
        />
      </Container>
    </Fragment>
  );
};

export default GeneralComponent;
