import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Container, Row, Col, Button } from "reactstrap";
import {
    FETCH_REFERRAL_SETTING_URL,
    UPDATE_REFERRAL_SETTINGS_URL
} from "../../../_helper/url_helper";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import api from "../../../_helper/api_helper";

const LevelReferralSettings = () => {
    const [numberOfLevels, setNumberOfLevels] = useState(1);
    const [levelSettings, setLevelSettings] = useState([]);
    const loginState = useSelector((state) => state.login);

    const handleInputChange = (e) => {
        const value = parseInt(e.target.value, 10) || 0;
        setNumberOfLevels(value);
    };

    const handleGenerateLevels = () => {
        const newLevels = Array.from({ length: numberOfLevels }, (_, index) => ({
            level: index + 1,
            percentage: levelSettings[index] ? levelSettings[index].percentage : 0,
        }));
        setLevelSettings(newLevels);
    };

    const handlePercentageChange = (index, value) => {
        const updatedSettings = [...levelSettings];
        updatedSettings[index] = { ...updatedSettings[index], percentage: value };
        setLevelSettings(updatedSettings);
    };

    useEffect(() => {
        fetchDataHandler();
    }, []);

    async function fetchDataHandler() {
        try {
            api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
            let res = await api.get(FETCH_REFERRAL_SETTING_URL);
            if (res.data.status === "SUCCESS") {
                setNumberOfLevels(res.data.payload.length);
                setLevelSettings(res.data.payload);
            } else {
                toast.error(res.data.message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    }

    async function updateDataHandler() {
        try {
            let data = {
                "levels": numberOfLevels
            }
            levelSettings.forEach((level) => {
                data["level_" + level.level] = level.percentage;
            });
            api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
            let res = await api.post(UPDATE_REFERRAL_SETTINGS_URL, data);
            if (res.data.status === "SUCCESS") {
                toast.success("Settings updated successfully")
            } else {
                toast.error(res.data.message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    }


    return (
        <Container fluid={true}>
            <Row>
                <Col sm="12">
                    <Card>
                        <CardHeader>
                            <Row className="align-items-center">
                                <Col md="6">
                                    <h5>Referral Level Brokerage Settings</h5>
                                </Col>
                                <Col md="6" className="text-end">
                                    <input
                                        type="number"
                                        className="form-control d-inline-block w-25 me-3"
                                        placeholder="Enter Levels"
                                        value={numberOfLevels}
                                        onChange={handleInputChange}
                                    />
                                    <Button color="primary" className="ml-2" onClick={handleGenerateLevels}>
                                        Generate Levels
                                    </Button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="12">
                                    <table className="table table-bordered fw-semibold">
                                        <thead>
                                            <tr>
                                                <th>Level</th>
                                                <th>Percentage</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {levelSettings.map((level, index) => (
                                                <tr key={index}>
                                                    <td>{level.level}</td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control fw-semibold"
                                                            value={level.percentage}
                                                            onChange={(e) => handlePercentageChange(index, e.target.value)}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md="12">
                                    <Button color="primary" onClick={updateDataHandler}>
                                        Update Settings
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default LevelReferralSettings;
