import React from 'react';

const Paginationcomp = ({ 
  currentPage, 
  totalPages = 1, 
  onPageChange, 
  resultsPerPage, 
  onResultsPerPageChange, 
  totalResults 
}) => {
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  // Function to calculate and format the page numbers
  const getPageNumbers = () => {
    const maxPagesToShow = 5;
    let pages = [];
    
    if (totalPages <= maxPagesToShow) {
      pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      if (currentPage <= 3) {
        pages = [1, 2, 3, "...", totalPages];
      } else if (currentPage >= totalPages - 2) {
        pages = [1, "...", totalPages - 2, totalPages - 1, totalPages];
      } else {
        pages = [1, "...", currentPage - 1, currentPage, currentPage + 1, "...", totalPages];
      }
    }

    return pages;
  };

  

  // Calculate the range of items currently being displayed
  const startItem = (currentPage - 1) * resultsPerPage + 1;
  const endItem = Math.min(currentPage * resultsPerPage, totalResults);

  return (
    <div className="d-flex flex-column flex-sm-row gap-2 justify-content-between align-items-center mt-4 mb-4">
      {/* Total Results and Showing Info */}
      <div>
        <span>Showing {startItem} – {endItem} of {totalResults}</span>
      </div>

      {/* Pagination Controls */}
      <div>
        <nav>
          <ul className="pagination justify-content-center gap-2">
           
            {/* Previous Button */}
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`} >
              <button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} style={{padding:"2px 10px"}}>
                Back
              </button>
            </li>

            {/* Page Numbers with Ellipsis */}
            {getPageNumbers().map((page, index) => (
              <li key={index} className={`page-item ${page === currentPage ? 'active' : ''}`} >
                {page === "..." ? (
                  <span className="page-link">...</span>
                ) : (
                  <button className="page-link" onClick={() => handlePageChange(page)} style={{padding:"2px 10px"}}>
                    {page}
                  </button>
                )}
              </li>
            ))}

            {/* Next Button */}
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`} >
              <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} style={{padding:"2px 10px"}}>
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>

      {/* Results Per Page */}
      <div>
        <span>
          Results per Page:
          <select name="resultsPerPage" id="resultsPerPage" value={resultsPerPage} onChange={(e) => onResultsPerPageChange(Number(e.target.value))}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </span>
      </div>
    </div>
  );
};

export default Paginationcomp;
