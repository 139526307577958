import React, { useEffect, useState } from 'react';
import CustomSelect from '../Dashboard/Customselect';
import Customtradfilter from '../Trades/Customtradfilter';
import Paginationcomp from '../TransactionAnalysis/Paginationcomp';
import { useSelector } from 'react-redux';
import api from '../../../_helper/api_helper';
import { toast } from 'react-toastify';
import BeatLoader from 'react-spinners/BeatLoader';
import { DOWNLOAD_BILL_CSV_URL, DOWNLOAD_TRADES_URL, FETCH_BILL_PDF_URL, FETCH_TRADES_URL } from '../../../_helper/url_helper';
import { convertToIndianTime } from '../../../_helper/dateFormate';

const GeneralComponent = () => {   

    const loginState = useSelector((state) => state.login);    
    const [allData, setAllData] = useState([]); // Stores fetched data
    const [loading, setLoading] = useState(false);
    const [resultsPerPage, setResultsPerPage] = useState(10);
    const [pagination, setPagination] = useState({
        current: 1,
        total: 1,
        totalRows: 0
    });

    const [filters, setFilters] = useState({
        userName: "",
        script: "",
        exchange: "ALL",
        close_min: "",
    });

    const filterOptions = {
        statusOptions: ["ALL", "MCX", "NFO", "CDS", "FOREX", "CRYPTO", "US"],
    };

    console.log("allDataallDataallDataallDataallDataallData====Bill page",allData);

    useEffect(() => {
        fetchTrades(pagination.current, filters); // Fetch with pagination and filters
    }, [pagination.current, resultsPerPage, filters]);

    
    
    const fetchTrades = async (page = 1, appliedFilters = filters) => {
        try {
            setLoading(true);
            api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
    
            const params = {
                page,
                per_page: resultsPerPage,
                username: appliedFilters.userName || undefined,
                script: appliedFilters.script || undefined,
                exchange: appliedFilters.exchange !== "ALL" ? appliedFilters.exchange : undefined,
                close_min: appliedFilters.close_min || undefined,
                date_filter: appliedFilters.date_filter || undefined, // "today", "last_week", etc.
                start_date: appliedFilters.start_date || undefined, // Custom start date
                end_date: appliedFilters.end_date || undefined, // Custom end date
            };    
            const res = await api.get(FETCH_BILL_PDF_URL, { params });    
            if (res.data.status === "SUCCESS") {
                setAllData(res.data.payload?.data || []);
                setPagination({
                    current: res.data.payload.current_page,
                    total: res.data.payload.last_page,
                    totalRows: res.data.payload.total
                });
            } else {
                toast.error(res.data.message);
            }
        } catch (error) {
            console.error("Error fetching trades:", error);
            toast.error("Error fetching trades");
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (newFilters) => {
        setFilters((prevFilters) => {
            const updatedFilters = { ...prevFilters, ...newFilters };
            fetchTrades(1, updatedFilters); // Ensure updated filters are passed
            return updatedFilters; // Update state properly
        });
    };
    

    const handleDownloadCSV = async (page = 1, appliedFilters = filters) => {
        try {
            api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
            let allFilteredData = [];
            let currentPage = 1;
            let hasMoreData = true;
            const perPage = 100; // Higher per_page to reduce requests

            while (hasMoreData) {
                const params = {
                    page: currentPage,
                    per_page: perPage,
                    username: appliedFilters.userName || undefined,
                    script: appliedFilters.script || undefined,
                    exchange: appliedFilters.exchange !== "ALL" ? appliedFilters.exchange : undefined,
                    close_min: appliedFilters.close_min || undefined,
                    close_min: appliedFilters.close_min || undefined,
                    date_filter: appliedFilters.date_filter || undefined, // "today", "last_week", etc.
                    start_date: appliedFilters.start_date || undefined, // Custom start date
                    end_date: appliedFilters.end_date || undefined, // Custom end date
                };

                console.log("Fetching CSV data for page:", params);
                const res = await api.get(FETCH_BILL_PDF_URL, { params });
                if (res.data.status === "SUCCESS") {
                    const pageData = res.data.payload?.data || [];
                    allFilteredData = [...allFilteredData, ...pageData];
                    const totalPages = res.data.payload.last_page;
                    hasMoreData = currentPage < totalPages;
                    currentPage++;
                } else {
                    throw new Error("Failed to fetch data");
                }
            }

            // Convert to CSV
            const csvContent = convertToCSV(allFilteredData);
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "billmaster_filtered.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.success("CSV downloaded successfully");
        } catch (error) {
            console.error("Error downloading CSV:", error);
            toast.error("Error downloading CSV: " + error.message);
        }
    };

    const convertToCSV = (data) => {
        if (!data.length) return 'No data available';
        const headers = [
            "ID", "Script", "Exchange", "Currency", "User", "Qty", "Type",
            "Buy Rate", "Sell Rate", "Profit/Loss", "Brokerage", "Net",
            "Used Margin", "Bought At", "Sold At", "Settled"
        ].join(',');
        const rows = data.map(txn => [
            txn.id, txn.trading_symbol, txn.exchange, txn.currency, txn.client_name,
            txn.qty, txn.type, txn.buy_price, Number(txn.sell_price).toFixed(2),
            txn.profit_and_loss, txn.brokerage, (txn.profit_and_loss - txn.brokerage).toFixed(2),
            txn.used_margin,convertToIndianTime( txn.bought_at), convertToIndianTime(txn.added_on), txn.is_settled.toUpperCase()
        ].map(value => `"${value}"`).join(',')).join('\n');
        return `${headers}\n${rows}`;
    };

    return (
        <>
            <div className="container containerdiv">
                <div className="filterdiv" style={{ padding: "5px" }}>
                    <div className="filtertitle">
                        <h2 className="mb-0">Bill Master</h2>
                    </div>
                    <div className="filteranddate">
                        <button className="btn btn-secondary me-2" onClick={handleDownloadCSV}> Download </button>
                        {/* <CustomSelect onOptionSelect={(selectedFilters) => handleFilterChange(selectedFilters)} /> */}
                        <CustomSelect 
                            onOptionSelect={(selectedFilters) => {
                                const updatedFilters = {};
                                if (selectedFilters.value === "custom") {
                                    updatedFilters.start_date = selectedFilters.startDate;
                                    updatedFilters.end_date = selectedFilters.endDate;
                                } else {
                                    updatedFilters.date_filter = selectedFilters.value; // e.g., "today", "last_week"
                                    updatedFilters.start_date = undefined; // Reset if not custom
                                    updatedFilters.end_date = undefined;
                                }

                                handleFilterChange(updatedFilters);
                            }} 
                        />

                        <Customtradfilter statusOptions={["ALL", "MCX", "NFO", "CDS", "FOREX", "CRYPTO", "US"]} onSubmit={(newFilters) => handleFilterChange(newFilters)} />
                    </div>
                </div>
            </div>

            <div className="container containerdiv p-0" style={{ background: "transparent" }}>
                <div className="table-responsive mt-4">
                    <table className="table table-bordered">
                        <thead className="table-secondary">
                            <tr>
                                <th style={{whiteSpace:"nowrap"}}>ID</th>
                                <th style={{whiteSpace:"nowrap"}}>Script</th>
                                <th style={{whiteSpace:"nowrap"}}>Exchange</th>
                                <th style={{whiteSpace:"nowrap"}}>Currency</th>
                                <th style={{whiteSpace:"nowrap"}}>User</th>
                                <th style={{whiteSpace:"nowrap"}}>Qty</th>
                                <th style={{whiteSpace:"nowrap"}}>Type</th>
                                <th style={{whiteSpace:"nowrap"}}>BuyRate</th>
                                <th style={{whiteSpace:"nowrap"}}>SellRate</th>
                                <th style={{whiteSpace:"nowrap"}}>Profit/Loss</th>
                                <th style={{whiteSpace:"nowrap"}}>Brokerage</th>
                                <th style={{whiteSpace:"nowrap"}}>Net</th>
                                <th style={{whiteSpace:"nowrap"}}>UsedMargin</th>
                                <th style={{whiteSpace:"nowrap"}}>BoughtAt</th>
                                <th style={{whiteSpace:"nowrap"}}>SoldAt</th>
                                <th style={{whiteSpace:"nowrap"}}>isSettled</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="16" className="text-center">
                                        <BeatLoader color="rgba(37, 146, 213, 1)" />
                                    </td>
                                </tr>
                            ) : allData.length === 0 ? (
                                <tr>
                                    <td colSpan="16" className="text-center">
                                        No data available for the selected filters.
                                    </td>
                                </tr>
                            ) : (
                                allData.map((txn, index) => (
                                    <tr key={index}>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{index + 1}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{txn.trading_symbol}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{txn.exchange}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{txn.currency}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{txn.client_name}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{txn.qty}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{txn.type}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{txn.buy_price}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{Number(txn.sell_price).toFixed(2)}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{txn.profit_and_loss}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{txn.brokerage}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase",fontSize:"14px"}} className={`${(txn.profit_and_loss > 0) ? "text-success" : "text-danger"}`}>{(Number(txn.profit_and_loss) - Number(txn.brokerage)).toFixed(2)}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{txn.used_margin}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{(txn.bought_at)}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{convertToIndianTime(txn.added_on)}</td>
                                        <td style={{whiteSpace:"nowrap",textTransform:"uppercase"}}>{txn.is_settled}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>                   
                </div>
                <Paginationcomp
                    currentPage={pagination.current}
                    totalPages={pagination.total}
                    onPageChange={(page) => fetchTrades(page)}
                    resultsPerPage={resultsPerPage}
                    totalResults = {pagination.totalRows}
                    onResultsPerPageChange={(value) => {
                        setResultsPerPage(value);
                        fetchTrades(1, filters); // Reset to page 1 when changing results per page
                    }}
                />
            </div>
        </>
    );
};

export default GeneralComponent;
