import React, { Fragment, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  CardBody,
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Table,
} from "reactstrap";
import { H2, H4, H5 } from "../../../AbstractElements";
import { useSelector, useDispatch } from "react-redux";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import { FETCH_MARGIN_TEMPLATES_URL, DELETE_MARGIN_URL, DELETE_GLOBAL_MARGIN_URL } from "../../../_helper/url_helper";
import { useNavigate } from "react-router-dom";
import { fetchOwner } from "../../../store/login/action";

const GeneralComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginState = useSelector((state) => state.login);
  const [data, setData] = useState([]);
  const [type, setType] = useState("all");
  const [username, setUsername] = useState("");
  const [status, setStatus] = useState("all");
  //fetch first time users
  useEffect(() => {
    dispatch(fetchOwner({ jwttoken: loginState.jwttoken }));
    fetchTemplatesHandler();
  }, []);

  async function fetchTemplatesHandler() {
    try {
      // let data = {
      //   jwttoken: loginState.jwttoken,
      // };
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_MARGIN_TEMPLATES_URL, data);
      if (res.data.status === "SUCCESS") {
        setData(res.data.payload);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function deleteTemplateHandler(templateId) {
    try {
      if (!window.confirm("Are you sure?")) {
        return;
      }
      let data = {
        // jwttoken: loginState.jwttoken,
        template_id: templateId
      };
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.post(DELETE_MARGIN_URL, data);
      if (res.data.status === "SUCCESS") {
        toast.success("Template deleted successfully");
        fetchTemplatesHandler();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }


  let i = 1;
  const tableColumns = [
    {
      name: "#",
      selector: (row) => ++i,
      sortable: true,
      // width: "120px",
      center: true,
    },
    {
      name: <span className="fw-bold text-center">TEMPLATE ID</span>,
      selector: (row) => row.id,
      sortable: true,
      //   width: "120px",
      center: true,
    },
    {
      name: <span className="fw-bold text-center">TEMPLATE NAME</span>,
      selector: (row) => row.name,
      sortable: true,
      // width: "120px",
      center: true,
    },
    // {
    //   name: (
    //     <span className="fw-bold text-center">
    //       EQUITY MARGIN <br />
    //       MIS
    //     </span>
    //   ),
    //   selector: (row) => row.equity_margin_mis,
    //   sortable: true,
    //   // width: "120px",
    //   center: true,
    // },
    // {
    //   name: (
    //     <span className="fw-bold text-center">
    //       EQUITY MARGIN <br />
    //       CNC
    //     </span>
    //   ),
    //   selector: (row) => row.equity_margin_cnc,
    //   sortable: true,
    //   // width: "120px",
    //   center: true,
    // },
    // {
    //   name: (
    //     <span className="fw-bold text-center">
    //       CDS MARGIN <br />
    //       MIS
    //     </span>
    //   ),
    //   selector: (row) => row.cds_margin_mis,
    //   sortable: true,
    //   // width: "120px",
    //   center: true,
    // },
    // {
    //   name: (
    //     <span className="fw-bold text-center">
    //       CDS MARGIN <br />
    //       CNC
    //     </span>
    //   ),
    //   selector: (row) => row.equity_margin_cnc,
    //   sortable: true,
    //   // width: "120px",
    //   center: true,
    // },
    // {
    //   name: (
    //     <span className="fw-bold text-center">
    //       MCX MARGIN <br />
    //       TYPE
    //     </span>
    //   ),
    //   selector: (row) => row.mcx_margin_type,
    //   sortable: true,
    //   // width: "120px",
    //   center: true,
    // },
    {
      name: "ACTIONS",
      selector: (row) => actionBinder(row, navigate, loginState, deleteTemplateHandler),
      sortable: true,
      // width: "120px",
      center: true,
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Templates per page",
  };

  return (
    <Fragment>
      <Container fluid={true} className="datatables">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="p-b-0 mb-3 pt-4">
                <div className="justify-content-between row align-items-center">
                  <div className="col-4 col">
                    <h5 className="m-0 p-0 d-inline-block">
                      <i className="fa fa-users"></i> Margin Templates{" "}
                    </h5>{" "}
                    <span className="badge rounded-pill badge bg-primary rounded-pill">
                      {data.length}
                    </span>
                  </div>
                  <div className="col-4 col text-end">
                    {(loginState.owner.type !== "broker") && <button
                      className="btn btn-primary"
                      onClick={() => navigate("/marginTemplate/create")}
                    >
                      <i className="fa fa-plus"></i> Create
                    </button>}
                  </div>
                </div>
              </CardHeader>

              <div className="table-responsive">
                <DataTable
                  data={data}
                  columns={tableColumns}
                  striped
                  center={false}
                  paginationRowsPerPageOptions={[10, 25, 50, 100]}
                  pagination
                  paginationComponentOptions={paginationComponentOptions}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GeneralComponent;

function actionBinder(row, navigate, loginState, deleteTemplateHandler) {
  return (
    <div>
      <ul className="list-inline hstack gap-1 mb-0">
        {/* <li className="list-inline-item">
          <button
            title="view"
            className="btn btn-sm btn-primary p-0 d-flex justify-content-center align-items-center fw-semibold"
            style={{ width: "30px", height: "30px" }}
            onClick={() => navigate(`/marginTemplate/view/${row.id}`)}
          >
            <i className="fa fa-eye"></i>
          </button>
        </li> */}
        {(loginState.owner.type === "super" || (loginState.owner.type !== "broker" && loginState.owner.id === row.creator_id)) && <><li className="list-inline-item edit">
          <button
            title="update"
            className="btn btn-sm btn-secondary p-0 d-flex justify-content-center align-items-center fw-semibold"
            style={{ width: "30px", height: "30px" }}
            onClick={() => navigate(`/marginTemplate/update/${row.id}`)}
          >
            <i className="fa fa-pencil"></i>
          </button>
        </li>
          <li>
            <button
              title="Delete"
              className="btn btn-sm btn-outline-danger p-0 d-flex justify-content-center align-items-center fw-semibold"
              style={{ width: "30px", height: "30px" }}
              onClick={() => { deleteTemplateHandler(row.id) }}
            >
              <i className="fa fa-trash-o"></i>
            </button>
          </li></>}
      </ul>
    </div>
  );
}
