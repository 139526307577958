import { useEffect, useState } from "react";
import io from "socket.io-client";

const useSocket = (url) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (!url) return;

    const newSocket = io(url, {
      transports: ["websocket"],
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 5000,
    });

    // Heartbeat mechanism to keep the connection alive
    const heartbeatInterval = setInterval(() => {
      if (newSocket.connected) {
        newSocket.emit("ping");
        console.log("Sent heartbeat ping to", url);
      }
    }, 30000);

    newSocket.on("connect", () => {
      console.log("Connected to", url);
      setSocket(newSocket);
    });

    newSocket.on("disconnect", (reason) => {
      console.warn(`Disconnected from ${url}:`, reason);
      clearInterval(heartbeatInterval);
    });

    newSocket.on("error", (error) => {
      console.error(`WebSocket error on ${url}:`, error);
    });

    return () => {
      newSocket.disconnect();
      clearInterval(heartbeatInterval);
    };
  }, [url]);

  return socket;
};

export default useSocket;
