import React, { Fragment, useState, useEffect } from "react";
import {
  CardBody,
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Nav,
  Collapse,
  NavItem,
  NavLink,
  Label,
} from "reactstrap";
import { useSelector } from "react-redux";
import api from "../../../../_helper/api_helper";
import { toast } from "react-toastify";
import {
  FETCH_SYMBOLS_URL,
  CREATE_MARGIN_URL,
  FETCH_SINGLE_MARGIN_TEMPLATE_URL,
  UPDATE_MARGIN_TEMPLATE
} from "../../../../_helper/url_helper";
import { useNavigate, useParams } from "react-router-dom";
import ScriptTable from "./ScriptTable";

const GeneralComponent = () => {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const loginState = useSelector((state) => state.login);
  const [name, setName] = useState("");
  const [exchange, setExchange] = useState("equity");
  const [mcxMarginType, setMcxMarginType] = useState("multiply");
  const [equityMarginMis, setEquityMarginMis] = useState("");
  const [equityMarginCnc, setEquityMarginCnc] = useState("");
  const [equityOptBuyingMarginMis, setEquityOptBuyingMarginMis] = useState("");
  const [equityOptBuyingMarginCnc, setEquityOptBuyingMarginCnc] = useState("");
  const [equityOptSellingMarginMis, setEquityOptSellingMarginMis] =
    useState("");
  const [equityOptSellingMarginCnc, setEquityOptSellingMarginCnc] =
    useState("");
  const [cdsMarginMis, setCdsMarginMis] = useState("");
  const [cdsMarginCnc, setCdsMarginCnc] = useState("");
  const [mcxMarginMis, setMcxMarginMis] = useState("");
  const [mcxMarginCnc, setMcxMarginCnc] = useState("");
  const [mcxOptBuyingMarginMis, setMcxOptBuyingMarginMis] = useState("");
  const [mcxOptBuyingMarginCnc, setMcxOptBuyingMarginCnc] = useState("");
  const [mcxOptSellingMarginMis, setMcxOptSellingMarginMis] = useState("");
  const [mcxOptSellingMarginCnc, setMcxOptSellingMarginCnc] = useState("");
  const [mcxSymbols, setMcxSymbols] = useState("");
  const [mcxMarginFix, setMcxMarginFix] = useState("");
  const [fxMarginMis, setFxMarginMis] = useState("");
  const [fxMarginCnc, setFxMarginCnc] = useState("");
  const [cryptoMarginMis, setCryptoMarginMis] = useState("");
  const [cryptoMarginCnc, setCryptoMarginCnc] = useState("");
  const [usMarginMis, setUsMarginMis] = useState("");
  const [usMarginCnc, setUsMarginCnc] = useState("");

  //fetch first time users
  useEffect(() => {
    fetchMcxSymbols();
  }, []);

   const [selectedOption, setSelectedOption] = useState("future");
    // const handleBalanceChange = (event) => {
    //     setSelectedOption(event.target.value);
    // };

    const handleSelectedOptionChange = (newValue) => {
      setSelectedOption(newValue);
      // console.log("Updated selectedOption in Parent:", newValue);
    };

   

  async function fetchMcxSymbols() {
    try {
      // let data = {
      //   jwttoken: loginState.jwttoken,
      // };
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_SYMBOLS_URL);
      if (res.data.status === "SUCCESS") {
        const temp = {};
        res.data.payload["MCX"].map((symbol) => {
          let obj = {};
          temp[symbol["symbol"] + "_margin_mis"] = "";
          temp[symbol["symbol"] + "_margin_cnc"] = "";
          return obj;
        });
        setMcxMarginFix(temp);
        setMcxSymbols(res.data.payload.MCX);
        if (templateId && (templateId !== "")) {
          fetchTemplate();
        }
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }


  async function fetchTemplate() {
    try {
      let data = {
        // jwttoken: loginState.jwttoken,
        template_id: templateId,
      };
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.post(FETCH_SINGLE_MARGIN_TEMPLATE_URL, data);
      if (res.data.status === "SUCCESS") {
        setName(res.data.payload.name);
        setEquityMarginMis(res.data.payload.equity_margin_mis);
        setEquityMarginCnc(res.data.payload.equity_margin_cnc);
        setEquityOptBuyingMarginMis(res.data.payload.equity_opt_buying_margin_mis);
        setEquityOptBuyingMarginCnc(res.data.payload.equity_opt_buying_margin_cnc);
        setEquityOptSellingMarginMis(res.data.payload.equity_opt_selling_margin_mis);
        setEquityOptSellingMarginCnc(res.data.payload.equity_opt_selling_margin_cnc);
        setCdsMarginMis(res.data.payload.cds_margin_mis);
        setCdsMarginCnc(res.data.payload.cds_margin_cnc);
        setMcxMarginType(res.data.payload.mcx_margin_type);
        setFxMarginMis(res.data.payload.eqtfx_margin_mis);
        setFxMarginCnc(res.data.payload.eqtfx_margin_cnc);
        setCryptoMarginMis(res.data.payload.prpcrypto_margin_mis);
        setCryptoMarginCnc(res.data.payload.prpcrypto_margin_cnc);
        setUsMarginMis(res.data.payload.futus_margin_mis);
        setUsMarginCnc(res.data.payload.futus_margin_cnc);

        if (res.data.payload.mcx_margin_type === "multiply") {
          setMcxMarginMis(res.data.payload.mcx_margin_mis);
          setMcxMarginCnc(res.data.payload.mcx_margin_cnc);
          setMcxOptBuyingMarginMis(res.data.payload.mcx_opt_buying_margin_mis);
          setMcxOptBuyingMarginCnc(res.data.payload.mcx_opt_buying_margin_cnc);
          setMcxOptSellingMarginMis(res.data.payload.mcx_opt_selling_margin_mis);
          setMcxOptSellingMarginCnc(res.data.payload.mcx_opt_selling_margin_cnc);
        } else {
          const temp = {};
          res.data.payload.get_margins.map((margin) => {
            let obj = {};
            temp[margin["instrument_symbol"] + "_margin_mis"] =
              margin["margin_mis"];
            temp[margin["instrument_symbol"] + "_margin_cnc"] =
              margin["margin_cnc"];
            return obj;
          });
          setMcxMarginFix(temp);
        }
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }


  async function updateTemplateHandler() {
    try {
      let data = {};
      if (name === "") {
        toast.error("Template name required");
        return;
      }
      if (equityMarginMis === "" || equityMarginCnc === "" || equityOptBuyingMarginMis === "" || equityOptBuyingMarginCnc === "" || equityOptSellingMarginMis === "" || equityOptSellingMarginCnc === "") {
        toast.error("EQUITY margin required");
        setExchange("equity");
        return;
      }
      if (cdsMarginMis === "" || cdsMarginCnc === "") {
        toast.error("CDS margin required");
        setExchange("cds");
        return;
      }

      if (fxMarginMis === "" || fxMarginCnc === "") {
        toast.error("FX margin required");
        setExchange("fx");
        return;
      }
      if (cryptoMarginMis === "" || cryptoMarginCnc === "") {
        toast.error("CRYPTO margin required");
        setExchange("crypto");
        return;
      }
      if (usMarginMis === "" || usMarginCnc === "") {
        toast.error("US margin required");
        setExchange("us");
        return;
      }

      if (mcxMarginType === "multiply") {
        if (mcxMarginMis === "" || mcxMarginCnc === "" || mcxOptBuyingMarginMis === "" || mcxOptBuyingMarginCnc === "" || mcxOptSellingMarginMis === "" || mcxOptSellingMarginCnc === "") {
          toast.error("MCX margin required");
          setExchange("mcx");
          return;
        } else {
          data["mcx_margin_mis"] = mcxMarginMis;
          data["mcx_margin_cnc"] = mcxMarginCnc;
          data["mcx_opt_buying_margin_mis"] = mcxOptBuyingMarginMis;
          data["mcx_opt_buying_margin_cnc"] = mcxOptBuyingMarginCnc;
          data["mcx_opt_selling_margin_mis"] = mcxOptSellingMarginMis;
          data["mcx_opt_selling_margin_cnc"] = mcxOptSellingMarginCnc;
        }
      } else {
        for (let i = 0; i < mcxSymbols.length; i++) {
          if (
            mcxMarginFix[mcxSymbols[i]["symbol"] + "_margin_mis"] === "" ||
            mcxMarginFix[mcxSymbols[i]["symbol"] + "_margin_cnc"] === ""
          ) {
            toast.error(`${mcxSymbols[i]["symbol"]} margin required`);
            setExchange("mcx");
            return;
          } else {
            data[mcxSymbols[i]["symbol"] + "_margin_mis"] =
              mcxMarginFix[mcxSymbols[i]["symbol"] + "_margin_mis"];
            data[mcxSymbols[i]["symbol"] + "_margin_cnc"] =
              mcxMarginFix[mcxSymbols[i]["symbol"] + "_margin_cnc"];
          }
        }
      }
      data = {
        ...data,
        // jwttoken: loginState.jwttoken,
        template_id: templateId,
        name: name,
        equity_margin_mis: equityMarginMis,
        equity_margin_cnc: equityMarginCnc,
        equity_opt_buying_margin_mis: equityOptBuyingMarginMis,
        equity_opt_buying_margin_cnc: equityOptBuyingMarginCnc,
        equity_opt_selling_margin_mis: equityOptSellingMarginMis,
        equity_opt_selling_margin_cnc: equityOptSellingMarginCnc,
        cds_margin_mis: cdsMarginMis,
        cds_margin_cnc: cdsMarginCnc,
        eqtfx_margin_mis: fxMarginMis,
        eqtfx_margin_cnc: fxMarginCnc,
        prpcrypto_margin_mis: cryptoMarginMis,
        prpcrypto_margin_cnc: cryptoMarginCnc,
        futus_margin_mis: usMarginMis,
        futus_margin_cnc: usMarginCnc,
        mcx_margin_type: mcxMarginType,
        optiontype: selectedOption,
      };
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.post(UPDATE_MARGIN_TEMPLATE, data);
      if (res.data.status === "SUCCESS") {
        toast.success("Template created sucessfully");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function submitTemplateHandler() {
    try {
      let data = {};
      if (name === "") {
        toast.error("Template name required");
        return;
      }
      if (equityMarginMis === "" || equityMarginCnc === "" || equityOptBuyingMarginMis === "" || equityOptBuyingMarginCnc === "" || equityOptSellingMarginMis === "" || equityOptSellingMarginCnc === "") {
        toast.error("EQUITY margin required");
        setExchange("equity");
        return;
      }
      if (cdsMarginMis === "" || cdsMarginCnc === "") {
        toast.error("CDS margin required");
        setExchange("cds");
        return;
      }
      if (fxMarginMis === "" || fxMarginCnc === "") {
        toast.error("FX margin required");
        setExchange("fx");
        return;
      }
      if (cryptoMarginMis === "" || cryptoMarginCnc === "") {
        toast.error("CRYPTO margin required");
        setExchange("crypto");
        return;
      }
      if (usMarginMis === "" || usMarginCnc === "") {
        toast.error("US margin required");
        setExchange("us");
        return;
      }
      if (mcxMarginType === "multiply") {
        if (mcxMarginMis === "" || mcxMarginCnc === "" || mcxOptBuyingMarginMis === "" || mcxOptBuyingMarginCnc === "" || mcxOptSellingMarginMis === "" || mcxOptSellingMarginCnc === "") {
          toast.error("MCX margin required");
          setExchange("mcx");
          return;
        } else {
          data["mcx_margin_mis"] = mcxMarginMis;
          data["mcx_margin_cnc"] = mcxMarginCnc;
          data["mcx_opt_buying_margin_mis"] = mcxOptBuyingMarginMis;
          data["mcx_opt_buying_margin_cnc"] = mcxOptBuyingMarginCnc;
          data["mcx_opt_selling_margin_mis"] = mcxOptSellingMarginMis;
          data["mcx_opt_selling_margin_cnc"] = mcxOptSellingMarginCnc;
        }
      } else {
        for (let i = 0; i < mcxSymbols.length; i++) {
          if (
            mcxMarginFix[mcxSymbols[i]["symbol"] + "_margin_mis"] === "" ||
            mcxMarginFix[mcxSymbols[i]["symbol"] + "_margin_cnc"] === ""
          ) {
            toast.error(`${mcxSymbols[i]["symbol"]} margin required`);
            setExchange("mcx");
            return;
          } else {
            data[mcxSymbols[i]["symbol"] + "_margin_mis"] =
              mcxMarginFix[mcxSymbols[i]["symbol"] + "_margin_mis"];
            data[mcxSymbols[i]["symbol"] + "_margin_cnc"] =
              mcxMarginFix[mcxSymbols[i]["symbol"] + "_margin_cnc"];
          }
        }
      }
      data = {
        ...data,
        // jwttoken: loginState.jwttoken,
        name: name,
        equity_margin_mis: equityMarginMis,
        equity_margin_cnc: equityMarginCnc,
        equity_opt_buying_margin_mis: equityOptBuyingMarginMis,
        equity_opt_buying_margin_cnc: equityOptBuyingMarginCnc,
        equity_opt_selling_margin_mis: equityOptSellingMarginMis,
        equity_opt_selling_margin_cnc: equityOptSellingMarginCnc,
        cds_margin_mis: cdsMarginMis,
        cds_margin_cnc: cdsMarginCnc,
        eqtfx_margin_mis: fxMarginMis,
        eqtfx_margin_cnc: fxMarginCnc,
        prpcrypto_margin_mis: cryptoMarginMis,
        prpcrypto_margin_cnc: cryptoMarginCnc,
        futus_margin_mis: usMarginMis,
        futus_margin_cnc: usMarginCnc,
        mcx_margin_type: mcxMarginType,
        optiontype: selectedOption,
      };

      console.log("datadatadatadatadatadatadatadatadatadatadata margin-templates",data)
      // api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      // let res = await api.post(CREATE_MARGIN_URL, data);
      // if (res.data.status === "SUCCESS") {
      //   toast.success("Template created sucessfully");
      // } else {
      //   toast.error(res.data.message);
      // }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <Fragment>
      <Container fluid={true} className="datatables">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="p-b-0 mb-3 pt-4">
                <div className="row g-4 align-items-center">
                  <div className="col-sm">
                    <div>
                      <h5 className="card-title mb-0">
                        Create Margin Template
                      </h5>
                    </div>
                  </div>
                  <div className="col-sm-auto">
                    <div className="row">
                      <div className="col-8">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter template name"
                          value={name}
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              /^[a-zA-Z0-9 ]*$/.test(e.target.value)
                            ) {
                              setName(e.target.value);
                            }
                          }}
                        />
                      </div>
                      <div className="col-4">
                        {(!templateId) && <button
                          type="button"
                          className="btn btn-success add-btn"
                          onClick={submitTemplateHandler}
                        >
                          Insert
                        </button>}

                        {(templateId) && <button
                          type="button"
                          className="btn btn-success add-btn"
                          onClick={updateTemplateHandler}
                        >
                          Update
                        </button>}
                      </div>
                    </div>
                  </div>
                </div>
              </CardHeader>

              <CardBody className="border-top">
                <Nav tabs className="row justify-content-between fw-semibold mb-4">
                  <NavItem className="col px-0">
                    <NavLink
                      className={`text-center ${exchange === "equity" ? "active" : ""
                        }`}
                      onClick={() => setExchange("equity")}
                    >
                      EQUITY
                    </NavLink>
                  </NavItem>
                  <NavItem className="col px-0">
                    <NavLink
                      className={`text-center ${exchange === "cds" ? "active" : ""
                        }`}
                      onClick={() => setExchange("cds")}
                    >
                      CDS
                    </NavLink>
                  </NavItem>

                  <NavItem className="col px-0">
                    <NavLink
                      className={`text-center ${exchange === "mcx" ? "active" : ""
                        }`}
                      onClick={() => setExchange("mcx")}
                    >
                      MCX
                    </NavLink>
                  </NavItem>

                  <NavItem className="col px-0">
                    <NavLink
                      className={`text-center ${exchange === "fx" ? "active" : ""
                        }`}
                      onClick={() => setExchange("fx")}
                    >
                      FX
                    </NavLink>
                  </NavItem>

                  <NavItem className="col px-0">
                    <NavLink
                      className={`text-center ${exchange === "crypto" ? "active" : ""
                        }`}
                      onClick={() => setExchange("crypto")}
                    >
                      CRYPTO
                    </NavLink>
                  </NavItem>

                  <NavItem className="col px-0">
                    <NavLink
                      className={`text-center ${exchange === "us" ? "active" : ""
                        }`}
                      onClick={() => setExchange("us")}
                    >
                      US
                    </NavLink>
                  </NavItem>
                </Nav>
                <Row>
                  {exchange === "equity" ? (
                    <>
                      <Col className="ps-0 mb-4" md={6}>
                        <Label>Equity Margin Intraday(MIS)</Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={equityMarginMis}
                          onChange={(e) => {
                            if (e.target.value >= 0 || e.target.value === "") {
                              setEquityMarginMis(e.target.value);
                            }
                          }}
                        />
                      </Col>
                      <Col className="ps-0 mb-4" md={6}>
                        <Label>Equity Margin Longterm(CNC)</Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={equityMarginCnc}
                          onChange={(e) => {
                            if (e.target.value >= 0 || e.target.value === "") {
                              setEquityMarginCnc(e.target.value);
                            }
                          }}
                        />
                      </Col>
                      <Col className="ps-0 mb-4" md={6}>
                        <Label>Equity Option Buying Margin Intraday(MIS)</Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={equityOptBuyingMarginMis}
                          onChange={(e) => {
                            if (e.target.value >= 0 || e.target.value === "") {
                              setEquityOptBuyingMarginMis(e.target.value);
                            }
                          }}
                        />
                      </Col>
                      <Col className="ps-0 mb-4" md={6}>
                        <Label>Equity Option Buying Margin Longterm(CNC)</Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={equityOptBuyingMarginCnc}
                          onChange={(e) => {
                            if (e.target.value >= 0 || e.target.value === "") {
                              setEquityOptBuyingMarginCnc(e.target.value);
                            }
                          }}
                        />
                      </Col>
                      <Col className="ps-0" md={6}>
                        <Label>
                          Equity Option Selling Margin Intraday(MIS)
                        </Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={equityOptSellingMarginMis}
                          onChange={(e) => {
                            if (e.target.value >= 0 || e.target.value === "") {
                              setEquityOptSellingMarginMis(e.target.value);
                            }
                          }}
                        />
                      </Col>
                      <Col className="ps-0" md={6}>
                        <Label>
                          Equity Option Selling Margin Longterm(CNC)
                        </Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={equityOptSellingMarginCnc}
                          onChange={(e) => {
                            if (e.target.value >= 0 || e.target.value === "") {
                              setEquityOptSellingMarginCnc(e.target.value);
                            }
                          }}
                        />
                      </Col>
                    </>
                  ) : (
                    ""
                  )}

                  {exchange === "cds" ? (
                    <>
                      <Col className="ps-0" md={6}>
                        <Label>CDS Margin Intraday(MIS)</Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={cdsMarginMis}
                          onChange={(e) => {
                            if (e.target.value >= 0 || e.target.value === "") {
                              setCdsMarginMis(e.target.value);
                            }
                          }}
                        />
                      </Col>
                      <Col className="ps-0" md={6}>
                        <Label>CDS Margin Longterm(CNC)</Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={cdsMarginCnc}
                          onChange={(e) => {
                            if (e.target.value >= 0 || e.target.value === "") {
                              setCdsMarginCnc(e.target.value);
                            }
                          }}
                        />
                      </Col>
                    </>
                  ) : (
                    ""
                  )}

                  {exchange === "mcx" ? (
                    <>
                      <Col className="mb-4 px-0" sm={12}>
                        <button
                          className={`btn ${mcxMarginType === "multiply"
                            ? "btn-primary"
                            : "btn-outline-primary"
                            }`}
                          onClick={() => {
                            setMcxMarginType("multiply");
                          }}
                        >
                          Multiply
                        </button>
                        <button
                          className={`btn ${mcxMarginType === "fix"
                            ? "btn-primary"
                            : "btn-outline-primary"
                            } mx-4`}
                          onClick={() => {
                            setMcxMarginType("fix");
                          }}
                        >
                          Fix
                        </button>
                      </Col>
                      <Collapse isOpen={mcxMarginType === "multiply"}>
                        <>
                          <Row>
                            <Col className="ps-0 mb-4" md={6}>
                              <Label>MCX Margin Intraday(MIS)</Label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter margin"
                                value={mcxMarginMis}
                                onChange={(e) => {
                                  if (
                                    e.target.value >= 0 ||
                                    e.target.value === ""
                                  ) {
                                    setMcxMarginMis(e.target.value);
                                  }
                                }}
                              />
                            </Col>
                            <Col className="ps-0 mb-4" md={6}>
                              <Label>MCX Margin Longterm(CNC)</Label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter margin"
                                value={mcxMarginCnc}
                                onChange={(e) => {
                                  if (
                                    e.target.value >= 0 ||
                                    e.target.value === ""
                                  ) {
                                    setMcxMarginCnc(e.target.value);
                                  }
                                }}
                              />
                            </Col>
                            <Col className="ps-0 mb-4" md={6}>
                              <Label>MCX Option Buying Margin Intraday(MIS)</Label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter margin"
                                value={mcxOptBuyingMarginMis}
                                onChange={(e) => {
                                  if (e.target.value >= 0 || e.target.value === "") {
                                    setMcxOptBuyingMarginMis(e.target.value);
                                  }
                                }}
                              />
                            </Col>
                            <Col className="ps-0 mb-4" md={6}>
                              <Label>MCX Option Buying Margin Longterm(CNC)</Label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter margin"
                                value={mcxOptBuyingMarginCnc}
                                onChange={(e) => {
                                  if (e.target.value >= 0 || e.target.value === "") {
                                    setMcxOptBuyingMarginCnc(e.target.value);
                                  }
                                }}
                              />
                            </Col>
                            <Col className="ps-0" md={6}>
                              <Label>
                                MCX Option Selling Margin Intraday(MIS)
                              </Label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter margin"
                                value={mcxOptSellingMarginMis}
                                onChange={(e) => {
                                  if (e.target.value >= 0 || e.target.value === "") {
                                    setMcxOptSellingMarginMis(e.target.value);
                                  }
                                }}
                              />
                            </Col>
                            <Col className="ps-0" md={6}>
                              <Label>
                                MCX Option Selling Margin Longterm(CNC)
                              </Label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter margin"
                                value={mcxOptSellingMarginCnc}
                                onChange={(e) => {
                                  if (e.target.value >= 0 || e.target.value === "") {
                                    setMcxOptSellingMarginCnc(e.target.value);
                                  }
                                }}
                              />
                            </Col>
                          </Row>
                        </>
                      </Collapse>
                    </>
                  ) : (
                    ""
                  )}

                  {exchange === "fx" ? (
                    <>
                      <Col className="ps-0" md={6}>
                        <Label>FX Margin Intraday(MIS)</Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={fxMarginMis}
                          onChange={(e) => {
                            if (e.target.value >= 0 || e.target.value === "") {
                              setFxMarginMis(e.target.value);
                            }
                          }}
                        />
                      </Col>
                      <Col className="ps-0" md={6}>
                        <Label>FX Margin Longterm(CNC)</Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={fxMarginCnc}
                          onChange={(e) => {
                            if (e.target.value >= 0 || e.target.value === "") {
                              setFxMarginCnc(e.target.value);
                            }
                          }}
                        />
                      </Col>
                    </>
                  ) : (
                    ""
                  )}


                  {exchange === "crypto" ? (
                    <>
                      <Col className="ps-0" md={6}>
                        <Label>CRYPTO Margin Intraday(MIS)</Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={cryptoMarginMis}
                          onChange={(e) => {
                            if (e.target.value >= 0 || e.target.value === "") {
                              setCryptoMarginMis(e.target.value);
                            }
                          }}
                        />
                      </Col>
                      <Col className="ps-0" md={6}>
                        <Label>CRYPTO Margin Longterm(CNC)</Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={cryptoMarginCnc}
                          onChange={(e) => {
                            if (e.target.value >= 0 || e.target.value === "") {
                              setCryptoMarginCnc(e.target.value);
                            }
                          }}
                        />
                      </Col>
                    </>
                  ) : (
                    ""
                  )}


                  {exchange === "us" ? (
                    <>
                      <Col className="ps-0" md={6}>
                        <Label>US Margin Intraday(MIS)</Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={usMarginMis}
                          onChange={(e) => {
                            if (e.target.value >= 0 || e.target.value === "") {
                              setUsMarginMis(e.target.value);
                            }
                          }}
                        />
                      </Col>
                      <Col className="ps-0" md={6}>
                        <Label>US Margin Longterm(CNC)</Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter margin"
                          value={usMarginCnc}
                          onChange={(e) => {
                            if (e.target.value >= 0 || e.target.value === "") {
                              setUsMarginCnc(e.target.value);
                            }
                          }}
                        />
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ScriptTable
          exchange={exchange}
          mcxMarginType={mcxMarginType}
          mcxSymbols={mcxSymbols}
          mcxMarginFix={mcxMarginFix}
          setMcxMarginFix={setMcxMarginFix}
          onSelectedOptionChange = {handleSelectedOptionChange}
        />
      </Container>
    </Fragment>
  );
};

export default GeneralComponent;
