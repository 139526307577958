import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { Star } from "react-feather";
import { MENUITEMS } from "../../../Sidebar/Menu";
import { LI, UL } from "../../../../AbstractElements";
import CustomContext from "../../../../_helper/Customizer";
import { useSelector } from "react-redux";
import ChangePasswordModal from "./ChangePasswordModal";

const AccountBox = () => {
  // eslint-disable-next-line
  const loginState = useSelector((state) => state.login);
  const [changePasswordModalStatus, setChangePasswordModalStatus] =
    useState(false);
  const [mainMenu, setMainMenu] = useState(MENUITEMS);
  const [searchIcon, setSearchIcon] = useState(false);
  const { setIsClose } = useContext(CustomContext);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState(false); // eslint-disable-next-line
  const [bookmarkSearch, SetBookmarkSearch] = useState(false);
  const [bookmarkItems, setBookmarkItems] = useState([]);
  const [bookmarkDropDown, setBookmarkDropDown] = useState(false);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setSearchValue("");
      setSearchResult([]);
      SetBookmarkSearch(false);
      setIsClose(false);
    } // eslint-disable-next-line
  }, []);
  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    mainMenu.map((menuItems) => {
      menuItems.Items.filter((items) => {
        if (items.bookmark) {
          setBookmarkItems((bookmarkItems) => [...bookmarkItems, items]);
        }
        return items;
      });
      return menuItems;
    });
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [mainMenu, escFunction]);
  const addFix = () => {
    setIsClose(true);
  };
  const removeFix = useCallback(() => {
    setSearchValue("");
    setIsClose(false);
  }, [setIsClose]);
  const checkSearchResultEmpty = (items) => {
    if (!items.length) {
      setSearchIcon(true);
    } else {
      setSearchIcon(false);
    }
  };
  const handleSearchKeyword = (keyword) => {
    keyword ? addFix() : removeFix();
    const items = [];
    setSearchValue(keyword);
    mainMenu.map((menuItems) => {
      menuItems.Items.filter((Items) => {
        if (
          Items.title.toLowerCase().includes(keyword) &&
          Items.type === "link"
        ) {
          items.push(Items);
        }
        if (!Items.children) return false;
        Items.children.filter((subItems) => {
          if (
            subItems.title.toLowerCase().includes(keyword) &&
            subItems.type === "link"
          ) {
            subItems.icon = Items.icon;
            items.push(subItems);
          }
          if (!subItems.children) return false;
          subItems.children.filter((suSubItems) => {
            if (suSubItems.title.toLowerCase().includes(keyword)) {
              suSubItems.icon = Items.icon;
              items.push(suSubItems);
            }
            return suSubItems;
          });
          return subItems;
        });
        setSearchResult(items);
        checkSearchResultEmpty(items);
        return Items;
      });
      return menuItems;
    });
  };
  const setCallbackBookmark = useCallback((valueAdd) => {
    setBookmarkItems(valueAdd);
  }, []);
  return (
    <Fragment>
      <ChangePasswordModal
        changePasswordModalStatus={changePasswordModalStatus}
        setChangePasswordModalStatus={setChangePasswordModalStatus}
      />
      <LI attrLI={{ className: "onhover-dropdown" }}>
        <div
          className="notification-box"
          onClick={() => setBookmarkDropDown(!bookmarkDropDown)}
        >
          <Star />
        </div>
        <div className={`onhover-show-div bookmark-flip`}>
          <div className="flip-card">
            <div className="flip-card-inner">
              <div>
                <div className="card-header pt-2 mt-2">
                  <h4 className="fw-semibold">{loginState.owner.username}</h4>
                </div>
                {(loginState.owner.type !== "super" && loginState.owner.type !== undefined) && (
                  <div className="card-body py-2 mb-2 f-16">
                    <div className="py-2 fw-semibold border-bottom">
                      <small>INR BALANCE</small>{" "}
                      <i className="icon-wallet text-success f-16"></i>{" "}
                      <span>{loginState.owner.user_relation?.deposit}</span>
                    </div>
                    <div className="py-2 fw-semibold border-bottom">
                      <small>USD BALANCE</small>{" "}
                      <i className="icon-wallet f-16 text-secondary f-16"></i>{" "}
                      <span>{loginState.owner.user_relation?.deposit_usd}</span>
                    </div>
                    {/* <div className="py-2 fw-semibold border-bottom">
                      <small>Rented</small>{" "}
                      <i className="icon-wallet f-16 text-danger f-16"></i>{" "}
                      <span>{loginState.owner.userRelation?.rented_wallet}</span>
                    </div> */}
                  </div>
                )}
                <div className="card-footer py-2 mb-3">
                  <button
                    className="btn btn-outline-secondary"
                    onClick={() => {
                      setChangePasswordModalStatus(true);
                    }}
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LI>
    </Fragment>
  );
};

export default AccountBox;
