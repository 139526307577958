import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import arrowupward from "../../../../assets/images/arrow_upward.svg";
import cancelred from "../../../../assets/images/cancel_red.svg";
import { CREATE_USER_URL, FETCH_TEMPLATES_URL, FETCH_USER_URL } from '../../../../_helper/url_helper';
import api from "../../../../_helper/api_helper";
import { toast } from "react-toastify";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CreateUser = () => {
  const loginState = useSelector((state) => state.login);
  const formikRef = useRef(null);
 
  const initialValues = {
    firstname: "",
    lastname: "",
    username: "",
    password: "",
    status: "",
    type: "",
    client_limit: "",
    id_charge_type: "",
    id_charge: "0",
    profit_loss_share_type: "",
    profit_loss_share: "",
    brokerage_share: "",
    brokerage_template_id: "",
    email: "",
    contact: "",
    // brokertheme: "",
  };

  const phoneRegExp = /^[0-9]{10}$/;
  const emailRegex = /^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  const validationSchema = Yup.object({
    firstname: Yup.string().required("First Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    username: Yup.string().required("Username is required"),
    // email: Yup.string().email("Invalid email format").required("Email is required"),
    email: Yup.string()
      .matches(emailRegex, "Please enter a valid email address")
      .email("Invalid email format")
      .required("Email is required"),
    contact: Yup.string()
      .matches(phoneRegExp, 'Phone number must be 10 digits long')
      .required('Phone number is required'),
    password: Yup.string().required("Password is required"),
    // contact: Yup.string().required("Contact is required"),
    type: Yup.string().required("User Type is required"),
    status: Yup.string().required("Status is required"),
    client_limit: Yup.number().positive("Client Limit must be a positive number").required("Client Limit is required"),
    id_charge: Yup.number().positive("Per ID Charge must be a positive number").required("Per ID Charge is required"),
    id_charge_type: Yup.string().required("Per ID Charge Type is required"),
    profit_loss_share_type: Yup.string().required("Profit/Loss Share Type is required"),
    profit_loss_share: Yup.string().required("Profit/Loss Share is required"),
    brokerage_share: Yup.string().required("Brokerage Share is required"),
    brokerage_template_id: Yup.string().required("Brokerage Template is required"),
    // brokertheme: Yup.string().required("Broker Theme is required"),
  });

  const handleonSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      const res = await api.post(CREATE_USER_URL, values);

      if (res.status === 200) {
        toast.success("User created successfully!");
        resetForm();
      } else {
        toast.error("Failed to create user. Please try again.");
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error("An error occurred while creating the user.");
    } finally {
      setSubmitting(false);
    }
  };

  const [brokerageTemplates, setBrokerageTemplates] = useState([]);

  console.log("brokeragetemplatebrokeragetemplatebrokeragetemplatebrokeragetemplate",brokerageTemplates);

  async function fetchBrokerageTemplatesHandler() {
    try {
      // let data = {
      //   jwttoken: loginState.jwttoken,
      // };
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_TEMPLATES_URL);
      if (res.data.status === "SUCCESS") {       
        setBrokerageTemplates(res.data.payload);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }


 

  useEffect(()=>{
    fetchBrokerageTemplatesHandler()
  },[])

  // const showTheme=()=>{
  //   console.log("brokerthemebrokerthemebrokertheme");
  // }

  const navigate = useNavigate();

  return (
    <>
      <div className="container containerdiv ">
          <div className="filterdiv" style={{padding:"5px"}} >
              <div className="filtertitle">
                  <h2 className='mb-0'>Broker Templates</h2>
              </div>
              <div className="filteranddate">
                  <button type="button" className="btn me-2" style={{ backgroundColor: "transparent", color: "red", border: "0", }} onClick={()=>navigate("/users")} > <img src={cancelred} alt="Cancel" /> Cancel </button>
                  <button type="submit" className="btn btn-success" onClick={() => formikRef.current.submitForm()}> Create </button>
              </div>
          </div>           
      </div>
      
      <div className="container titleheaderdiv mt-2">
        <div className="card tabcard">
          <div className="card-body p-2"> 
            <div className="tab-content">
              <Formik 
                innerRef={formikRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleonSubmit}
              >
                {({ isSubmitting }) => (
                  <Form className="mt-4">
                    <div className="row mt-3">
                      <div className="col-md-6">
                          <label htmlFor="firstName">First Name *</label>
                          <Field type="text" name="firstname" className="form-control" placeholder="Enter first name" />
                          <ErrorMessage name="firstname" component="div" className="text-danger"/>
                      </div>
                      <div className="col-md-6">
                          <label>Last Name *</label>
                          <Field type="text" name="lastname" className="form-control" placeholder="Enter last name" />
                          <ErrorMessage name="lastname" component="div" className="text-danger" />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                          <label>Username *</label>
                          <Field type="text" name="username" className="form-control" placeholder="Enter user name" />
                          <ErrorMessage name="username" component="div" className='text-danger' />
                      </div>
                      <div className="col-md-6">
                          <label>Email *</label>
                          <Field type="email" name="email" className="form-control" placeholder="Enter email" />
                          <ErrorMessage name="email" component="div" className='text-danger' />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                          <label>Contact</label>
                          <Field type="text" name="contact" className="form-control" placeholder="Enter contact" />
                          <ErrorMessage name="contact" component="div" className='text-danger' />
                      </div>
                      <div className="col-md-6">
                          <label>Password *</label>
                          <Field type="password" name="password" className="form-control" placeholder="Enter password" />
                          <ErrorMessage name="password" component="div" className='text-danger' />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                          <label>User Type *</label>
                          <Field as="select" name="type" className="form-control">
                            <option value="">Select</option>
                            <option value="broker">Broker</option>
                            <option value="admin">Admin</option>
                          </Field>
                          <ErrorMessage name="type" component="div" className='text-danger' />
                      </div>
                      <div className="col-md-6">
                          <label>Status *</label>
                          <Field as="select" name="status" className="form-control">
                            <option value="">Select</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </Field>
                          <ErrorMessage name="status" component="div" className='text-danger' />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                          <label>Client Limit *</label>
                          <Field type="number" name="client_limit" className="form-control" placeholder="Enter client limit" />
                          <ErrorMessage name="client_limit" component="div" className='text-danger' />
                      </div>
                      <div className="col-md-6">
                          <label>Per ID Charge Type *</label>
                          <Field as="select" name="id_charge_type" className="form-control">
                            <option value="">Select</option>
                            {/* <option value="enable">Enabled</option>
                            <option value="disable">Disabled</option> */}
                            <option value="disable">Disable</option>
                            <option value="fix">Fix</option>
                            <option value="id">Per ID</option>
                          </Field>
                          <ErrorMessage name="id_charge_type" component="div" className='text-danger' />
                          
                      </div>
                    </div> 
                    <div className="row mt-3">
                      <div className="col-md-6">
                          <label>Per ID Charge *</label>
                          <Field type="number" name="id_charge" className="form-control" placeholder="Enter per ID charge" />
                          <ErrorMessage name="id_charge" component="div" className='text-danger' />
                      </div>
                      <div className="col-md-6">
                          <label>Profit/Loss Share (%) Type *</label>
                          <Field as="select" name="profit_loss_share_type" className="form-control">
                            <option value="">Select</option>
                            <option value="with_brokerage">With Brokerage</option>
                            <option value="without_brokerage"> Brokerage Template </option>
                            <option value="brokerage_share"> Different Brokerage Percentage </option>
                          </Field>
                          <ErrorMessage name="profit_loss_share_type" component="div" className='text-danger'/>                          
                      </div>
                    </div> 
                    <div className="row mt-3">
                      <div className="col-md-6">
                          <label>Profit/Loss Share (%) *</label>
                          <Field type="text" name="profit_loss_share" placeholder="Enter percentage" className="form-control"/>
                          <ErrorMessage name="profit_loss_share" component="div" className='text-danger' />                          
                      </div>
                      <div className="col-md-6">
                          <label>Brokerage Template *</label>
                          <Field as="select" name="brokerage_template_id" className="form-control">
                            <option key= "0" value="0">Select template</option>                            
                            {brokerageTemplates.map((value) => {
                              return (
                                <option value={value.id}>{value.name}</option>
                              );
                            })}
                          </Field>
                          <ErrorMessage name="brokerage_template_id" component="div" className='text-danger'/>
                      </div>                      
                    </div> 
                    <div className="row mt-3">
                    <div className="col-md-6">
                          <label>Brokerage Share *</label>
                          <Field type="text" name="brokerage_share" placeholder="Enter brokerage share" className="form-control"/>
                          <ErrorMessage name="brokerage_share" component="div" className='text-danger'/>
                      </div>
                      {/* <div className="col-md-6">
                          <label>Broker Theme *</label>                         
                          <Field as="select" name="brokertheme" className="form-control">
                            <option value="">Select theme</option>
                            <option value="theme">Theme 1</option>
                            <option value="theme2">Theme 2</option>
                            <option value="theme3">Theme 2</option>
                          </Field>
                          <ErrorMessage name="brokertheme" component="div" className='text-danger'/>
                      </div>   */}
                    </div>                                          
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
    
  );
};

export default CreateUser;
