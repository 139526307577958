import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./Scriptlotsetting.css";
import arrowupward from "../../../assets/images/arrow_upward.svg";
import cancelred from "../../../assets/images/cancel_red.svg";
import api from "../../../_helper/api_helper";
import { LOT_SCRIPT_GET_URL, LOT_SCRIPT_POST_URL } from "../../../_helper/url_helper";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const Scriptlotsettings = () => {
  const [activeTab, setActiveTab] = useState("nfo");
  const loginState = useSelector((state) => state.login);
  const [initialValues, setInitialValues] = useState({
    min_lot_single_trade: 0,
    max_lot_single_trade: 0,
    min_lot_per_script: 0,
    max_lot_per_script: 0,
  });

  const validationSchema = Yup.object({
    min_lot_single_trade: Yup.number()
      .required("Min Lot Trade is required")
      .min(0, "Min Lot Trade must be at least 0"),
    max_lot_single_trade: Yup.number()
      .required("Max Lot Trade is required")
      .min(0, "Max Lot Trade must be at least 0"),
    min_lot_per_script: Yup.number()
      .required("Min Lot Per Script is required")
      .min(0, "Min Lot Per Script must be at least 0"),
    max_lot_per_script: Yup.number()
      .required("Max Lot Per Script is required")
      .min(0, "Max Lot Per Script be at least 0"),
  });

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const getAllScriptLotSetting = async () => {
      try {
        api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
        const res = await api.post(`${LOT_SCRIPT_GET_URL}`);
        const data = res?.data?.payload;

        if (data && data[activeTab]) {
          setInitialValues({
            min_lot_single_trade: data[activeTab][`min_lot_${activeTab}_single_trade`] || 0,
            max_lot_single_trade: Number(data[activeTab][`max_lot_${activeTab}_single_trade`]).toFixed(0) || 0,
            min_lot_per_script: data[activeTab][`min_lot_${activeTab}_per_script`] || 0,
            max_lot_per_script: Number(data[activeTab][`max_lot_${activeTab}_per_script`]).toFixed(0) || 0,
          });
        }
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message || "An error occurred");
        } else {
          toast.error("An error occurred");
        }
      }
    };

    getAllScriptLotSetting();
  }, [activeTab, loginState.jwttoken]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const formData = { ...values, exchange: activeTab };
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      const res = await api.post(`${LOT_SCRIPT_POST_URL}`, formData);
      if (res.data.status === "SUCCESS") {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message || "An error occurred");
      } else {
        toast.error("An error occurred");
      }
    }

    setSubmitting(false);
    resetForm();
  };

  return (
    <>
      <div className="container titleheaderdiv mt-4">
        <div className="card mb-0">
          <div className="card-body p-2">
            <h5 className="card-title mb-0">Lot Script Settings</h5>
          </div>
        </div>
      </div>

      <div className="container titleheaderdiv mt-2">
        <div className="card tabcard">
          <div className="card-body p-2">
            <div className="tabdiv">
              <ul className="nav nav-tabs">
                {["nfo", "cds", "mcx", "fx", "crypto", "us"].map((tab) => (
                  <li className="nav-item" key={tab}>
                    <a
                      className={`nav-link ${activeTab === tab ? "active" : ""}`}
                      href={`#${tab}`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleTabChange(tab);
                      }}
                    >
                      {tab.toUpperCase()}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="tab-content">
              <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} >
                {({ isSubmitting }) => (
                  <Form className="mt-4">
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <label htmlFor="min_lot_single_trade">Min Lot In Single Trade</label>
                        <Field type="text" name="min_lot_single_trade" className="form-control" placeholder="Min Lot in single Trade" onInput={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ""))} />
                        <ErrorMessage name="min_lot_single_trade" component="div" className="text-danger" />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="max_lot_single_trade">Max Lot In Single Trade</label>
                        <Field type="text" name="max_lot_single_trade" className="form-control" placeholder="Max Lot in single Trade" onInput={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ""))} />
                        <ErrorMessage name="max_lot_single_trade" component="div" className="text-danger" />
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-md-6">
                        <label htmlFor="min_lot_per_script">Min Lot Per Script</label>
                        <Field type="text" name="min_lot_per_script" className="form-control" placeholder="Min Lot Per Script" onInput={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ""))} />
                        <ErrorMessage name="min_lot_per_script" component="div" className="text-danger" />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="max_lot_per_script">Max Lot Per Script</label>
                        <Field type="text" name="max_lot_per_script" className="form-control" placeholder="Max Lot Per Script" onInput={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ""))} />
                        <ErrorMessage name="max_lot_per_script" component="div" className="text-danger" />
                      </div>
                    </div>

                    <div className="mt-4">
                      <button type="button" className="btn me-2" style={{ backgroundColor: "transparent", color: "red", border: "0", }} > <img src={cancelred} alt="Cancel" /> Cancel </button>
                      <button type="submit" className="btn btn-success" disabled={isSubmitting} > <img src={arrowupward} alt="Save" style={{ transform: "rotate(320deg)" }} />{" "} Save </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Scriptlotsettings;
