import React, { useEffect, useState } from 'react'
import dollericon from '../../../assets/images/dollericon.svg'
import inricon from '../../../assets/images/inricon.svg'
import cryptoforexicon from '../../../assets/images/crypto.svg'
import forexicon from '../../../assets/images/forex.svg'
import { Col, Row } from 'reactstrap'
import Customselect from './Customselect'
import { toast } from 'react-toastify'
import { fetchOwner } from '../../../store/login/action'
import { useDispatch, useSelector } from 'react-redux'
import { FETCH_DASHBOARD_DATA_URL_TOTAL_BROKERAGEUSED, FETCH_DASHBOARD_DATA_URLTOTAL_MARGIN_USED,} from '../../../_helper/url_helper'
import api from "../../../_helper/api_helper";
import { parseDateRange } from '../../../_helper/dateFormate'
import BeatLoader from 'react-spinners/BeatLoader'

const Brokeragecomp = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const dispatch        = useDispatch();
    const loginState      = useSelector((state) => state.login);
    const [brokeragedata, setDataBrokerage] = useState([]);
    const [margindata, setMarginData] = useState([]);
    const [loading,setLoading] = useState(false);
    useEffect(() => {
        fetchDataHandlerbrokerage();
        fetchDataHandlemargin()
        dispatch(fetchOwner({ jwttoken: loginState.jwttoken }));
    }, []);

    

    useEffect(() => {
        if (loginState.owner.status === "inactive") {
        localStorage.removeItem("jwttoken");
        window.location.href = "/";
        }
    }, [loginState]);  

    const handleOptionSelect = (option) => {
        setSelectedOption(option); // Update the state
        fetchDataHandlerbrokerage(option); // Call the fetch function
        // fetchDataHandlertrad(option)
    };

    const handleOptionSelecttrade = (option) => {
        setSelectedOption(option); // Update the state       
        fetchDataHandlemargin(option)
    }; 
    
    async function fetchDataHandlerbrokerage(option) {
        let jwttoken = loginState.jwttoken;
        const formattedDates = parseDateRange(option?.value);
        const startDate = formattedDates?.startDate;
        const endDate = formattedDates?.endDate;
        setLoading(true)
        try {
            api.defaults.headers.common["Authorization"] = `Bearer ${jwttoken}`;
            // let data = { jwttoken: jwttoken };
            let dataObj = {
                jwttoken: jwttoken,
                filter: option?.value, // Use the passed option directly
            };
    
            if (option?.label === "custom" || option?.label === "Custom") {
                dataObj.filter = 'custom'; // For custom, include dates
                dataObj.start_date = startDate;
                dataObj.end_date = endDate;
            }
            api.defaults.headers.common["Authorization"] = `Bearer ${jwttoken}`;
            const res = await api.post(FETCH_DASHBOARD_DATA_URL_TOTAL_BROKERAGEUSED, dataObj);    
            if (res.data.status === "SUCCESS") {
                setDataBrokerage(res.data.payload);
            } else {
                toast.error(res.data.message);
            }

            setLoading(false)
            
        } catch (error) {
            toast.error(`Error: ${error.message}`);
            setLoading(false)
        }
    }

    async function fetchDataHandlemargin(option) {
        const jwttoken = loginState.jwttoken;
    
        // Compute the startDate and endDate based on the selected option
        const formattedDates = parseDateRange(option?.value);
        const startDate = formattedDates?.startDate;
        const endDate = formattedDates?.endDate;
        setLoading(true)
        try {
            let dataObj = {
                jwttoken: jwttoken,
                filter: option?.value, // Use the passed option directly
            };
    
            if (option?.label === "custom" || option?.label === "Custom") {
                dataObj.filter = 'custom'; // For custom, include dates
                dataObj.start_date = startDate;
                dataObj.end_date = endDate;
            }
    
                
            api.defaults.headers.common["Authorization"] = `Bearer ${jwttoken}`;
            const res = await api.post(FETCH_DASHBOARD_DATA_URLTOTAL_MARGIN_USED, dataObj);    
            if (res.data.status === "SUCCESS") {
                setMarginData(res.data.payload);
            } else {
                toast.error(res.data.message);
            }
            setLoading(false)
        } catch (error) {
            toast.error(error.message);
            setLoading(false)
        }
    }

    // async function fetchDataHandler() {
    //     let jwttoken = loginState.jwttoken
    //     try {
    //         let data = {
    //             jwttoken: jwttoken,
    //         };
    //         api.defaults.headers.common["Authorization"] = `Bearer ${jwttoken}`;
            
    //         // let res = await api.post(FETCH_DASHBOARD_DATA_URL_TOTAL_BROKERAGEUSED, data);
            
    //         // if (res.data.status === "SUCCESS") {
    //         //     setDataBrokerage(res.data.payload);
    //         // } else {
    //         //     toast.error(res.data.message);
    //         // }
    //         const [res1, res2] = await Promise.all([
    //             api.post(FETCH_DASHBOARD_DATA_URL_TOTAL_BROKERAGEUSED, data),
    //             api.post(FETCH_DASHBOARD_DATA_URLTOTAL_MARGIN_USED, data),
    //         ]);
    //         if (res1.data.status === "SUCCESS") {
    //             setDataBrokerage(res1.data.payload);
    //         } else {
    //             toast.error(`API 1 Error: ${res1.data.message}`);
    //         }
    //         if (res2.data.status === "SUCCESS") {
    //             setMarginData(res2.data.payload);
    //         } else {
    //             toast.error(`API 2 Error: ${res2.data.message}`);
    //         }
    //     } catch (error) {
    //         toast.error(error.message);
    //     }
    // }

    // const handleOptionSelect = (option) => {
    //     setSelectedOption(option);
    // };    
    
    // const formattedDates = parseDateRange(selectedOption?.value);
    // const startDate = formattedDates?.startDate;
    // const endDate = formattedDates?.endDate;
  return (
    <>
         <div className="rowhalfcoldiv">
            <div className="halfcoldiv">
                <div className="p-2">
                < div className="d-flex gap-3 justify-content-between align-items-center" >
                    <div>
                        <h4>Total Brokerage Used</h4>
                        <p>Your Brokerage</p>
                    </div>
                    <div>
                        <Customselect onOptionSelect={handleOptionSelect}/>
                    </div>
                </div>                   
                <Row className="mt-3">
                    <Col md="3">
                        <div className="iconaligndivmain">
                            <span className="iconaligndiv">
                                <div className="icondiv" style={{background:"#883dcf2e"}}>
                                    <img src={dollericon} alt=""/>
                                </div>                                  
                                <p>US <small className={brokeragedata?.us >= 0 ? 'profitcolor' : 'losscolor'}> 30%<i className={`fa ${ brokeragedata?.us >= 0 ? "fa-caret-up" : "fa-caret-down" }`}></i></small></p>
                            </span>
                            {/* <h4>${brokeragedata?.us} </h4> */}
                            {loading? <><BeatLoader color="rgba(37, 146, 213, 1)" /></>:<h4>{brokeragedata?.us} </h4>}
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="iconaligndivmain">
                            <span className="iconaligndiv">
                                <div className="icondiv" style={{background:"#f867262e"}}>
                                    <img src={inricon} alt=""/>
                                </div>                                  
                                <p>Indian <small className={brokeragedata?.indian >= 0 ? 'profitcolor' : 'losscolor'}> 10% <i className={`fa ${ brokeragedata?.indian >= 0 ? "fa-caret-up" : "fa-caret-down" }`}></i></small></p>
                            </span>
                            {/* <h4>&#8377;{brokeragedata?.indian}</h4> */}
                            {loading? <><BeatLoader color="rgba(37, 146, 213, 1)" /></>:<h4>{brokeragedata?.indian}</h4>}
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="iconaligndivmain">
                            <span className="iconaligndiv">
                                <div className="icondiv" style={{background:"#48bcfe2e"}}>
                                    <img src={forexicon} alt="" />
                                </div>
                                <p>Forex <small className={brokeragedata?.forex >= 0 ? 'profitcolor' : 'losscolor'}> 14%<i className={`fa ${ brokeragedata?.forex >= 0 ? "fa-caret-up" : "fa-caret-down" }`}></i></small></p>
                            </span>
                            {/* <h4>${brokeragedata?.forex}</h4> */}
                            {loading? <><BeatLoader color="rgba(37, 146, 213, 1)" /></>:<h4>{brokeragedata?.forex}</h4>}
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="iconaligndivmain">
                            <span className="iconaligndiv">
                                <div className="icondiv" style={{background:"#32cdb42e"}}>
                                    <img src={cryptoforexicon} alt="" />
                                </div>
                                <p>Crypto <small className={brokeragedata?.crypto >= 0 ? 'profitcolor' : 'losscolor'}> 14%<i className={`fa ${ brokeragedata?.crypto >= 0 ? "fa-caret-up" : "fa-caret-down" }`}></i></small></p>
                            </span>
                            {/* <h4>${brokeragedata?.crypto} </h4> */}
                            {loading? <><BeatLoader color="rgba(37, 146, 213, 1)" /></>:<h4>{brokeragedata?.crypto} </h4>}
                        </div>
                    </Col>
                    </Row> 
                </div>                     
            </div>
            <div className="halfcoldiv">
                <div className="p-2">
                <div div className="gap-3 d-flex justify-content-between align-items-center" >
                    <div>
                        <h4>Total Margin Used</h4>
                        <p>Your Brokerage</p>  
                    </div>
                    <div>
                        <Customselect onOptionSelect={handleOptionSelecttrade}/> 
                    </div>
                </div>
                
                <Row className="mt-3">
                    <Col md="3">
                        <div className="iconaligndivmain">
                            <span className="iconaligndiv">
                                <div className="icondiv" style={{background:"#883dcf2e"}}>
                                    <img src={dollericon} alt=""/>
                                </div>                                  
                                <p>US <small className={margindata?.US >= 0 ? 'profitcolor' : 'losscolor'}> 10% <i className={`fa ${ margindata?.US >= 0 ? "fa-caret-up" : "fa-caret-down" }`}></i></small></p>
                            </span>
                            {/* <h4 className="ml-2">${margindata?.US}</h4> */}
                            {loading? <><BeatLoader color="rgba(37, 146, 213, 1)" /></>:<h4 className="ml-2">{margindata?.US}</h4>}
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="iconaligndivmain">
                            <span className="iconaligndiv">
                                <div className="icondiv" style={{background:"#f867262e"}}>
                                    <img src={inricon} alt=""/>
                                </div>                                  
                                <p>Indian <small className={margindata?.INDIAN >= 0 ? 'profitcolor' : 'losscolor'}>20%<i className={`fa ${ margindata?.INDIAN >= 0 ? "fa-caret-up" : "fa-caret-down" }`}></i></small></p>
                            </span>
                            {/* <h4>&#8377;{margindata?.INDIAN}</h4> */}
                            {loading? <><BeatLoader color="rgba(37, 146, 213, 1)" /></>:<h4>{margindata?.INDIAN}</h4>}
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="iconaligndivmain">
                            <span className="iconaligndiv">
                                <div className="icondiv" style={{background:"#48bcfe2e"}}>
                                    <img src={forexicon} alt="" />
                                </div>
                                <p>Forex <small className={margindata?.FOREX >= 0 ? 'profitcolor' : 'losscolor'}> 14 %<i className={`fa ${ margindata?.FOREX >= 0 ? "fa-caret-up" : "fa-caret-down" }`}></i></small></p>
                            </span>
                            {/* <h4>${margindata?.FOREX} </h4> */}
                            {loading? <><BeatLoader color="rgba(37, 146, 213, 1)" /></>:<h4>{margindata?.FOREX} </h4>}
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="iconaligndivmain">
                            <span className="iconaligndiv">
                                <div className="icondiv" style={{background:"#32cdb42e"}}>
                                    <img src={cryptoforexicon} alt="" />
                                </div>
                                <p>Crypto <small className={margindata?.CRYPTO >= 0 ? 'profitcolor' : 'losscolor'}>10% <i className={`fa ${ margindata?.CRYPTO >= 0 ? "fa-caret-up" : "fa-caret-down" }`}></i></small></p>
                            </span>
                            {/* <h4>${margindata?.CRYPTO} </h4> */}
                            {loading? <><BeatLoader color="rgba(37, 146, 213, 1)" /></>:<h4>{margindata?.CRYPTO} </h4>}
                        </div>
                    </Col>
                    </Row>
                </div>
            </div>
        </div> 
    </>
  )
}

export default Brokeragecomp