import React, { useEffect, useState } from "react";
import CustomFilter from "../TransactionAnalysis/CustomFilter";
import Paginationcomp from "../TransactionAnalysis/Paginationcomp";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import {
  DELETE_CLIENT_URL,
  DOWNLOAD_CLIENT_CSV_URL,
  FETCH_ALL_UNDER_CLIENTS_URL
} from "../../../_helper/url_helper";
import actioncopy from "../../../assets/images/action-copy.svg";
import actionedit from "../../../assets/images/action-edit.svg";
import actiondelete from "../../../assets/images/action-delete.svg";
import CustomSelect from "../Dashboard/Customselect";
import BeatLoader from "react-spinners/BeatLoader";
import { parseDateRange } from "../../../_helper/dateFormate";

const GeneralComponent = () => {
  const navigate = useNavigate();
  const loginState = useSelector((state) => state.login);

  const [allData, setAllData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 1,
    totalRows: 0,
  });
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [filters, setFilters] = useState({
    userName: "",
    usernameType:"",
    status: "all",
    contactNo: "",
  });

  const [filterOptions] = useState({
    statusOptions: ["active", "inactive", "pending"],
  });
  const [selectedOption,setSelectedOption]=useState(null)
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAllUsers(pagination.current, filters);
  }, [pagination.current, resultsPerPage, filters]);

  


  const fetchAllUsers = async (page = 1, appliedFilters = filters,option) => {
    try {
      setLoading(true);
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      const formattedDates=parseDateRange(option?.value)
      const startDate=formattedDates?.startDate;
      const endDate=formattedDates?.endDate;

      console.log("filtersfiltersfiltersfiltersfilters",appliedFilters.usernameType);

      const params = {
        page,
        per_page: resultsPerPage,
        username: appliedFilters.userName || undefined,
        usernameType: appliedFilters.usernameType || undefined,
        status: appliedFilters.status !== "all" ? appliedFilters.status : undefined,
        contact: appliedFilters.contactNo || undefined,
        
      };
      params.filter=option?.value
      if(option?.label==="custom"||option?.label==="Custom"){
        params.filter='custom'
        params.start_date=startDate
        params.end_date=endDate
      }


      console.log("Fetching with params:", params);
      const res = await api.get(FETCH_ALL_UNDER_CLIENTS_URL, { params });
      console.log("API Response:", res.data);

      if (res.data.status === "SUCCESS") {
        setAllData(res.data.payload?.data || []);
        setPagination({
          current: res.data.payload.current_page,
          total: res.data.payload.last_page,
          totalRows: res.data.payload.total,
        });
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
      toast.error("Error fetching clients");
    } finally {
      setLoading(false);
    }
  };

  const downloadClientsCsvHandler = async () => {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      const res = await api.get(DOWNLOAD_CLIENT_CSV_URL, { responseType: "blob" });

      const blob = new Blob([res.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "clients.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.error("Error downloading CSV");
    }
  };

  const deleteClientHandler = async (clientId) => {
    try {
      if (!window.confirm("Are you sure?")) {
        return;
      }

      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      const res = await api.post(DELETE_CLIENT_URL, { client_id: clientId });

      if (res.data.status === "SUCCESS") {
        fetchAllUsers();
        toast.success("Client Deleted");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleFilterChange = (option) => {
   setSelectedOption(option);
   fetchAllUsers(1,filters,option)
  };

  return (
    <div>
      <div className="containerdiv">
        <div className="filterdiv" style={{ padding: "5px" }}>
          <div className="filtertitle">
            <h2 className="mb-0">
              Clients{" "}
              <span className="badge badge-primary" style={{ fontSize: "12px" }}>
                {allData.length}
              </span>
            </h2>
          </div>
          <div className="filteranddate">
            <CustomSelect
              onOptionSelect={handleFilterChange}
            />
            <CustomFilter statusOptions={filterOptions.statusOptions} onSubmit={setFilters} />
            <button className="btn btn-secondary me-2" onClick={downloadClientsCsvHandler}>
              Download
            </button>
            <button className="btn btn-primary" onClick={() => navigate("/client/create")}>
              <i className="fa fa-plus"></i> Create
            </button>
          </div>
        </div>
      </div>

      <div className="containerdiv p-0" style={{ background: "transparent" }}>
        <div className="table-responsive mt-4">
          <table className="table table-bordered">
            <thead className="table-secondary">
              <tr>
                <td>ID</td>
                <td>NAME</td>
                <td>USERNAME</td>
                <td>BROKER</td>
                <td>LEDGER</td>
                <td>USD LEDGER</td>
                <td>IS DEMO</td>
                <td>STATUS</td>
                <td>ACTIONS</td>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="9" className="text-center">
                    <BeatLoader color="rgba(37, 146, 213, 1)" />
                  </td>
                </tr>
              ) : allData.length === 0 ? (
                <tr>
                  <td colSpan="9" className="text-center">No data available.</td>
                </tr>
              ) : (
                allData.map((txn, index) => (
                  <tr key={txn?.id}>
                    <td>{Number(pagination.current-1)*Number(resultsPerPage)+index+1}</td>
                    <td>{txn?.firstname} {txn?.lastname}</td>
                    <td>{txn?.username}</td>
                    <td>{txn?.broker}</td>
                    <td>{txn?.client_account?.balance}</td>
                    <td>{txn?.client_account?.balance_usd}</td>
                    <td>{txn?.client_account?.is_demo === "yes" ? "Demo" : "Real"}</td>
                    <td>{txn.status}</td>
                    <td className="d-flex gap-1">
                      <button title="update" className="btn btn-sm btn-secondary p-0 d-flex justify-content-center align-items-center fw-semibold" style={{ width: "30px", height: "30px" }} onClick={() => navigate(`/client/Update/${txn.id}`)} > <i className="fa fa-pencil"></i> </button>
                      <button title="Copy" className="btn btn-sm btn-info p-0 d-flex justify-content-center align-items-center fw-semibold" style={{ width: "30px", height: "30px" }} onClick={() => navigate(`/client/Copy/${txn.id}`)} > <i className="fa fa-copy"></i> </button>
                      <button className="btn btn-sm btn-secondary p-0 d-flex justify-content-center align-items-center fw-semibold" style={{ width: "30px", height: "30px" }} onClick={() => deleteClientHandler(txn.id)}><i className="fa fa-trash-o"></i></button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <Paginationcomp
            currentPage={pagination.current}
            totalPages={pagination.total}
            onPageChange={(page) => fetchAllUsers(page)}
            resultsPerPage={resultsPerPage}    
            totalResults = {pagination.totalRows}        
            onResultsPerPageChange={(value) => {
                setResultsPerPage(value);
                fetchAllUsers(1, filters);
            }}
        />
      </div>
    </div>
  );
};

export default GeneralComponent;
