import React, { Fragment } from "react";
import { Col, Container, Row } from "reactstrap";
import LoginForm from "./LoginForm";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const loginState = useSelector((state) => state.login);
  if (loginState.jwttoken !== null) {
    window.location.href="/";
  }
  return (
    <Fragment>
      <Container fluid={true} className="p-0">
        <Row>
          <Col>
            <LoginForm />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Login;
