export const parseDateRange = (dateRange) => {
    if (dateRange && dateRange.includes(" - ")) {
        const [start, end] = dateRange.split(" - ");
        const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
        };
    
        return { startDate: formatDate(start), endDate: formatDate(end) };
    } else {
        return { startDate: null, endDate: null };
    }
};

export const convertToIndianTime=(timestamp)=>{
    const date = new Date(timestamp);
    date.setMinutes(date.getMinutes() + 330); // UTC se IST shift karne ke liye +5:30 add karna padega

    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Month 0-based hota hai
    const dd = String(date.getDate()).padStart(2, '0');
    const hh = String(date.getHours()).padStart(2, '0');
    const min = String(date.getMinutes()).padStart(2, '0');
    const ss = String(date.getSeconds()).padStart(2, '0');

    return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
  }