import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  Label,
  Input,
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { LI, UL } from "../../../AbstractElements";
import {
  FETCH_GLOBAL_SETTING_URL,
  PM2_RESTART_ALL_URL,
  UPDATE_GLOBAL_OTO_KITE,
  UPDATE_GLOBAL_SETTING_URL,
} from "../../../_helper/url_helper";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";

export default function GeneralComponent() {
  const loginState = useSelector((state) => state.login);
  const [mcxTrading, setMcxTrading] = useState(true);
  const [equityTrading, setEquityTrading] = useState(true);
  const [cdsTrading, setCdsTrading] = useState(true);
  const [usTrading, setUsTrading] = useState(true);
  const [fxTrading, setFxTrading] = useState(true);
  const [cryptoTrading, setCryptoTrading] = useState(true);
  const [cancelPendingOrderMins, setCancelPendingOrderMins] = useState("");
  const [bankniftyStrikePoints, setBankNiftyStrikePoints] = useState("");
  const [niftyStrikePoints, setNiftyStrikePoints] = useState("");
  const [nfoStrikePercentage, setNfoStrikePercentage] = useState("");
  const [mcxStrikePercentage, setMcxStrikePercentage] = useState("");
  const [tradeAutoCloseOn, setTradeAutoCloseOn] = useState("");
  const [lossNotificationOn, setLossNotificationOn] = useState("");
  const [lastBillGenerateTime, setLastBillGenerateTime] = useState("");
  const [secondLastBillGenerateDateTime, setSecondLastBillGenerateDateTime] =
    useState("");
  const [autoSquareSystemOnLedger, setAutoSquareSystemOnLedger] = useState(true);
  const [kiteotp,setKiteOtp] = useState();

  useEffect(() => {
    fetchGlobalSettingHandler();
  }, []);

  function isInt(value) {
    var x = parseFloat(value);
    return !isNaN(value) && (x | 0) === x;
  }

console.log("apiapiapiapiapiapiapiapiapiapi",api.baseUrl);

  function setIntValue(value, setFun) {
    if ((isInt(value) || value === "") && value !== "0") {
      setFun(value);
    }
  }

  async function pm2RestartALlHandler() {
    try {
      let data = {
        jwttoken: loginState.jwttoken,
      };
      let res = await api.post(PM2_RESTART_ALL_URL, data);
      if (res.data.status === 0) {
        toast.error(res.data.message);
      } else {
        toast.success("Success");
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  

  async function fetchGlobalSettingHandler() {
    try {
      // let data = {
      //   jwttoken: loginState.jwttoken,
      // };
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_GLOBAL_SETTING_URL);
      if (res.data.status === "SUCCESS") {
        setMcxTrading(res.data.payload.mcx_trading === "on");
        setEquityTrading(res.data.payload.equity_trading === "on");
        setCdsTrading(res.data.payload.cds_trading === "on");
        setUsTrading(res.data.payload.us_trading === "on");
        setCryptoTrading(res.data.payload.crypto_trading === "on");
        setFxTrading(res.data.payload.fx_trading === "on");
        setCancelPendingOrderMins(res.data.payload.cancel_pending_order_mins);
        setBankNiftyStrikePoints(res.data.payload.banknifty_options_strike_points);
        setNiftyStrikePoints(res.data.payload.nifty_options_strike_points);
        setNfoStrikePercentage(
          res.data.payload.nfo_stock_options_strike_percentage
        );
        setMcxStrikePercentage(
          res.data.payload.mcx_stock_options_strike_percentage
        );
        setTradeAutoCloseOn(res.data.payload.trade_auto_close_on);
        setLossNotificationOn(res.data.payload.loss_notification_on);
        setLastBillGenerateTime(res.data.payload.last_bill_generate_date_time);
        setSecondLastBillGenerateDateTime(
          res.data.payload.second_last_bill_generate_date_time
        );
        setAutoSquareSystemOnLedger(res.data.payload.auto_square_system_on_ledger === "on");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function updateGlobalSettingHandler() {
    try {
      if (cancelPendingOrderMins === "") {
        toast.error("Cancel pending order in mins required");
        return;
      }
      if (bankniftyStrikePoints === "") {
        toast.error("Banknifty options Strike points required");
        return;
      }
      if (niftyStrikePoints === "") {
        toast.error("Nifty options Strike points required");
        return;
      }
      if (nfoStrikePercentage === "") {
        toast.error("NFO Stock options Strike percentage required");
        return;
      }
      if (mcxStrikePercentage === "") {
        toast.error("MCX Stock options Strike percentage required");
        return;
      }
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let data = {
        // jwttoken: loginState.jwttoken,
        mcx_trading: mcxTrading ? "on" : "off",
        equity_trading: equityTrading ? "on" : "off",
        cds_trading: cdsTrading ? "on" : "off",
        us_trading: usTrading ? "on" : "off",
        crypto_trading: cryptoTrading ? "on" : "off",
        fx_trading: fxTrading ? "on" : "off",
        auto_square_system_on_ledger: autoSquareSystemOnLedger ? "on" : "off",
        cancel_pending_order_mins: cancelPendingOrderMins,
        banknifty_options_strike_points: bankniftyStrikePoints,
        nifty_options_strike_points: niftyStrikePoints,
        nfo_stock_options_strike_percentage: nfoStrikePercentage,
        mcx_stock_options_strike_percentage: mcxStrikePercentage,
        trade_auto_close_on: tradeAutoCloseOn,
        loss_notification_on: lossNotificationOn
      };
      let res = await api.post(UPDATE_GLOBAL_SETTING_URL, data);
      if (res.data.status ===  "SUCCESS") {
        toast.success("Settings updated");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  const updateKiteOtp = async (e) => {    
    e.preventDefault();
    if (!kiteotp) {
        toast.error("Kite OTP cannot be empty");
        return;
    }
    const data = { access_key: kiteotp };
    try {
        api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
        const res = await api.post(UPDATE_GLOBAL_OTO_KITE, data);
        if (res?.data?.status === "SUCCESS") {
            toast.success("Kite OTP Updated");
            setKiteOtp("")
        } else {
            toast.error(res?.data?.message || "Failed to update Kite OTP");
            setKiteOtp("")
        }

    } catch (error) {
        console.error("Error updating Kite OTP:", error);
        toast.error(error?.response?.data?.message || "An error occurred while updating OTP");
        setKiteOtp("")
    }
  };


  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardHeader className="py-3 border-bottom">
              <div className="row g-4 align-items-center">
                <div className="col">
                  <div>
                    <h5 className="card-title mb-0">Settings</h5>
                  </div>
                </div>
                <div className="col col-auto">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={updateGlobalSettingHandler}
                  >
                    Save
                  </button>
                </div>
              </div>
            </CardHeader>
            <div className="ui-list">
              <ul className="list-group" style={{ borderRadius: "0px" }}>
                <LI
                  attrLI={{
                    className:
                      "d-flex justify-content-between align-items-center",
                  }}
                >
                  <span className=" fw-semibold">MCX trading</span>{" "}
                  <Label className="switch">
                    <Input
                      type="checkbox"
                      checked={mcxTrading}
                      onChange={() => {
                        setMcxTrading(!mcxTrading);
                      }}
                    />
                    <span className="switch-state"></span>
                  </Label>
                </LI>

                <LI
                  attrLI={{
                    className:
                      "d-flex justify-content-between align-items-center",
                  }}
                >
                  <span className=" fw-semibold">EQUITY trading</span>{" "}
                  <Label className="switch">
                    <Input
                      type="checkbox"
                      checked={equityTrading}
                      onChange={() => {
                        setEquityTrading(!equityTrading);
                      }}
                    />
                    <span className="switch-state"></span>
                  </Label>
                </LI>

                <LI
                  attrLI={{
                    className:
                      "d-flex justify-content-between align-items-center",
                  }}
                >
                  <span className=" fw-semibold">CDS trading</span>{" "}
                  <Label className="switch">
                    <Input
                      type="checkbox"
                      checked={cdsTrading}
                      onChange={() => {
                        setCdsTrading(!cdsTrading);
                      }}
                    />
                    <span className="switch-state"></span>
                  </Label>
                </LI>

                <LI
                  attrLI={{
                    className:
                      "d-flex justify-content-between align-items-center",
                  }}
                >
                  <span className=" fw-semibold">FX trading</span>{" "}
                  <Label className="switch">
                    <Input
                      type="checkbox"
                      checked={fxTrading}
                      onChange={() => {
                        setFxTrading(!fxTrading);
                      }}
                    />
                    <span className="switch-state"></span>
                  </Label>
                </LI>


                <LI
                  attrLI={{
                    className:
                      "d-flex justify-content-between align-items-center",
                  }}
                >
                  <span className=" fw-semibold">CRYPTO trading</span>{" "}
                  <Label className="switch">
                    <Input
                      type="checkbox"
                      checked={cryptoTrading}
                      onChange={() => {
                        setCryptoTrading(!cryptoTrading);
                      }}
                    />
                    <span className="switch-state"></span>
                  </Label>
                </LI>

                <LI
                  attrLI={{
                    className:
                      "d-flex justify-content-between align-items-center",
                  }}
                >
                  <span className=" fw-semibold">US trading</span>{" "}
                  <Label className="switch">
                    <Input
                      type="checkbox"
                      checked={usTrading}
                      onChange={() => {
                        setUsTrading(!usTrading);
                      }}
                    />
                    <span className="switch-state"></span>
                  </Label>
                </LI>


                <LI
                  attrLI={{
                    className:
                      "d-flex justify-content-between align-items-center",
                  }}
                >
                  <span className=" fw-semibold">Auto Square System on Ledger</span>{" "}
                  <Label className="switch">
                    <Input
                      type="checkbox"
                      checked={autoSquareSystemOnLedger}
                      onChange={() => {
                        setAutoSquareSystemOnLedger(!autoSquareSystemOnLedger);
                      }}
                    />
                    <span className="switch-state"></span>
                  </Label>
                </LI>

                <LI
                  attrLI={{
                    className:
                      "d-flex justify-content-between align-items-center",
                  }}
                >
                  <span className=" fw-semibold">
                    Cancel pending order in mins
                  </span>{" "}
                  <span className="d-block">
                    <input
                      type="number"
                      className="form-control fw-semibold"
                      placeholder="Enter Points"
                      value={cancelPendingOrderMins}
                      onChange={(e) => {
                        setIntValue(e.target.value, setCancelPendingOrderMins);
                      }}
                    />
                  </span>
                </LI>

                <LI
                  attrLI={{
                    className:
                      "d-flex justify-content-between align-items-center",
                  }}
                >
                  <span className=" fw-semibold">
                    Banknifty options Strike points
                  </span>{" "}
                  <span className="d-block">
                    <input
                      type="text"
                      className="form-control fw-semibold"
                      placeholder="Enter Points"
                      value={bankniftyStrikePoints}
                      onChange={(e) => {
                        setIntValue(e.target.value, setBankNiftyStrikePoints);
                      }}
                    />
                  </span>
                </LI>

                <LI
                  attrLI={{
                    className:
                      "d-flex justify-content-between align-items-center",
                  }}
                >
                  <span className=" fw-semibold">
                    Nifty options Strike points
                  </span>{" "}
                  <span className="d-block">
                    <input
                      type="text"
                      className="form-control fw-semibold"
                      placeholder="Enter Points"
                      value={niftyStrikePoints}
                      onChange={(e) => {
                        setIntValue(e.target.value, setNiftyStrikePoints);
                      }}
                    />
                  </span>
                </LI>

                <LI
                  attrLI={{
                    className:
                      "d-flex justify-content-between align-items-center",
                  }}
                >
                  <span className=" fw-semibold">
                    NFO Stock options Strike percentage
                  </span>{" "}
                  <span className="d-block">
                    <input
                      type="text"
                      className="form-control fw-semibold"
                      placeholder="Enter Percentage"
                      value={nfoStrikePercentage}
                      onChange={(e) => {
                        setIntValue(e.target.value, setNfoStrikePercentage);
                      }}
                    />
                  </span>
                </LI>
                <LI
                  attrLI={{
                    className:
                      "d-flex justify-content-between align-items-center",
                  }}
                >
                  <span className=" fw-semibold">
                    MCX Stock options Strike percentage
                  </span>{" "}
                  <span className="d-block">
                    <input
                      type="text"
                      className="form-control fw-semibold"
                      placeholder="Enter Percentage"
                      value={mcxStrikePercentage}
                      onChange={(e) => {
                        setIntValue(e.target.value, setMcxStrikePercentage);
                      }}
                    />
                  </span>
                </LI>

                <LI
                  attrLI={{
                    className:
                      "d-flex justify-content-between align-items-center",
                  }}
                >
                  <span className=" fw-semibold">
                    Trade Auto Close On
                  </span>{" "}
                  <span className="d-block">
                    <input
                      type="text"
                      className="form-control fw-semibold"
                      placeholder="Enter Percentage"
                      value={tradeAutoCloseOn}
                      onChange={(e) => {
                        setIntValue(e.target.value, setTradeAutoCloseOn);
                      }}
                    />
                  </span>
                </LI>

                <LI
                  attrLI={{
                    className:
                      "d-flex justify-content-between align-items-center",
                  }}
                >
                  <span className=" fw-semibold">
                    Loss Notification On
                  </span>{" "}
                  <span className="d-block">
                    <input
                      type="text"
                      className="form-control fw-semibold"
                      placeholder="Enter Percentage"
                      value={lossNotificationOn}
                      onChange={(e) => {
                        setIntValue(e.target.value, setLossNotificationOn);
                      }}
                    />
                  </span>
                </LI>

                <LI
                  attrLI={{
                    className:
                      "d-flex justify-content-between align-items-center",
                  }}
                >
                  <span className=" fw-semibold">
                    Last Bill generate date time
                  </span>{" "}
                  <span className="d-block">
                    <input
                      type="text"
                      className="form-control fw-semibold"
                      placeholder="Enter Percentage"
                      value={lastBillGenerateTime}
                      readOnly
                    />
                  </span>
                </LI>

                <LI
                  attrLI={{
                    className:
                      "d-flex justify-content-between align-items-center",
                  }}
                >
                  <span className=" fw-semibold">
                    Second Last Bill generate date time
                  </span>{" "}
                  <span className="d-block">
                    <input
                      type="text"
                      className="form-control fw-semibold"
                      placeholder="Enter Percentage"
                      value={secondLastBillGenerateDateTime}
                      readOnly
                    />
                  </span>
                </LI>

                {(loginState.owner.type === "super") && <LI
                  attrLI={{
                    className:
                      "d-flex justify-content-between align-items-center",
                  }}
                >
                  <span className=" fw-semibold">
                    Restart Price
                  </span>{" "}
                  <span className="d-block">
                    <button class="btn btn-sm btn-secondary-gradien" onClick={pm2RestartALlHandler}>pm2 restart all</button>
                  </span>
                </LI>}
              </ul>
            </div>
            
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
            <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center">
                      <div>
                          <p><b>Update Kite OTP</b></p>
                      </div>
                      <div className="d-flex gap-2">
                          {/* <input type="text" name="kiteotp" onChange={(e)=>setKiteOtp(e.target.value)}/> */}
                          <input type="text" className="form-control" name="kiteotp" value={kiteotp} onChange={(e) => setKiteOtp(e.target.value)} autoComplete="off" placeholder="Enter Kite OTP" />
                          <button onClick={(e)=>updateKiteOtp(e)} className="btn btn-success">Update</button>
                      </div>
                  </div>
                </CardBody>
              </Card>
        </Col>
      </Row>
    </>
  );
}
