import React, { useEffect, useState } from 'react'
import CustomSelect from '../Dashboard/Customselect'
import CustomFilter from '../TransactionAnalysis/CustomFilter'
import actioncopy from '../../../assets/images/action-copy.svg'
import actionedit from '../../../assets/images/action-edit.svg'
import actiondelete from '../../../assets/images/action-delete.svg'
import Paginationcomp from '../TransactionAnalysis/Paginationcomp'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FETCH_CLOSE_SCRIPT_URL} from '../../../_helper/url_helper'
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import BeatLoader from "react-spinners/BeatLoader"
import { FaCalendarAlt } from 'react-icons/fa'

const GeneralComponent = () => {
   
  const navigate = useNavigate();
  const loginState = useSelector((state) => state.login);

  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [totalValue, setTotalValue] = useState({});
  const [pagination, setPagination] = useState({ current: 1, total: 1, totalRows: 0 });
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({ userName: '', status: 'all', contactNo: '' });

  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [totalvalueclosed,setAlltotalClosed] = useState(); 

  const marketOptions = [
    { value: 'mcx', label: 'MCX' },
    { value: 'nfo', label: 'NFO' },
    { value: 'fx', label: 'FOREX' },
    { value: 'us', label: 'US' },
    { value: 'crypto', label: 'CRYPTO' },
    { value: 'cds', label: 'CDS' }
  ];

  useEffect(() => {
    fetchUsersHandler();
  }, [filters, selectedOption]);

async function fetchUsersHandler() {
  try {
      setLoading(true);
      let params = {};
      if (selectedOption?.value) {
          params.market = selectedOption.label;
      }
      if (filters.type) {
          params.type = filters.type;
      }
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_CLOSE_SCRIPT_URL, { params });

      if (res.data.status === "SUCCESS") {
          setAllData(res.data.payload.scripts);
          setFilteredData(res.data.payload.scripts);
          setTotalValue(res.data.payload);
          setPagination({
              current: 1,
              total: Math.ceil(res.data.payload.scripts.length / resultsPerPage),
              totalRows: res.data.payload.scripts.length
          });
      } else {
          toast.error(res.data.message);
      }
  } catch (error) {
      toast.error(error.message);
  } finally {
      setLoading(false);
  }
}

useEffect(() => {
  fetchUsersHandler();
}, [selectedOption, filters.type]);

  useEffect(() => {
    applyFilters();
  }, [filters]);

 
  const applyFilters = () => {
    let filtered = [...allData];
    if (filters.userName) {
        filtered = filtered.filter((user) =>
            user.username.toLowerCase().includes(filters.userName.toLowerCase())
        );
    }
    if (filters.status !== "all") {
        filtered = filtered.filter((user) => user.status === filters.status);
    }
    if (filters.contactNo) {
        filtered = filtered.filter((user) =>
            user.contact_no.includes(filters.contactNo)
        );
    }
    if (filters.buy) {
        filtered = filtered.filter((item) => item.active_buy > 0);  
    }
    if (filters.sell) {
        filtered = filtered.filter((item) => item.active_sell > 0);
    }
    setFilteredData(filtered);
    setPagination((prev) => ({
        ...prev,
        current: 1,
        total: Math.ceil(filtered.length / resultsPerPage),
        totalRows: filtered.length
    }));
  };

  const getCurrentPageData = () => {
    const startIndex = (pagination.current - 1) * resultsPerPage;
    return filteredData.slice(startIndex, startIndex + resultsPerPage);
  };

  const setPage = (page) => {
    if (page >= 1 && page <= pagination.total) {
      setPagination((prev) => ({ ...prev, current: page }));
    }
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

 
const selectOption = (option) => {
  setSelectedOption(option);
  setIsOpen(false);
};

  const handleResultsPerPageChange = (newResultsPerPage) => {
    setResultsPerPage(newResultsPerPage);
    setPagination((prev) => ({
      ...prev,
      current: 1,
      total: Math.ceil(filteredData.length / newResultsPerPage)
    }));
  };

   console.log("totalValuetotalValuetotalValuetotalValue",totalValue);
  
  return (
        <>
            <div className="containerdiv ">
                <div className="filterdiv" style={{padding:"5px"}} >
                    <div className="filtertitle">
                        <h2 className='mb-0'>Closed Position</h2>
                    </div>
                    <div className="filteranddate">
                      <button className={`btn ${filters.type === "buy" ? "btn-success" : "btn-outline-success"}`} onClick={() => setFilters((prev) => ({ ...prev, type: prev.type === "buy" ? "" : "buy" }))} > Buy </button>
                      <button className={`btn ${filters.type === "sell" ? "btn-danger" : "btn-outline-danger"}`} onClick={() => setFilters((prev) => ({ ...prev, type: prev.type === "sell" ? "" : "sell"}))} > Sell </button>
                      <div className="custom-selectnew">
                        <div className={`select-selected ${isOpen ? 'select-arrow-active' : ''}`} onClick={toggleDropdown} >
                          {selectedOption ? selectedOption.label : 'Market'}
                          <span className="dropdown-icon" style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', pointerEvents: 'none' }} > ▼ </span>
                        </div>
                        {isOpen && (
                          <div className="select-items">
                            {marketOptions.map((option) => (
                              <div
                                key={option.value}
                                onClick={() => selectOption(option)}
                                className={`select-item ${
                                  selectedOption?.value === option.value ? 'same-as-selected' : ''
                                }`}
                              >
                                {option.label}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                </div>           
            </div>
            <div className="containerdiv p-0"  style={{background:"transparent"}}>
                <div className="table-responsive mt-4">
                    <table className="table table-bordered">
                    <thead className="table-secondary">
                        <tr>
                            <th>#</th>
                            <th>SCRIPT</th>
                            {/* <th>Close Time</th> */}
                            <th>Market</th>
                            <th>Brokerage</th>
                            <th>BuyQty</th>
                            <th>SellQty</th>
                            <th>Avg Buy Rate</th>
                            <th>Avg Sell Rate</th>
                            <th>Profit / Loss</th>                            
                            <th>Net P/L</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                        {loading
                          ? <tr>
                              <td colSpan="9"><div className="d-flex justify-content-center align-items-center"><BeatLoader color="rgba(37, 146, 213, 1)" /></div></td>
                            </tr>
                          : filteredData.length === 0
                          ? (
                            <tr>
                              <td colSpan="9" className="text-center">
                                No data available for the selected filters.
                              </td>
                            </tr>
                          )
                          : getCurrentPageData().map((txn,index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{txn.trading_symbol}</td> 
                                <td>{txn.exchange}</td>   
                                <td>{Number(txn.brokerage).toFixed(2)}</td>                              
                                <td>{Number(txn.active_buy).toFixed(2)}</td>
                                <td>{Number(txn.active_sell).toFixed(2)}</td>
                                <td>{Number(txn.avg_buy_price).toFixed(2)}</td> 
                                <td>{Number(txn.avg_sell_price).toFixed(2)}</td>                                 
                                <td>{Number(txn.profit_and_loss).toFixed(2)}</td> 
                                <td>{Number(txn.total).toFixed(2)}</td>                                 
                            </tr>
                            ))}                           
                            
                            <tr className="table-secondary">
                              <td colSpan="4"><b>Total</b></td>                              
                              <td><b>{Number(totalValue?.total_active_buy ? totalValue?.total_active_buy : 0).toFixed(2)}</b></td>
                              <td><b>{Number(totalValue?.total_active_buy ? totalValue?.total_active_buy : 0).toFixed(2)}</b></td> 
                              <td><b>{Number(totalValue.total_avg_buy ? totalValue.total_avg_buy : 0).toFixed(2)}</b></td>
                              <td><b>{Number(totalValue.total_avg_sell ? totalValue.total_avg_sell : 0).toFixed(2)}</b></td>                              
                              <td><b>{Number(totalValue?.total_profit_and_loss ? totalValue?.total_profit_and_loss : 0).toFixed(2)}</b></td> 
                              <td><b>{Number(totalValue?.total_total ? totalValue?.total_total : 0).toFixed(2)}</b></td> 
                          </tr>
                      </tbody>
                    </table>
                </div>
                {/* Pagination */}
                <Paginationcomp 
                  currentPage={pagination.current}
                  totalPages={pagination.total}
                  onPageChange={setPage}
                  resultsPerPage={resultsPerPage}
                  onResultsPerPageChange={handleResultsPerPageChange}
                />
            </div>
        </>
  )
}

export default GeneralComponent