import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Resizer from "react-image-file-resizer";
import cancelred from "../../../../assets/images/cancel_red.svg";
import arrowupward from "../../../../assets/images/arrow_upward.svg";
import { CREATE_THEME_LIST, FETCH_MARKET_LIST } from "../../../../_helper/url_helper";
import api from "../../../../_helper/api_helper";


const GeneralComponent = () => {
  const formikRef = useRef(null);
  const loginState = useSelector((state) => state.login);
  const navigate = useNavigate();
  const [singlePreviewImage, setSinglePreviewImage] = useState(null);
  const [multiplePreviewImages, setMultiplePreviewImages] = useState([]);

  // const marketdata = [
  //   { value: "market1", label: "Market 1" },
  //   { value: "market2", label: "Market 2" },
  //   { value: "market3", label: "Market 3" },
  // ];

  const initialValues = {
    name: "",
    remarks: "",
    support_markets: [],
    image: null,
    gallery_image: [],
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z0-9]*$/, "Only alphanumeric values are allowed")
      .required("Name is required"),
    remarks: Yup.string()
      .matches(/^[a-zA-Z0-9]*$/, "Only alphanumeric values are allowed")
      .required("Remarks are required"),
    support_markets: Yup.array()
      .min(1, "Please select at least one market")
      .required("Required"),
    image: Yup.mixed().required("Featured image is required"),
    gallery_image: Yup.array()
      .min(1, "Please upload at least one gallery image")
      .required("Required"),
  });
const resizeFile = (file) =>
  new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      300, // Desired width
      300, // Desired height
      "JPEG", // Output format
      100, // Quality
      0, // Rotation
      (uri) => resolve(uri),
      "base64",
      undefined,
      (err) => reject(err) // Handle resizing errors
    );
  });

const handleSingleFileChange = async (event, setFieldValue) => {
  const file = event.target.files[0];
  if (!file) return;

  try {
    const resizedImage = await resizeFile(file);
    const imageBlob = dataURItoBlob(resizedImage);

    const fileForUpload = new File([imageBlob], file.name, { type: imageBlob.type });

    setSinglePreviewImage(URL.createObjectURL(imageBlob)); // For preview purposes
    setFieldValue("image", fileForUpload); // Assign the resized file
  } catch (err) {
    toast.error("Failed to resize the image");
    console.error("Error resizing single file:", err);
  }
};

const handleMultipleFileChange = async (event, setFieldValue) => {
  const files = Array.from(event.target.files);

  if (files.length > 5) {
    toast.error("You can only upload a maximum of 5 images.");
    return;
  }

  try {
    const resizedImages = await Promise.all(
      files.map(async (file) => {
        const resizedImage = await resizeFile(file);
        const imageBlob = dataURItoBlob(resizedImage);

        return new File([imageBlob], file.name, { type: imageBlob.type });
      })
    );

    const previews = resizedImages.map((file) => URL.createObjectURL(file));

    setMultiplePreviewImages(previews); // For preview purposes
    setFieldValue("gallery_image", resizedImages); // Assign the resized files
  } catch (err) {
    toast.error("Failed to resize one or more images");
    console.error("Error resizing multiple files:", err);
  }
};

const dataURItoBlob = (dataURI) => {
  const byteString = atob(dataURI.split(",")[1]);
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};


  

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const formData = new FormData();
  
    // Append single-value fields
    formData.append("name", values.name);
    formData.append("remarks", values.remarks);
  
    // Append support_markets as a JSON string
    let idValues=values.support_markets.map(val=>val.value);
    idValues=idValues.join(',');
    formData.append("support_markets", idValues);
  
    // Append single image
    formData.append("image", values.image);
  
    // Append multiple images as gallery_image[]
    values.gallery_image.forEach((image) => {
      formData.append("gallery_image[]", image); // Important to use [] syntax for array
    });
  
    try {
      // Add JWT token to headers
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
  
      // Make the API call
      const res = await api.post(`${CREATE_THEME_LIST}`, formData);
      if (res.data.status === "SUCCESS") {
        toast.success(res.data.message);
        getAllMarketList(); // Refresh market list
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
      console.error("Error during form submission:", error);
    }
  
    setSubmitting(false);
    resetForm();
    setSinglePreviewImage(null);
    setMultiplePreviewImages([]);
  };
  

  const [marketdata,setMarketData] = useState([])
  console.log(marketdata)
  const getAllMarketList = async()=>{    
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      const res = await api.get(`${FETCH_MARKET_LIST}`);
      console.log("res=============================>",res?.data?.payload);
      if (res?.data?.status === "SUCCESS") {
        setMarketData(res?.data?.payload);
      } else {
        toast.error(res?.data.message);
      }
  }

  useEffect(()=>{
      getAllMarketList();
  },[])

  return (
    <div>
      <div className="container containerdiv">
        <div className="filterdiv" style={{ padding: "5px" }}>
          <h5 className="mb-0" onClick={() => navigate("/createtheme")} style={{ cursor: "pointer" }}>
            <i className="fa fa-arrow-left"></i> Create Theme
          </h5>
          <div className="mt-4">
            <button type="button" className="btn me-2" style={{ color: "red", border: "0" }}>
              <img src={cancelred} alt="Cancel" /> Cancel
            </button>
            <button type="button" className="btn btn-success" onClick={() => formikRef.current.submitForm()}>
              <img src={arrowupward} alt="Save" style={{ transform: "rotate(320deg)" }} /> Save
            </button>
          </div>
        </div>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form className="mt-4">
              <div className="row mb-5">
                <div className="col-md-8">
                  <label>Name</label>
                  <Field name="name" type="text" className="form-control" placeholder="Name" />
                  <ErrorMessage name="name" component="div" className="text-danger" />

                  <label>Remarks</label>
                  <Field name="remarks" type="text" className="form-control" placeholder="Remarks" />
                  <ErrorMessage name="remarks" component="div" className="text-danger" />

                  <label>Market</label>
                  {/* <Select
                    isMulti
                    options={marketdata}
                    onChange={(selectedOptions) => setFieldValue("support_markets", selectedOptions)}
                    value={values.support_markets}
                  /> */}

                  <Select
                    isMulti
                    options={marketdata.map((market) => ({
                      value: market.id,  // Set the id as the value
                      label: market.name, // Set the name as the label
                    }))}
                    onChange={(selectedOptions) =>{
                    
                      setFieldValue("support_markets", selectedOptions)
                      }}
                    value={values.support_markets}
                  />
                  <ErrorMessage name="support_markets" component="div" className="text-danger" />

                  <label>Upload Featured Image</label>
                  <input type="file" className="form-control" accept="image/*" onChange={(e) => handleSingleFileChange(e, setFieldValue)} />

                  <label>Upload Gallery Images</label>
                  <input type="file" className="form-control" multiple accept="image/*" onChange={(e) => handleMultipleFileChange(e, setFieldValue)} />
                  {/* {multiplePreviewImages.length > 0 && (
                    <div className="row mt-3">
                      {multiplePreviewImages.map((src, index) => (
                        <div key={index} className="col-md-2">
                          <img src={src} alt={`Preview ${index}`} className="img-fluid" />
                        </div>
                      ))}
                    </div>
                  )} */}
                  {multiplePreviewImages.length > 0 && (
                      <div className="row mt-3 imagepreviewmultiple">
                        {multiplePreviewImages.map((src, index) => (
                          <div key={index} className="col-md-2 imagepreviewmul">
                            <img src={src} alt={`Preview ${index}`} className="img-fluid" style={{ maxHeight: "150px",}} />
                          </div>
                        ))}
                      </div>
                    )}
                </div>
                {/* {singlePreviewImage && (
                  <div className="col-md-4 imagepreviewsingle" >
                    <img src={singlePreviewImage} alt="Single Preview" className="img-fluid" />
                  </div>
                )} */}
                <div className="col-md-4">
                    {singlePreviewImage && (
                        <div className="mt-3 imagepreviewsingle" style={{ }}>
                          <img src={singlePreviewImage} alt="Single Preview" className="img-fluid" />
                        </div>
                      )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default GeneralComponent;
