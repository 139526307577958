import React, { useEffect, useState } from 'react'
import CustomSelect from '../Dashboard/Customselect'
import CustomFilter from '../TransactionAnalysis/CustomFilter'
import actioncopy from '../../../assets/images/action-copy.svg'
import actionedit from '../../../assets/images/action-edit.svg'
import actiondelete from '../../../assets/images/action-delete.svg'
import actionsetting from '../../../assets/images/settings.svg'
import Paginationcomp from '../TransactionAnalysis/Paginationcomp'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DELETE_USER_URL, FETCH_USERS_URL } from '../../../_helper/url_helper'
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import BeatLoader from "react-spinners/BeatLoader"
import Settingmodal from './Settingmodal'

const GeneralComponent = () => {
   
    const navigate = useNavigate();
    const loginState = useSelector((state) => state.login);
    const [data, setData] = useState([]);
    const [type, setType] = useState("all");
    const [username, setUsername] = useState("");
    const [status, setStatus] = useState("all");
    const [allData, setAllData] = useState([]); // Store all data initially
    const [filteredData, setFilteredData] = useState([]); // Data after filtering     
    const [resultsPerPage, setResultsPerPage] = useState(10);
    const [pagination, setPagination] = useState({ current: 1, total: 1, totalRows: 0 });
    const [filterOptions, setFilterOptions] = useState({
      statusOptions: ["active","inactive"],
    });
    const [filters, setFilters] = useState({
        userName: "",
        status: "all",
        contactNo: "",
    });
    const [selectedOption, setSelectedOption] = useState(null);

    console.log("selectedOptionselectedOptionselectedOption",selectedOption);
    //fetch first time users
    const [loading, setLoading] = useState(false);
    useEffect(() => {
      fetchUsersHandler();
    }, []);

    useEffect(() => {
      fetchUsersHandler();
    }, [username, type, status]);

    async function deleteClientHandler(userid) {
      try {
        if (!window.confirm("Areyou sure?")) {
          return;
        }
        api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
        let data = {
          // jwttoken: loginState.jwttoken,
          user_id: userid
        };

        let res = await api.post(DELETE_USER_URL, data);
        if (res.data.status === "SUCCESS") {
          fetchUsersHandler();
          toast.success("user Deleted");
        } else {
          toast.error(res.data.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }

    // function setPage(page) {
    //   if (page < 1 || page > pagination.total) {
    //     return;
    //   }
    //   // setPagination({ ...pagination, current: page });
    //   fetchUsersHandler(page);
    // }

    const formattedDatestart = (selectedOption) => 
      selectedOption?.startDate ? new Date(selectedOption.startDate).toLocaleDateString('en-US') : null;
    
    const formattedDateend = (selectedOption) => 
      selectedOption?.endDate ? new Date(selectedOption.endDate).toLocaleDateString('en-US') : null;

    async function fetchUsersHandler(option) {
      try {
        // let data = {
        //   jwttoken: loginState.jwttoken,
        // };
        let data = {};
        if (username != "") {
          data.username = username;
        }
        if (type != "all") {
          data.type = type;
        }
        if (status != "all") {
          data.status = status;
        }
        const validOptions = ["today", "yesterday", "lastmonth", "lastyear"];
        if (option?.value && validOptions.includes(option.value)) {
            // data["date"] = option.value;
            data["date_filter"] = option.value;           
           
        }
       
        if (formattedDatestart(option)) {
            data["start_date"] = formattedDatestart(option);
            data["end_date"] = formattedDateend(option);
            data["fiter"] = "custom";
        }
        // if (formattedDateend(option)) {
        //     data["end_date"] = formattedDateend(option);
        // }        

        // data.page = page;
        setLoading(true)
        api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
        let res = await api.get(`${FETCH_USERS_URL}`, { params: data });
        if (res.data.status == "SUCCESS") {
          setAllData(res.data.payload.data); // Store the full dataset
          setFilteredData(res.data.payload.data); // Initialize the filtered data
          setPagination({
            current: 1,
            total: Math.ceil(res.data.payload.data.length / 10), // Assuming 10 items per page
            totalRows: res.data.payload.data.length,})
            setLoading(false);
          // setData(res.data.payload.data);
          // setPagination({ current: res.data.payload.current_page, total: res.data.payload.last_page, totalRows: res.data.payload.total });
        } else {
          toast.error(res.data.message);
          setLoading(false)
        }
      } catch (error) {
        toast.error(error.message);
        setLoading(false)
      }
    }

    useEffect(() => {
      applyFilters(); // Reapply filters when filter state changes
    }, [filters]);
  

    const applyFilters = () => {
      // If all filters are default, show all data
      if (
        !filters.userName &&
        filters.status === "all" &&
        !filters.contactNo
      ) {
        setFilteredData(allData);
        setPagination((prev) => ({
          ...prev,
          current: 1,
          total: Math.ceil(allData.length / resultsPerPage),
          totalRows: allData.length,
        }));
        return;
      }
    
      // Apply filters if any are active
      let filtered = [...allData];
    
      if (filters.userName) {
        filtered = filtered.filter((user) =>
          user.username.toLowerCase().includes(filters.userName.toLowerCase())
        );
      }
    
      if (filters.status !== "all") {
        filtered = filtered.filter((user) => user.status === filters.status);
      }
    
      if (filters.contactNo) {
        filtered = filtered.filter((user) =>
          user.contact_no.includes(filters.contactNo)
        );
      }
    
      setFilteredData(filtered);
    
      // Update pagination based on filtered data
      setPagination((prev) => ({
        ...prev,
        current: 1,
        total: Math.ceil(filtered.length / resultsPerPage),
        totalRows: filtered.length,
      }));
    };

    async function deleteClientHandler(userid) {
      try {
        if (!window.confirm("Areyou sure?")) {
          return;
        }
        api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
        let data = {
          // jwttoken: loginState.jwttoken,
          user_id: userid
        };
  
        let res = await api.post(DELETE_USER_URL, data);
        if (res.data.status === "SUCCESS") {
          fetchUsersHandler();
          toast.success("user Deleted");
        } else {
          toast.error(res.data.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }

    // const handleFilterSubmit = (filters) => {
    //   console.log("Filters from Child:on Broker ", filters);
    // };
    const handleFilterSubmit = (newFilters) => {
      setFilters(newFilters);
      
    };
  
    const getCurrentPageData = () => {
      const startIndex = (pagination.current - 1) * 10;
      const endIndex = startIndex + 10;
      return filteredData.slice(startIndex, endIndex);
    };
  
    const setPage = (page) => {
      if (page >= 1 && page <= pagination.total) {
        setPagination((prev) => ({ ...prev, current: page }));
      }
    };
  
    const handleResultsPerPageChange = (newResultsPerPage) => {
      setResultsPerPage(newResultsPerPage);
      setPagination((prev) => ({
        ...prev,
        current: 1,
        total: Math.ceil(filteredData.length / newResultsPerPage),
      }));
    };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [setmarketid, setMarketId] = useState("");

  // console.log("setmarketidsetmarketidsetmarketid",setmarketid);

  const openModal = (marketid) => {
    // console.log("marketidmarketidmarketid",marketid);
    setMarketId(marketid)
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOptionSelect = (option) => {
      setSelectedOption(option); // Update the state
      applyFilters(option)
      fetchUsersHandler(option);
  };
  
  return (
        <>
            <div className="containerdiv ">
                <div className="filterdiv" style={{padding:"5px"}} >
                    <div className="filtertitle">
                        <h2 className='mb-0'>Broker <span className="badge badge-primary" style={{fontSize:"12px"}}>{allData.length}</span></h2>
                    </div>
                    <div className="filteranddate">
                        <CustomSelect onOptionSelect={handleOptionSelect}/>
                        <CustomFilter  statusOptions={filterOptions.statusOptions} onSubmit={handleFilterSubmit}/>
                        <button className='btn btn-success' onClick={()=>navigate("/user/create")}> <i className="fa fa-plus"></i> Create User</button>
                    </div>
                </div>           
            </div>
            <div className="containerdiv p-0"  style={{background:"transparent"}}>
                <div className="table-responsive mt-4">
                    <table className="table table-bordered">
                    <thead className="table-secondary">
                        <tr>
                            <th>#</th>
                            <th>NAME</th>
                            <th>USERNAME</th>
                            <th>DEPOSIT INR</th>
                            <th>DEPOSIT USD</th>
                            <th>TYPE</th>
                            <th>STATUS</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                   
                    <tbody>
                        {loading
                          ? <tr>
                              <td colSpan="9"><div className="d-flex justify-content-center align-items-center"><BeatLoader color="rgba(37, 146, 213, 1)" /></div></td>
                            </tr>
                          : filteredData.length === 0
                          ? (
                            <tr>
                              <td colSpan="9" className="text-center">
                                No data available for the selected filters.
                              </td>
                            </tr>
                          )
                          : getCurrentPageData().map((txn) => (
                            <tr key={txn.id}>
                            <td>{txn.id}</td>
                            <td>{txn.firstname} {txn.lastname}</td>
                            <td>{txn.username}</td>
                            <td>{txn.user_relation.deposit}</td>
                            <td>{txn.user_relation.deposit_usd}</td> 
                            <td>{txn.type === "admin" ? <span className="text-info">admin</span> : <span >broker</span>}</td>
                            <td>
                                <span className={`badge ${ txn.status === "active" ? "bg-succesnew" : txn.status === "Pending" ? "bg-pendingnew " : "bg-failednew" }`} > {txn.status} </span>
                            </td>                               
                            <td className="actiondiv">                                
                                {/* <button className="btn btn-sm" style={{padding:"0px",border:"0px",marginRight:'6px',background:'none'}}><img src={actioncopy} width="25px"/></button> */}
                                <button className="btn btn-sm" style={{padding:"0px",border:"0px",marginRight:'6px',background:'none'}} onClick={() => navigate(`/user/update/${txn.id}`)}><img src={actionedit} width="25px"/></button>
                                <button className="btn btn-sm" style={{padding:"0px",border:"0px",marginRight:'6px',background:'none'}} onClick={() => { deleteClientHandler(txn.id) }}><img src={actiondelete} width="25px"/></button>
                                {/* <button className="btn btn-sm" style={{padding:"0px",border:"0px",marginRight:'6px',background:'none'}} onClick={() => { openModal(txn) }}><img src={actionsetting} width="25px"/></button> */}
                            </td>
                        </tr>
                            ))}
                      </tbody>
                    </table>
                </div>
                {/* Pagination */}
                <Paginationcomp 
                  currentPage={pagination.current}
                  totalPages={pagination.total}
                  onPageChange={setPage}
                  totalResults = {pagination.totalRows}
                  resultsPerPage={resultsPerPage}
                  onResultsPerPageChange={handleResultsPerPageChange}
                />
            </div>
            <Settingmodal show={isModalOpen} onClose={closeModal} setmarketid={setmarketid} title="Modal Title"/>
            
        </>
  )
}

export default GeneralComponent