import React, { Fragment, useState, useEffect } from "react";
import {
  Card,
  Container,
  Col,
  Row,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardBody
} from "reactstrap";
import { useSelector } from "react-redux";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import { APPROVE_PAYOUT_REQUESTS_URL, FETCH_PAYOUT_REQUESTS_URL, REJECT_PAYOUT_REQUESTS_URL } from "../../../_helper/url_helper";
// import { useNavigate } from "react-router-dom";

const GeneralComponent = () => {
  // const navigate = useNavigate();
  const loginState = useSelector((state) => state.login);
  const [requests, setRequests] = useState([]);
  const [payoutDataObj, setPayoutDataObj] = useState({});
  const [payoutName, setPayoutName] = useState("");
  const [userdetails,setUserDetails] = useState({});
  const [payoutDataModalStatus, setPayoutDataModalStatus] = useState(false);
  const [loadingStateRequestId, setLoadingStateRequestId] = useState(0);
  const [pagination, setPagination] = useState({ current: 1, total: 1, totalRows: 0 });

  const [filters, setFilters] = useState({ userName: '', status: 'all', contactNo: '' });
  
    // const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOption, setSelectedOption] = useState({ value: 'pending', label: 'Pending' });
    const [isOpen, setIsOpen] = useState(false);
  
    const marketOptions = [
      { value: 'pending', label: 'Pending' },
      { value: 'cancel', label: 'Cancel' },
      { value: 'complete', label: 'Complete' },
    ];

    const selectOption = (option) => {
      setSelectedOption(option);
      setIsOpen(false);
    };
    const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    fetchPayoutRequestsHandler();
  }, []);

  useEffect(() => {
    fetchPayoutRequestsHandler();
  }, [selectedOption]);

  function setPage(page) {
    if (page < 1 || page > pagination.total) {
      return;
    }
    // setPagination({ ...pagination, current: page });
    fetchPayoutRequestsHandler(page);
  }

  // async function fetchPayoutRequestsHandler() {
  //   try {
  //     api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
  //     let res = await api.get(FETCH_PAYOUT_REQUESTS_URL);
  //     if (res.data.status === "SUCCESS") {
  //       if (res.data.payload.data !== undefined) {
  //         setRequests(res.data.payload.data);
  //         setPagination({ current: res.data.payload.current_page, total: res.data.payload.last_page, totalRows: res.data.payload.total });
  //       } else {
  //         setRequests([]);
  //       }
  //     } else {
  //       toast.error(res.data.message);
  //     }
  //   } catch (error) {
  //     toast.error(error.message);
  //   }
  // }

  async function fetchPayoutRequestsHandler(page = 1) {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_PAYOUT_REQUESTS_URL, { params: { status: selectedOption.value, page } });
      
      if (res.data.status === "SUCCESS") {
        setRequests(res.data.payload.data || []);
        setPagination({ current: res.data.payload.current_page, total: res.data.payload.last_page, totalRows: res.data.payload.total });
      } else {
        setRequests([]);
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function rejectPayoutRequestsHandler(payout_request_id) {
    try {
      if (!window.confirm("Are you sure you want to reject this request?")) {
        return;
      }
      setLoadingStateRequestId(payout_request_id);
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let data = {
        // jwttoken: loginState.jwttoken,
        request_id: payout_request_id
      };
      let res = await api.post(REJECT_PAYOUT_REQUESTS_URL, data);
      if (res.data.status === "SUCCESS") {
        fetchPayoutRequestsHandler();
        toast.success("Request rejected");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoadingStateRequestId(0);
  }

  async function approvePayoutRequestsHandler(payout_request_id) {
    try {
      if (!window.confirm("Are you sure you want to approve this request?")) {
        return;
      }
      setLoadingStateRequestId(payout_request_id);
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let data = {
        request_id: payout_request_id
      };
      let res = await api.post(APPROVE_PAYOUT_REQUESTS_URL, data);
      if (res.data.status === "SUCCESS") {
        fetchPayoutRequestsHandler();
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoadingStateRequestId(0);
  }

  function setPayoutDataModal(request) {
    console.log("requestrequestrequestrequest",request);
    setUserDetails(request)
    setPayoutName(request.payout_method);
    setPayoutDataObj(JSON.parse(request.data));
    setPayoutDataModalStatus(true);
  }

  // console.log("payoutDataObjpayoutDataObjpayoutDataObj",payoutDataObj,payoutName,"Name=====");
  // console.log("requestsrequestsrequestsrequestsrequests",requests);


  function generatePayoutDataFields() {
    let data = [];
    for (const [key, value] of Object.entries(payoutDataObj)) {
      data.push(
        <> 

          <div className="row mb-2">
            <div className="col-md-4 text-start">
                <label style={{textTransform:"capitalize",fontSize:"12px"}}>{key}</label>
            </div>
            <div className="col-md-8 text-start">
                {/* <input type="text" className="form-control fw-semibold" value={value} readOnly /> */}
                <p>{value}</p>
            </div>
          </div>            
        </>        
      );
    }
    return data;
  }

  return (
    <Fragment>
      <Container fluid={true}>
        <Modal isOpen={payoutDataModalStatus} toggle={() => { setPayoutDataModalStatus(false) }} className="modal-lg" >
          <ModalHeader className="text-start">Payout {payoutName}</ModalHeader>
          <ModalBody className="fw-semibold f-16" style={{with:"100%"}}>
            <div className="row">
                <div className="col-md-6 border-end">
                    {/* User Details */}
                    <p className="mb-3 text-start"><b>User Details</b></p>
                    <div className="text-start">
                      <div className="row mb-2">
                          <div className="col-md-4 text-start">
                              <label style={{fontSize:"12px"}}>Name</label>                              
                          </div>
                          <div className="col-md-8">
                              <p>{userdetails.firstname + " " +  userdetails.lastname +" "+ (userdetails.client_name)}</p>
                          </div>
                      </div>
                      <div className="row mb-2">
                          <div className="col-md-4 text-start">
                              <label style={{fontSize:"12px"}}>Email</label>                              
                          </div>
                          <div className="col-md-8">
                              <p>{userdetails.email}</p>
                          </div>
                      </div>
                      <div className="row mb-2">
                          <div className="col-md-4 text-start">
                              <label style={{fontSize:"12px"}}>Balance INR</label>                              
                          </div>
                          <div className="col-md-8">
                              <p>{userdetails.balance}</p>
                          </div>
                      </div>
                      <div className="row mb-2">
                          <div className="col-md-4 text-start">
                              <label style={{fontSize:"12px"}}>Balance USD</label>                              
                          </div>
                          <div className="col-md-8">
                              <p>{userdetails.balance_usd}</p>
                          </div>
                      </div>
                    </div>
                </div>                  
                <div className="col-md-6 pl-2">
                    <p className="mb-3 text-start"><b>Payout Details</b></p>
                    <div>
                        {generatePayoutDataFields()} {/* Payout fields generated here */}
                    </div>                                          
                </div>
            </div>
          </ModalBody>
          <ModalFooter><button className="btn btn-dark btn-sm" onClick={() => { setPayoutDataModalStatus(false); }}>Close</button></ModalFooter>
        </Modal>

        <Row>
          <Col sm="12">
              <div className="filterdiv" style={{ padding: '5px',marginBottom:"20px" }}>
                  <div className="filtertitle">
                    <h2 className="mb-0">Payout request</h2>
                  </div>
                  <div className="filteranddate">           
                    <div className="custom-selectnew">
                      <div className={`select-selected ${isOpen ? 'select-arrow-active' : ''}`} onClick={toggleDropdown} >
                        {selectedOption ? selectedOption.label : 'Status'}
                        <span className="dropdown-icon" style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', pointerEvents: 'none' }} > ▼ </span>
                      </div>
                      {isOpen && (
                        <div className="select-items">
                          {marketOptions.map((option) => (
                            <div
                              key={option.value}
                              onClick={() => selectOption(option)}
                              className={`select-item ${
                                selectedOption?.value === option.value ? 'same-as-selected' : ''
                              }`}
                            >
                              {option.label}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
            <Card>              
              {/* <CardBody> */}
              <Table className="table-border-horizontal fw-semibold" striped>
                <thead>
                  <tr className="bg-secondary">
                    <th style={{ color: "#ffffff" }}>RID</th>
                    <th style={{ color: "#ffffff" }}>Client</th>
                    <th style={{ color: "#ffffff" }}>Amount</th>
                    <th style={{ color: "#ffffff" }}>Currency</th>
                    <th style={{ color: "#ffffff" }}>Method</th>
                    <th style={{ color: "#ffffff" }}>AddedOn</th>
                    <th style={{ color: "#ffffff" }}>Status</th>
                    <th style={{ color: "#ffffff" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {requests.length
                    ? requests.map((request,index) => {
                      return (
                        <tr key={request.id}>
                          {(Number(loadingStateRequestId) === Number(request.id)) ? <td className="text-center" colSpan={6}>Please Wait</td> :
                            <><td>{index + 1}</td>
                              <td>{request.client_name}</td>
                              <td><span className="badge badge-primary fw-semibold f-14">{request.amount}</span></td>
                              <td style={{textTransform:"uppercase"}}>{request.currency}</td>
                              <td className={request.payout_method} style={{textTransform:"uppercase"}}>{request.payout_method}</td>
                              <td>{request.added_on}</td>
                              <td style={{textTransform:"uppercase"}}>{request.status}</td>
                              <td>{actionBinder(request, setPayoutDataModal, rejectPayoutRequestsHandler, approvePayoutRequestsHandler)}</td></>}
                        </tr>
                      );
                    })
                    : <tr><td className="text-center" colSpan={6}>Requests  not found</td></tr>}

                </tbody>
              </Table>
              {/* </CardBody> */}
              <div className="pagination mt-3 d-flex justify-content-between align-items-center mb-3 px-3" >
                <div>
                  <span className="text-semibold">Page {pagination.current} out of {pagination.total}</span>
                </div>
                <div>
                  <button className="btn btn-outline-primary" onClick={() => { setPage(pagination.current - 1) }}>Prev</button>
                  <input type="number" className="form-control d-inline mx-3 text-center fw-semibold" style={{ maxWidth: '100px' }} min="1" value={pagination.current} onChange={(e) => { setPage(e.target.value) }} />
                  {/* <span>of</span> */}
                  {/* <span id="totalPages">10</span> */}
                  <button className="btn btn-outline-primary" onClick={() => { setPage(pagination.current + 1) }}>Next</button>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

function actionBinder(request, setPayoutDataModal, rejectPayoutRequestsHandler, approvePayoutRequestsHandler) {
  return (
    <div>
      <ul className="list-inline hstack gap-1 mb-0">

        <li className="list-inline-item edit">
          <button
            title="View Data"
            className="btn btn-sm btn-secondary p-0 d-flex justify-content-center align-items-center fw-semibold"
            style={{ width: "30px", height: "30px" }}
            onClick={() => { setPayoutDataModal(request) }}
          >
            V
          </button>
        </li>

        <li className="list-inline-item">
          <button
            title="Approve"
            className="btn btn-sm btn-primary p-0 d-flex justify-content-center align-items-center fw-semibold"
            style={{ width: "30px", height: "30px" }}
            onClick={() => { approvePayoutRequestsHandler(request.id) }}
          >
            A
          </button>
        </li>

        <li>
          <button
            title="Reject And Delete"
            className="btn btn-sm btn-outline-danger p-0 d-flex justify-content-center align-items-center fw-semibold"
            style={{ width: "30px", height: "30px" }}
            onClick={() => { rejectPayoutRequestsHandler(request.id) }}
          >
            R
          </button>
        </li>
      </ul>
    </div>
  );
}

export default GeneralComponent;