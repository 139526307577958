import {
  CHANGE_ORDER_WINDOW,
  CLOSE_ORDER_WINDOW,
  CHANGE_ORDER_TYPE,
  REFRESH_ACTIVE_INSTRUMENT,
} from "./actionTypes";
let initialState = {
  type: "buy",
  status: false,
  instrument:{}
};
const order = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_ORDER_WINDOW:
      return {
        instrument: action.payload.instrument,
        type: action.payload.type,
        status: true,
      };
    case CLOSE_ORDER_WINDOW:
      return {
        ...state,
        type:null,
        instrument:null,
        status: false,
      };
    case CHANGE_ORDER_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case REFRESH_ACTIVE_INSTRUMENT:
      return {
        ...state,
        instrument: action.payload,
      };
    default:
      return state;
  }
};
export default order;
