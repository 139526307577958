import React, { Fragment, useRef } from "react";
import {
  Table,
} from "reactstrap";
import { useDraggable } from "react-use-draggable-scroll";

const ExecutedOrders = (props) => {
  const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
  const { events } = useDraggable(ref);
  return (
    <Fragment>
        <div className="table-responsive" ref={ref} {...events}>
              <Table className="table-border-horizontal fw-semibold" striped>
                <thead>
                  <tr className="bg-primary">
                  <th style={{ color: "#ffffff" }}>ID</th>
                      <th style={{ color: "#ffffff" }}>Script</th>
                      <th style={{ color: "#ffffff" }}>Type</th>
                      <th style={{ color: "#ffffff" }}>OrderType</th>
                      <th style={{ color: "#ffffff" }}>Price</th>
                      <th style={{ color: "#ffffff" }}>Qty</th>
                      <th style={{ color: "#ffffff" }}>Lots</th>
                      <th style={{ color: "#ffffff" }}>IPAddress</th>
                      <th style={{ color: "#ffffff" }}>CDI</th>
                      <th style={{ color: "#ffffff" }}>Status</th>
                      <th style={{ color: "#ffffff" }}>OrderAt</th>
                  </tr>
                </thead>
                <tbody>
                  {props.client.executed_orders.length
                    ? props.client.executed_orders.map((order,) => {
                        return (
                          <tr key={order.id}>
                          <td>{order.id}</td>
                          <td>{order.trading_symbol}</td>
                          <td>
                            {order.type === "buy" ? (
                              <span className="text-success fw-semibold">
                                Buy
                              </span>
                            ) : (
                              <span className="text-danger fw-semibold">
                                Sell
                              </span>
                            )}
                          </td>
                          <td>{order.order_type}</td>
                          <td>{order.price}</td>
                          <td>{order.qty}</td>
                          <td>
                            {order.type === "buy"
                              ? parseFloat(order.lot).toFixed(2)
                              : "-" + parseFloat(order.lot).toFixed(2)}
                          </td>
                          <td>{order.from_ip_address}</td>
                          <td>{order.from_custom_device_id}</td>
                          <th>
                            {order.status === "complete" ? (
                              <span className="badge bg-success">
                                Complete
                              </span>
                            ) : order.status === "pending" ? (
                              <span className="badge bg-secondary">
                                Pending
                              </span>
                            ) : (
                              <span className="badge bg-danger">Cancel</span>
                            )}
                          </th>{" "}
                          <td className="text-nowrap">{order.added_on}</td>
                        </tr>
                        );
                      })
                    : <tr><td className="text-center" colSpan={11}>No have any order</td></tr>}
                </tbody>
              </Table>
              </div>
    </Fragment>
  );
};

export default ExecutedOrders;
