import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";

const Detail = ({ user }) => {
  return (
    <div className="table-responsive">
      <Table striped bordered>
        <tbody>
          <tr>
            <th>ID</th>
            <td>{user.userid}</td>
          </tr>

          <tr>
            <th>First Name</th>
            <td>{user.firstname}</td>
          </tr>

          <tr>
            <th>Last Name</th>
            <td>{user.lastname}</td>
          </tr>

          <tr>
            <th>User Name</th>
            <td>{user.username}</td>
          </tr>

          <tr>
            <th>Phone</th>
            <td>{user.contact}</td>
          </tr>

          <tr>
            <th>Email</th>
            <td>{user.email}</td>
          </tr>

          <tr>
            <th>Status</th>
            <td>{user.status}</td>
          </tr>

          <tr>
            <th>Type</th>
            <td>{user.type}</td>
          </tr>

          <tr>
            <th>Client Limit</th>
            <td>{user.client_limit}</td>
          </tr>

          <tr>
            <th>Per ID charge type</th>
            <td>{user.id_charge_type}</td>
          </tr>

          <tr>
            <th>Profit / loss Share (%) Type </th>
            <td>{user.profit_loss_share_type}</td>
          </tr>

          <tr>
            <th>Profit / loss Share (%) </th>
            <td>{user.profit_loss_share}</td>
          </tr>

          <tr>
            <th>Brokerage Template </th>
            <td>{user.brokerage_template_id}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default Detail;
