import React, { Fragment, useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import {
  CardBody,
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Collapse,
  Table,
} from "reactstrap";
import { Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import api from "../../../../_helper/api_helper";
import { toast } from "react-toastify";
import { FETCH_ACTIVE_SCRIPT_CLIENTS_URL } from "../../../../_helper/url_helper";
import { useNavigate, useParams } from "react-router-dom";

const GeneralComponent = () => {
  const {instrumentToken} = useParams();
  const navigate = useNavigate();
  const loginState = useSelector((state) => state.login);
  const [isOpen, setIsOpen] = useState(1);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  const [startDate, setstartDate] = useState(new Date());
  const [activeScriptClients, setActiveScriptClients] = useState([]);

  useEffect(() => {
    fetchActiveScriptClientHandler();
  },[]);

  useEffect(() => {
    let interval = setInterval(fetchActiveScriptClientHandler,20000);
    return () => clearInterval(interval);
  },[]);

  async function fetchActiveScriptClientHandler() {
    try {
      let data = {
        jwttoken: loginState.jwttoken,
        token: instrumentToken
      };
      let res = await api.post(FETCH_ACTIVE_SCRIPT_CLIENTS_URL, data);
      if (res.data.status === 1) {
        setActiveScriptClients(res.data.data);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <Fragment>
      <Container fluid={true}>
        {/* <Row>
          <Col sm="12">
            <Accordion defaultActiveKey="0">
              <div className="default-according" id="accordion1">
                <Card className="mb-4">
                  <CardHeader className="bg-primary" onClick={() => toggle(1)}>
                    <h5 className="d-flex justify-content-between">
                      <span>Filters</span>
                      {isOpen === 1 ? (
                        <i className="icofont icofont-rounded-up"></i>
                      ) : (
                        <i className="icofont icofont-rounded-down"></i>
                      )}
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={isOpen === 1}>
                    <CardBody>
                      <div className="row">
                        <div className="col">
                          <label className="col-form-label form-label">
                            Search Script
                          </label>
                          <input type="text" className="form-control" placeholder="Enter Script"/>
                        </div>

                        <div className="col">
                          <label className="col-form-label form-label">
                            View user panel
                          </label>
                          <input type="text" className="form-control" placeholder="Select Username"/>
                        </div>

                        <div className="col row align-items-end">
                          <div>
                            <button className="btn btn-danger m-t-5">
                              Reset
                            </button>
                          </div>
                        </div>
                        <div className="col"></div>
                      </div>
                    </CardBody>
                  </Collapse>
                </Card>
              </div>
            </Accordion>
          </Col>
        </Row> */}

        <Row>
          <Col sm="12">
            <Card>
              {/* <CardBody> */}
              <Table className="table-border-horizontal fw-semibold" striped>
                <thead>
                  <tr className="bg-secondary">
                  <th style={{ color: "#ffffff" }}>PID</th>
                    <th style={{ color: "#ffffff" }}>Username</th>
                    <th style={{ color: "#ffffff" }}>Instrument</th>
                    <th style={{ color: "#ffffff" }}>Type</th>
                    <th style={{ color: "#ffffff" }}>Qty</th>
                    <th style={{ color: "#ffffff" }}>Avg</th>
                    <th style={{ color: "#ffffff" }}>Used Margin</th>
                    <th style={{ color: "#ffffff" }}>Added On</th>
                    {/* <th style={{color:"#ffffff"}}>Net</th> */}
                  </tr>
                </thead>
                <tbody>
                  {activeScriptClients.length
                    ? activeScriptClients.map((script, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{script.id}</td>
                            <td>{script.username}</td>
                            <td>{script.trading_symbol}</td>
                            <td className={`${(script.type==="buy")?"text-success":"text-danger"}`}>{(script.type==="buy")?"Buy":"Sell"}</td>
                            <td className={`${(script.type==="buy")?"text-success":"text-danger"}`}>{`${(script.type==="sell")?"-":""}`+script.qty}</td>
                            <td>{script.avg_price}</td>
                            <td>{script.used_margin}</td>
                            <td>{script.added_on}</td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </Table>
              {/* </CardBody> */}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GeneralComponent;
