import React, { useEffect, useRef, useState } from 'react';
import Paginationcomp from '../TransactionAnalysis/Paginationcomp';
import actioncopy from '../../../assets/images/action-copy.svg';
import actionedit from '../../../assets/images/action-edit.svg';
import actiondelete from '../../../assets/images/action-delete.svg';
import CustomSelect from '../Dashboard/Customselect';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import arrowupward from "../../../assets/images/arrow_upward.svg";
import cancelred from "../../../assets/images/cancel_red.svg";
import { toast } from 'react-toastify';
import { CREATE_MARKET_LIST, DELETE_MARKET_LIST, FETCH_MARKET_LIST, UPDATE_MARKET_LIST } from "../../../_helper/url_helper";
import api from "../../../_helper/api_helper";
import { useSelector } from 'react-redux';

const GeneralComponent = () => {
  const formikRef = useRef(null);
  const loginState = useSelector((state) => state.login);
  const [isEditing, setIsEditing] = useState(false); // For tracking edit mode
  const [editId, setEditId] = useState(null); // For tracking the row being edited
  const [initialValues, setInitialValues] = useState({
    name: "",
    currency: "INR",
    remarks: "",
    status: "1",
  });

  const tableData = [
    { id: "id", name: "Name", currency: "INR", remarks: "Remarks", status: "Active" },
    { id: "id-2", name: "Name-2", currency: "USD", remarks: "Remarks-2", status: "Inactive" },
  ];

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z0-9]*$/, "Only alphanumeric values are allowed")
      .required("Name is required"),
    currency: Yup.string()
      .matches(/^[a-zA-Z0-9]*$/, "Only alphanumeric values are allowed")
      .required("Currency is required"),
    remarks: Yup.string()
      .matches(/^[a-zA-Z0-9]*$/, "Only alphanumeric values are allowed")
      .required("Remarks is required"),
    status: Yup.string()
      .matches(/^[a-zA-Z0-9]*$/, "Only alphanumeric values are allowed")
      .required("Status is required"),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const formData = { ...values };
    console.log("Submitted Data:", formData);
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      const res = await api.post(`${CREATE_MARKET_LIST}`, formData);
      if (res.data.status === "SUCCESS") {
        toast.success(res.data.message);
        getAllMarketList();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }

    setSubmitting(false);
    resetForm();
    setIsEditing(false); // Exit edit mode
    setEditId(null); // Reset edit ID
  };

  const updateForm = async (values, { setSubmitting, resetForm }) => {
    const formData = { ...values, id: editId }; // Include the editId in the form data
    console.log("Updating Data:", formData);
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      const res = await api.post(`${UPDATE_MARKET_LIST}`, formData);
      if (res.data.status === "SUCCESS") {
        toast.success(res.data.message);
        getAllMarketList(); // Refresh the table data
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  
    setSubmitting(false);
    resetForm();
    setIsEditing(false); // Exit edit mode
    setEditId(null); // Reset edit ID
  };

  const handleEdit = (row) => {
    setInitialValues({
      name: row.name,
      currency: row.currency,
      remarks: row.remarks,
      status: row.status,
    });
    setIsEditing(true);
    setEditId(row.id); // Track the row being edited
  };

  const [newtableData,setTableData] = useState([]);

  const getAllMarketList = async()=>{    
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      const res = await api.get(`${FETCH_MARKET_LIST}`);
      console.log("res=============================>",res?.data?.payload);
      if (res?.data?.status === "SUCCESS") {
        setTableData(res?.data?.payload);
      } else {
        toast.error(res?.data.message);
      }
  }

  const handleDelete = async(marketid)=>{
    const data = {
        "id": marketid
    }
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      const res = await api.post(`${DELETE_MARKET_LIST}`,data);
      console.log("resresresresresresres=====",res);
      if (res?.data?.status === "SUCCESS") {
        toast.error(res?.data.message);
        getAllMarketList();
      } else {
        toast.error(res?.data.message);
      }

  }

  useEffect(()=>{
    getAllMarketList();
  },[])

  return (
    <div>
      <div className="container containerdiv">
        <div className="filterdiv" style={{ padding: "5px" }}>
          <div className="filtertitle">
            <h5 className="mb-0">Market</h5>
          </div>
          <div className="mt-4">
            <button type="button" className="btn me-2" style={{ backgroundColor: "transparent", color: "red", border: "0" }} onClick={() => { setInitialValues({ name: "", currency: "INR", remarks: "", status: "1", }); setIsEditing(false); setEditId(null); }} > <img src={cancelred} alt="Cancel" /> Cancel </button>
            <button type="submit" className="btn btn-success" onClick={() => {
                if (isEditing) {
                  formikRef.current.submitForm(); // Trigger Formik's submit for update
                } else {
                  formikRef.current.submitForm(); // Trigger Formik's submit for save
                }
              }} > 
          <img src={arrowupward} alt="Save" style={{ transform: "rotate(320deg)" }} />{" "} {isEditing ? "Update" : "Save"} </button>
          </div>
        </div>
        <div>
          <Formik innerRef={formikRef} enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={isEditing?updateForm:handleSubmit } >
            {({ isSubmitting }) => (
              <Form className="mt-4">
                <div className="row mt-3">
                  <div className="col-md-6">
                    <label htmlFor="name">Name</label>
                    <Field type="text" name="name" className="form-control" placeholder="Name" />
                    <ErrorMessage name="name" component="div" className="text-danger" />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="currency">Currency</label>
                    <Field as="select" name="currency" className="form-control custom-dropdown">
                      <option value="USD">USD</option>
                      <option value="EUR">EUR</option>
                      <option value="INR">INR</option>
                    </Field>
                    <span className="dropdown-icon" style={{ position: "absolute", top: "70%", right: "20px", transform: "translateY(-50%)", pointerEvents: "none", }} > ▼ </span>
                    <ErrorMessage name="currency" component="div" className="text-danger" />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-6">
                    <label htmlFor="remarks">Remarks</label>
                    <Field type="text" name="remarks" className="form-control" placeholder="Remarks" />
                    <ErrorMessage name="remarks" component="div" className="text-danger" />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="status">Status</label>
                    <Field as="select" name="status" className="form-control custom-dropdown">
                      <option value="1">Active</option>
                      <option value="2">Inactive</option>
                    </Field>
                    <span className="dropdown-icon" style={{ position: "absolute", top: "70%", right: "20px", transform: "translateY(-50%)", pointerEvents: "none", }} > ▼ </span>
                    <ErrorMessage name="status" component="div" className="text-danger" />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <div className="container containerdiv">
        <div className="table-responsive mt-4">
          <table className="table table-bordered">
            <thead className="table-secondary">
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Currency</th>
                <th>Remarks</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {newtableData && newtableData.map((row) => (
                <tr key={row.id}>
                  <td>{row.id}</td>
                  <td>{row.name}</td>
                  <td>{row.currency}</td>
                  <td>{row.remarks}</td>
                  <td> <span className={`badge ${ row.status === 1 ? "bg-success" : "bg-danger" }`} > { row.status === 1 ? "Active" : "In-active" } </span> </td>
                  <td>
                    
                    <button className="btn btn-sm" onClick={() => handleEdit(row)} style={{ padding: "0px", border: "0px", marginRight: "5px" }} > <img src={actionedit} width="25px" alt="Edit" /> </button>
                    <button className="btn btn-sm" style={{ padding: "0px", border: "0px", marginRight: "5px" }} onClick={() => handleDelete(row.id)}> <img src={actiondelete} width="25px" alt="Delete" /> </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Paginationcomp />
      </div>
    </div>
  );
};

export default GeneralComponent;
