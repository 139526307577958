import React, { useEffect, useState } from 'react'
import dollericon from '../../../assets/images/dollericon.svg'
import inricon from '../../../assets/images/inricon.svg'
import cryptoforexicon from '../../../assets/images/crypto.svg'
import forexicon from '../../../assets/images/forex.svg'
import { Col, Row } from 'reactstrap'
import Customselect from './Customselect'
import { fetchOwner } from '../../../store/login/action'
import { useDispatch, useSelector } from 'react-redux'
import api from "../../../_helper/api_helper";
import { FETCH_DASHBOARD_DATA_URL_TOTAL_CLIENT_BALANCE,FETCH_DASHBOARD_DATA_URLTOTAL_DEPOSITE_WITHDRAW } from '../../../_helper/url_helper'
import { toast } from 'react-toastify'
import { parseDateRange } from '../../../_helper/dateFormate'

const Balancecomp = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const dispatch        = useDispatch();
    const loginState      = useSelector((state) => state.login);
    const [depositewithdraw, setDepositeWithdraw] = useState([]);
    const [cleientbalance, setClientBalance] = useState([]);
    useEffect(() => {
        fetchDataHandlerdepositewithdraw();
        fetchDataHandlerbalance();
        dispatch(fetchOwner({ jwttoken: loginState.jwttoken }));
    }, []);

    


    useEffect(() => {
        if (loginState.owner.status === "inactive") {
        localStorage.removeItem("jwttoken");
        window.location.href = "/";
        }
    }, [loginState]); 

    // async function fetchDataHandler() {
    //     let jwttoken = loginState.jwttoken
    //     try {
    //         let data = {
    //             jwttoken: loginState.jwttoken,
    //         };
    //         api.defaults.headers.common["Authorization"] = `Bearer ${jwttoken}`;
    //         const [res1, res2] = await Promise.all([
    //             api.post(FETCH_DASHBOARD_DATA_URLTOTAL_DEPOSITE_WITHDRAW, data),
    //             api.post(FETCH_DASHBOARD_DATA_URL_TOTAL_CLIENT_BALANCE, data),
                
    //         ]);

    //         // console.log("res1======",res1);
            
    //         if (res1.data.status === "SUCCESS") {
    //             setDepositeWithdraw(res1.data.payload);
    //         } else {
    //             toast.error(`API 1 Error: ${res1.data.message}`);
    //         }
    //         if (res2.data.status === "SUCCESS") {
    //             setClientBalance(res2.data.payload);
    //         } else {
    //             toast.error(`API 2 Error: ${res2.data.message}`);
    //         }
    //     } catch (error) {
    //         toast.error(error.message);
    //     }
    // }
    const handleOptionSelect = (option) => {
        setSelectedOption(option); // Update the state
        fetchDataHandlerdepositewithdraw(option); // Call the fetch function
        // fetchDataHandlertrad(option)
    };

    const handleOptionSelectbalance = (option) => {
        setSelectedOption(option); // Update the state       
        fetchDataHandlerbalance(option)
    }; 
    
    async function fetchDataHandlerdepositewithdraw(option) {
        let jwttoken = loginState.jwttoken;
        const formattedDates = parseDateRange(option?.value);
        const startDate = formattedDates?.startDate;
        const endDate = formattedDates?.endDate;
    
        try {
            api.defaults.headers.common["Authorization"] = `Bearer ${jwttoken}`;
            // let data = { jwttoken: jwttoken };
            let dataObj = {
                jwttoken: jwttoken,
                filter: option?.value, // Use the passed option directly
            };
    
            if (option?.label === "custom" || option?.label === "Custom") {
                dataObj.filter = 'custom'; // For custom, include dates
                dataObj.start_date = startDate;
                dataObj.end_date = endDate;
            }
            api.defaults.headers.common["Authorization"] = `Bearer ${jwttoken}`;
            const res = await api.post(FETCH_DASHBOARD_DATA_URLTOTAL_DEPOSITE_WITHDRAW, dataObj);    
            if (res.data.status === "SUCCESS") {
                setDepositeWithdraw(res.data.payload);
            } else {
                toast.error(res.data.message);
            }
           
        } catch (error) {
            toast.error(`Error: ${error.message}`);
        }
    }

    async function fetchDataHandlerbalance(option) {
        const jwttoken = loginState.jwttoken;
    
        // Compute the startDate and endDate based on the selected option
        const formattedDates = parseDateRange(option?.value);
        const startDate = formattedDates?.startDate;
        const endDate = formattedDates?.endDate;
    
        try {
            let dataObj = {
                jwttoken: jwttoken,
                filter: option?.value, // Use the passed option directly
            };
    
            if (option?.label === "custom" || option?.label === "Custom") {
                dataObj.filter = 'custom'; // For custom, include dates
                dataObj.start_date = startDate;
                dataObj.end_date = endDate;
            }
    
              
            api.defaults.headers.common["Authorization"] = `Bearer ${jwttoken}`;
            const res = await api.post(FETCH_DASHBOARD_DATA_URL_TOTAL_CLIENT_BALANCE, dataObj);    
            if (res.data.status === "SUCCESS") {
                setClientBalance(res.data.payload);
            } else {
                toast.error(res.data.message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    }

    // const handleOptionSelect = (option) => {
    //     setSelectedOption(option);
    // };  
    
    const [selectedBalance, setSelectedBalance] = useState("Deposit");
    // console.log("selectedBalanceselectedBalanceselectedBalance",selectedBalance);

    const handleBalanceChange = (event) => {
        setSelectedBalance(event.target.value);
    };
    
    const formattedDates = parseDateRange(selectedOption?.value);
    const startDate = formattedDates?.startDate;
    const endDate = formattedDates?.endDate;

  return (
    <>
        <div className="rowhalfcoldiv">
            <div className="halfcoldiv">
                <div className='p-2'>
                < div className="gap-2 d-flex justify-content-between align-items-center" >
                    <div>
                        {/* <h4>
                            <span>

                            </span>
                        </h4> */}
                        <div className='deposit-withdraw-container'>
                            <label className="radio-label">
                                <input type="radio" name="transaction" value="Deposit" checked={selectedBalance === "Deposit"} onChange={handleBalanceChange} />
                                <span className="custom-radio"></span>
                                Deposit
                            </label>
                            <label className="radio-label">
                                <input type="radio" name="transaction" value="Withdraw" checked={selectedBalance === "Withdraw"} onChange={handleBalanceChange} />
                                <span className="custom-radio"></span>
                                Withdraw
                            </label>
                        </div>
                        <p>Your Deposit/Withdraw</p>
                    </div>
                    <div>
                        <Customselect onOptionSelect={handleOptionSelect}/>
                    </div>
                </div>                   
                <Row className="mt-3">
                   
                    <Col md="4">
                        <div className="iconaligndivmain">
                            <span className="iconaligndiv">
                                <div className="icondiv" style={{background:"#883dcf2e"}}>
                                    <img src={dollericon} alt=""/>
                                </div>                                  
                                <p>US <small className='profitcolor'> 10% <i className="fa fa-caret-up"></i></small></p>
                            </span>
                            <h4 className="ml-2">{selectedBalance ==="Deposit"? depositewithdraw?.usd?.usd_deposit:depositewithdraw?.usd?.usd_withdraw} </h4>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="iconaligndivmain">
                            <span className="iconaligndiv">
                                <div className="icondiv" style={{background:"#f867262e"}}>
                                    <img src={inricon} alt=""/>
                                </div>                                  
                                <p>Indian <small className='profitcolor'>20%<i className="fa fa-caret-up"></i></small></p>
                            </span>
                            <h4>{selectedBalance ==="Deposit"? depositewithdraw?.inr?.inr_deposit:depositewithdraw?.inr?.inr_withdraw} </h4>
                        </div>
                    </Col>
                    {/* <Col md="3">
                        <div className="iconaligndivmain">
                            <span className="iconaligndiv">
                                <div className="icondiv" style={{background:"#48bcfe2e"}}>
                                    <img src={forexicon} alt="" />
                                </div>
                                <p>Forex <small className='profitcolor'> 14%<i className="fa fa-caret-up"></i></small></p>
                            </span>
                            <h4>$2600 </h4>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="iconaligndivmain">
                            <span className="iconaligndiv">
                                <div className="icondiv" style={{background:"#32cdb42e"}}>
                                    <img src={cryptoforexicon} alt="" />
                                </div>
                                <p>Crypto <small className='profitcolor'>10% <i className="fa fa-caret-up"></i></small></p>
                            </span>
                            <h4>$2600 </h4>
                        </div>
                    </Col> */}
                </Row>  
                </div>                    
            </div>
            <div className="halfcoldiv">
                <div className='p-2'>
                <div div className="d-flex justify-content-between align-items-center" >
                    <div>
                        <h4>Client Balance</h4>
                        <p>Your Brokerage</p>  
                    </div>
                    <div>
                        <Customselect onOptionSelect={handleOptionSelectbalance}/> 
                    </div>
                </div>                
                <Row className="mt-3">
                    <Col md="4">
                        <div className="iconaligndivmain">
                            <span className="iconaligndiv">
                                <div className="icondiv" style={{background:"#883dcf2e"}}>
                                    <img src={dollericon} alt=""/>
                                </div>                                  
                                <p>US <small className='profitcolor'> 10% <i className="fa fa-caret-up"></i></small></p>
                            </span>
                            <h4 className="ml-2">{cleientbalance?.balance_usd? cleientbalance?.balance_usd:0.00}</h4>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="iconaligndivmain">
                            <span className="iconaligndiv">
                                <div className="icondiv" style={{background:"#f867262e"}}>
                                    <img src={inricon} alt=""/>
                                </div>                                  
                                <p>Indian <small className='profitcolor'>20%<i className="fa fa-caret-up"></i></small></p>
                            </span>
                            <h4>{cleientbalance?.balance_inr?cleientbalance?.balance_inr:0.00}</h4>
                        </div>
                    </Col>                    
                </Row>
                </div>
            </div>
        </div>
    </>
  )
}

export default Balancecomp