import Dashboard from "../Components/Private/Dashboard/index";
import TransactionAnalysis from "../Components/Private/TransactionAnalysis";
import Users from "../Components/Private/Users";
import AdminProfile from "../Components/Private/Users/AdminProfile";
import CreateUser from "../Components/Private/Users/CreateUser";
import ActionLedger from "../Components/Private/ActionLedger";
import ActivePositions from "../Components/Private/ActivePositions";
import ClosedPositions from "../Components/Private/ClosedPositions";
import Script from "../Components/Private/Script";
import Clients from "../Components/Private/Clients";
import ClientsWithM2M from "../Components/Private/Clients/ClientsWithM2M";
// ClientsWithM2M
import ClientProfile from "../Components/Private/Clients/ClientProfile";
import CreateClient from "../Components/Private/Clients/CreateClient";
import UpdateClient from "../Components/Private/Clients/UpdateClient";
import ClientM2M from "../Components/Private/Clients/M2M";
import CreateFund from "../Components/Private/Funds/CreateFund";
import ActivePositionClients from "../Components/Private/ActivePositions/ActivePositionClients";
import BrokerageTemplate from "../Components/Private/BrokerageTemplate";
import CreateBrokerage from "../Components/Private/BrokerageTemplate/CreateBrokerage";
import UpdateBrokerage from "../Components/Private/BrokerageTemplate/UpdateBrokerage";
import ViewBrokerage from "../Components/Private/BrokerageTemplate/ViewBrokerage";
import Trades from "../Components/Private/Trades";
import MarginTemplate from "../Components/Private/MarginTemplate";
import CreateMargin from "../Components/Private/MarginTemplate/CreateMargin";
import UpdateMargin from "../Components/Private/MarginTemplate/UpdateMargin";
import ViewMargin from "../Components/Private/MarginTemplate/ViewMargin";
import Fund from "../Components/Private/Funds";
import Timings from "../Components/Private/Timings";
// import GlobalMargin from "../Components/Private/GlobalMargin";
import Settings from "../Components/Private/Settings";
import Orders from "../Components/Private/Orders";
import LiveAccounts from "../Components/Private/LiveAccounts";
import Accounts from "../Components/Private/Accounts";
import Market from "../Components/Private/Market";
import Themes from "../Components/Private/Themes";
import Createtheme from "../Components/Private/Themes/Createtheme";
import Marketstocks from "../Components/Private/Marketstockes";
import UpdateUser from "../Components/Private/Users/UpdateUser";
import PayoutRequests from "../Components/Private/PayoutRequests";
import CurrencyRateManager from "../Components/Private/CurrencyRateManager";
import BillMaster from "../Components/Private/BillMaster";
import LedgerMaster from "../Components/Private/LedgerMaster";
import Positions from "../Components/Private/Positions";
import ReferralSetting from "../Components/Private/ReferralSetting";
import BlockScriptManager from "../Components/Private/BlockScriptManager";
import RejectionLogs from "../Components/Private/RejectionLogs";
import Dividend from "../Components/Private/Dividend";
import SingleClientM2M from "../Components/Private/SingleClientM2M";

import { useSelector } from "react-redux";
import Scriptlotsettings from "../Components/Private/Scriptlotsettings";

export let routes = [
  { path: `/`, Component: <Dashboard /> },
  { path: `/dashboard`, Component: <Dashboard /> },
  { path: `/transactionAnalysis`, Component: <TransactionAnalysis /> },
  { path: `/users`, Component: <Users /> },
  { path: `/adminProfile/:userid`, Component: <AdminProfile /> },
  { path: `/user/create`, Component: <CreateUser /> },
  { path: `/actionLedger`, Component: <ActionLedger /> },
  { path: `/activePositions`, Component: <ActivePositions /> },
  { path: `/closedPositions`, Component: <ClosedPositions /> },
  { path: `/script`, Component: <Script /> },
  { path: `/clients`, Component: <Clients /> },
  { path: `/clientsWithM2M`, Component: <ClientsWithM2M /> },
  { path: `/client/create`, Component: <CreateClient /> },
  // { path: `/client/M2M`, Component: <ClientM2M /> },
  { path: `/client/copy/:copyClientId`, Component: <CreateClient /> },
  { path: `/client/update/:client_id`, Component: <CreateClient /> },
  { path: `/fund/create`, Component: <CreateFund /> },
  {
    path: `/activePositionClients/:instrumentToken`,
    Component: <ActivePositionClients />,
  },
  { path: `/brokerageTemplate`, Component: <BrokerageTemplate /> },
  { path: `/brokerageTemplate/create`, Component: <CreateBrokerage /> },
  { path: `/brokerageTemplate/view/:templateId`, Component: <ViewBrokerage /> },
  {
    path: `/brokerageTemplate/update/:templateId`, Component: <CreateBrokerage />,
  },
  { path: `/trades`, Component: <Trades /> },
  { path: `/marginTemplates`, Component: <MarginTemplate /> },
  { path: `/marginTemplate/create`, Component: <CreateMargin /> },
  { path: `/marginTemplate/update/:templateId`, Component: <CreateMargin /> },
  // { path: `/marginTemplate/update/:templateId`, Component: <UpdateMargin /> },
  { path: `/marginTemplate/view/:templateId`, Component: <ViewMargin /> },
  { path: `/fund`, Component: <Fund /> },
  { path: `/timings`, Component: <Timings /> },
  // { path: `/globalMargin`, Component: <GlobalMargin /> },
  { path: `/settings`, Component: <Settings /> },
  { path: `/Orders`, Component: <Orders /> },
  { path: `/liveAccounts`, Component: <LiveAccounts /> },
  { path: `/Accounts`, Component: <Accounts /> },
  { path: `/Market`, Component: <Market /> },
  { path: `/createtheme`, Component: <Themes /> },
  { path: `/themes/create`, Component: <Createtheme /> },
  
  { path: `/marketstocks`, Component: <Marketstocks /> },
  // { path: `/user/update/:userId`, Component: <CreateUser /> },
  { path: `/user/update/:userId`, Component: <UpdateUser /> },
  
  { path: `/client/profile/:clientid`, Component: <ClientProfile /> },
  { path: `/PayoutRequests`, Component: <PayoutRequests /> },
  { path: `/CurrencyRateManager`, Component: <CurrencyRateManager /> },
  { path: `/BillMaster`, Component: <BillMaster /> },
  { path: `/LedgerMaster`, Component: <LedgerMaster /> },
  { path: `/Positions`, Component: <Positions /> },
  { path: `/ReferralSetting`, Component: <ReferralSetting /> },
  { path: `/lotscriptmanagement`, Component: <Scriptlotsettings /> },
  { path: `/BlockScriptManager`, Component: <BlockScriptManager /> },
  { path: `/RejectionLogs`, Component: <RejectionLogs /> },
  { path: `/Dividend`, Component: <Dividend /> },
  { path: `/SingleClientM2M`, Component: <SingleClientM2M /> }
];

export const GetRoutes = () => {
  const loginState = useSelector((state) => state.login);
  if (loginState['owner']['type'] !== undefined && loginState.owner.type === 'broker') {
    // Paths to hide for 'broker' type
    const pathsToHide = [
      '/users'
      // Add other paths you want to hide for 'broker' type
    ];

    routes = routes.filter(route => !pathsToHide.includes(route.path));
  }
  return routes;
}